import React, { useState, useRef } from "react";
import { Carousel, Row, Col } from "antd";
import { scmButtonText, scmDescription, scmElements } from "../../Util/writing";
import { getWindowBracket } from "../../Util/layout";

const SCM = (props) => {
  const [step, setStep] = useState(0);
  const carouselRef = useRef();
  const maxStep = process.env.REACT_APP_VERSION === "dev" ? 1 : 0;

  const handlePreviousClick = () => {
    if (step > 0) {
      carouselRef.current.goTo(step - 1);
      setStep(step - 1);
    }
  };

  const handleNextClick = () => {
    if (step < maxStep) {
      carouselRef.current.goTo(step + 1);
      setStep(step + 1);
    }
  };

  const getButtonText = (side = 0) => {
    if (side) {
      // get text for "next" button
      return scmButtonText[step + 1];
    } else {
      // get text for "previous" button
      return scmButtonText[step - 1];
    }
  };

  const createOutputForChildren = (children, j, output) => {
    if (children) {
      for (const child of children) {
        output.push(
          <p key={output.length + 1} style={{ paddingLeft: j * 20, marginBottom: 3 }}>
            {"\u2022 " + child.name}
          </p>
        );
        createOutputForChildren(child.children, j + 1, output);
      }
    }

    return output;
  };

  const getColumnContent = (column) => {
    const bracket = getWindowBracket(props.width);
    const numColumns = bracket === "xs" || bracket === "sm" ? 1 : bracket === "md" || bracket === "lg" ? 2 : 3;
    if (column > numColumns) {
      return null;
    }

    const output = [];

    let i = column - 1;
    while (i < scmElements.length) {
      const element = scmElements[i];
      output.push(
        <p key={output.length + 1} style={{ fontWeight: "bold", paddingTop: 30, marginBottom: 3 }}>
          {"\u2022 " + element.name}
        </p>
      );
      createOutputForChildren(element.children, 1, output);
      i += numColumns;
    }

    return output;
  };

  return (
    <React.Fragment>
      <p style={{ fontSize: 20, fontWeight: "bold" }}>Sunrise Clinical Manager</p>
      <Carousel ref={carouselRef} dots={false} style={{ paddingTop: 10 }}>
        <div>
          {scmDescription}
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              {getColumnContent(1)}
            </Col>
            <Col xs={0} sm={0} md={12} lg={12} xl={8}>
              {getColumnContent(2)}
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={8}>
              {getColumnContent(3)}
            </Col>
          </Row>
        </div>

        {/* Step 2 - Database info */}
        <div>
          <p style={{ fontSize: 18, fontWeight: "bold" }}>Example linkage for dataset creation</p>
          <img
            src={process.env.PUBLIC_URL + "/moreInfo/scmLinkage.png"}
            alt="table linking"
            width="90%"
            height={props.height * 0.75}
          />
        </div>
      </Carousel>

      <div>
        <button style={{ visibility: step === 0 ? "hidden" : "visible" }} onClick={handlePreviousClick}>
          {getButtonText()}
        </button>
        <button
          style={{ visibility: step === maxStep ? "hidden" : "visible", float: "right" }}
          onClick={handleNextClick}
        >
          {getButtonText(1)}
        </button>
      </div>
    </React.Fragment>
  );
};

export default SCM;
