import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import * as actions from "../../Store/Actions/index";

const DatasetFilter = (props) => {
  const [localData, setLocalData] = useState([...props.datasets]);
  const [allChecked, setAllChecked] = useState(false);
  const [adminChecked, setAdminChecked] = useState(false);
  const [clinicalChecked, setClinicalChecked] = useState(false);
  const [emrChecked, setEmrChecked] = useState(false);
  const [vsChecked, setVsChecked] = useState(false);

  useEffect(() => {
    setLocalData(props.datasets);
  }, [props.datasets]);

  const checkAll = () => {
    const newAllChecked = !allChecked; // new value for all checked
    const dataCopy = JSON.parse(JSON.stringify(localData));
    setClinicalChecked(false);
    setEmrChecked(false);
    setAdminChecked(false);
    setVsChecked(false);
    // set all boxes to newAllChecked
    setAllChecked(newAllChecked);
    for (let dataset of dataCopy) {
      dataset.checked = newAllChecked;
    }
    props.setDatasets(dataCopy);
  };

  //   checks if all boxes are checked in order to check select all
  const checkAllChecked = (dataCopy) => {
    for (let dataset of dataCopy) {
      if (dataset.checked === false) {
        return false;
      }
    }
    return true;
  };

  const checkCategory = (category) => {
    let checked, setChecked, otherCategories;
    // assigning getter and setters to predefined variables
    switch (category) {
      case "Admin":
        checked = adminChecked;
        setChecked = setAdminChecked;
        otherCategories = [setClinicalChecked, setEmrChecked, setVsChecked];
        break;
      case "Clinical":
        checked = clinicalChecked;
        setChecked = setClinicalChecked;
        otherCategories = [setAdminChecked, setEmrChecked, setVsChecked];
        break;
      case "EMR":
        checked = emrChecked;
        setChecked = setEmrChecked;
        otherCategories = [setAdminChecked, setClinicalChecked, setVsChecked];
        break;
      case "VS":
        checked = vsChecked;
        setChecked = setVsChecked;
        otherCategories = [setAdminChecked, setClinicalChecked, setEmrChecked];
        break;
      default:
        return;
    }

    const newChecked = !checked;
    // copy data and adjust checkmarks
    const dataCopy = JSON.parse(JSON.stringify(localData));
    if (newChecked) {
      if (category === "VS") {
        for (const dataset of dataCopy) {
          if (dataset.title.startsWith("Vital Statistics")) {
            dataset.checked = true;
          } else {
            dataset.checked = false;
          }
        }
      } else {
        for (const dataset of dataCopy) {
          if (dataset.category === category) {
            dataset.checked = true;
          } else {
            dataset.checked = false;
          }
        }
      }
    } else {
      for (const dataset of dataCopy) {
        dataset.checked = false;
      }
    }
    props.setDatasets(dataCopy);

    // adjust checks for other categories
    for (const setCategory of otherCategories) {
      setCategory(false);
    }
    setAllChecked(false);
    setChecked(newChecked);
  };

  const checkIfAllCategoriesChecked = (data) => {
    setAdminChecked(checkIfCategoryChecked("Admin", data));
    setClinicalChecked(checkIfCategoryChecked("Clinical", data));
    setEmrChecked(checkIfCategoryChecked("EMR", data));
    setVsChecked(checkIfCategoryChecked("VS", data));
  };

  const checkIfCategoryChecked = (category, data) => {
    // checking VS
    if (category === "VS") {
      for (const dataset of data) {
        if (dataset.title.startsWith("Vital Statistics")) {
          if (!dataset.checked) {
            return false;
          }
        } else {
          if (dataset.checked) {
            return false;
          }
        }
      }
      return true;
    }

    // checking categories
    for (const dataset of data) {
      if (dataset.category === category) {
        if (!dataset.checked) {
          return false;
        }
      } else {
        if (dataset.checked) {
          return false;
        }
      }
    }
    return true;
  };

  const changeDatasetCheck = (title) => {
    const dataCopy = JSON.parse(JSON.stringify(localData));
    for (let dataset of dataCopy) {
      if (dataset.title === title) {
        dataset.checked = !dataset.checked;
        if (!dataset.checked) {
          if (allChecked) {
            // uncheck select all if it's checked
            setAllChecked(false);
          }
        } else {
          // check if all are checked, then check select all
          if (checkAllChecked(dataCopy)) {
            setAllChecked(true);
          }
        }
        break;
      }
    }
    props.setDatasets(dataCopy);
    checkIfAllCategoriesChecked(dataCopy);
  };

  return (
    <div>
      {/* Select All */}
      <div style={{ display: "flex", flexDirection: "row" }} onClick={checkAll}>
        <div style={{ flex: 1 }}>
          <Checkbox key={"select-all"} checked={allChecked} />
        </div>
        <div style={{ flex: 10 }}>{"Select All"}</div>
      </div>
      {/* Admin */}
      <div style={{ display: "flex", flexDirection: "row" }} onClick={() => checkCategory("Admin")}>
        <div style={{ flex: 1 }}>
          <Checkbox key={"admin"} checked={adminChecked} />
        </div>
        <div style={{ flex: 10 }}>{"Admin"}</div>
      </div>
      {/* Clinical */}
      <div style={{ display: "flex", flexDirection: "row" }} onClick={() => checkCategory("Clinical")}>
        <div style={{ flex: 1 }}>
          <Checkbox key={"clinical"} checked={clinicalChecked} />
        </div>
        <div style={{ flex: 10 }}>{"Clinical"}</div>
      </div>
      {/* EMR */}
      <div style={{ display: "flex", flexDirection: "row" }} onClick={() => checkCategory("EMR")}>
        <div style={{ flex: 1 }}>
          <Checkbox key={"emr"} checked={emrChecked} />
        </div>
        <div style={{ flex: 10 }}>{"EMR"}</div>
      </div>
      {/* Vital Statistics */}
      <div style={{ display: "flex", flexDirection: "row" }} onClick={() => checkCategory("VS")}>
        <div style={{ flex: 1 }}>
          <Checkbox key={"vs"} checked={vsChecked} />
        </div>
        <div style={{ flex: 10 }}>{"Vital Statistics"}</div>
      </div>
      {/* mapping individual datasets */}
      {props.datasets.map((dataset) => {
        return (
          <div
            key={dataset.title + "-div"}
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => changeDatasetCheck(dataset.title)}
          >
            <div style={{ flex: 1 }}>
              <Checkbox
                key={dataset.title}
                checked={dataset.checked}
                disabled={props.disabledList.includes(dataset.title)}
              />
            </div>
            <div style={{ flex: 10 }}>{dataset.title + (dataset.short ? " (" + dataset.short + ")" : "")}</div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    datasets: state.data.datasets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDatasets: (datasets) => dispatch(actions.setDatasets(datasets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetFilter);
