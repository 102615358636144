import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { algorithmColors } from "./PressureUlcerConstantsHelpers";

const PressureUlcerAlgorithmFilter = (props) => {
  const [localData, setLocalData] = useState([...props.algorithmsChecked]);
  //   const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setLocalData(props.algorithmsChecked);
  }, [props.algorithmsChecked]);

  //   const checkAll = () => {
  //     const newAllChecked = !allChecked; // new value for all checked
  //     const dataCopy = JSON.parse(JSON.stringify(localData));

  //     // set all boxes to newAllChecked
  //     setAllChecked(newAllChecked);
  //     for (let algorithm of dataCopy) {
  //       algorithm.checked = newAllChecked;
  //     }
  //     props.setAlgorithmsChecked(dataCopy);
  //   };

  // checks if all boxes are checked in order to check select all
  //   const checkAllChecked = (dataCopy) => {
  //     for (let type of dataCopy) {
  //       if (type.checked === false) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   };

  // changes check mark for a single algorithm and updates select all if necessary
  const changeAlgorithmCheck = (algorithmToChange) => {
    const dataCopy = JSON.parse(JSON.stringify(localData));
    for (let algorithm of dataCopy) {
      if (algorithm.algorithm === algorithmToChange) {
        algorithm.checked = !algorithm.checked;
        // if (!algorithm.checked) {
        //   if (allChecked) {
        //     // uncheck select all if it's checked
        //     setAllChecked(false);
        //   }
        // } else {
        //   // check if all are checked, then check select all
        //   if (checkAllChecked(dataCopy)) {
        //     setAllChecked(true);
        //   }
        // }
        break;
      }
    }
    props.setAlgorithmsChecked(dataCopy);
  };

  return (
    <div
      style={{
        // marginLeft: props.bracket === "xs" || props.bracket === "sm" || props.bracket === "md" ? 80 : 0,
        paddingTop: props.bracket === "xs" || props.bracket === "sm" ? 0 : 60,
        paddingBottom: 20,
      }}
    >
      {/* checkbox for Select All*/}
      {/* <div>
        <Checkbox
          key={"select-all"}
          checked={allChecked}
          onChange={() => {
            checkAll();
          }}
        />
        {" Select All"}
      </div> */}

      {/* checkbox for ICD-10*/}
      <div style={{ display: "flex", flexDirection: "row" }} key={"icd-outerdiv"}>
        <div key={"icd-div"} style={{ flex: 3 }}>
          <Checkbox key={"icd10"} checked={true} disabled />
          {" ICD-10"}
        </div>
        <svg width={8} height={18} style={{ flex: 1 }}>
          <circle r={6} cx={6} cy={12} fill={algorithmColors["ICD10"]} />
        </svg>
      </div>

      {/* mapping individual algorithms */}
      {localData.map((algorithm) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }} key={algorithm.algorithm + "outerdiv"}>
            <div key={algorithm.algorithm + "div"} style={{ flex: 3 }}>
              <Checkbox
                key={algorithm.algorithm}
                checked={algorithm.checked}
                onChange={() => {
                  changeAlgorithmCheck(algorithm.algorithm);
                }}
              />
              {" " + algorithm.name}
            </div>
            <svg width={8} height={18} style={{ flex: 1 }}>
              <circle r={6} cx={6} cy={12} fill={algorithmColors[algorithm.algorithm]} />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default PressureUlcerAlgorithmFilter;
