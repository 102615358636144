import React, { useState, useEffect, useRef } from "react";
import MenuBar from "../../Components/MenuBar/MenuBar.js";
import PressureUlcerIntro from "../../Components/PressureUlcer/PressureUlcerIntro";
import PressureUlcerMethodsTree from "../../Components/PressureUlcer/PressureUlcerMethodsTree";
import PressureUlcerMethodsDetails from "../../Components/PressureUlcer/PressureUlcerMethodsDetails";
import PressureUlcerResults from "../../Components/PressureUlcer/PressureUlcerResults";
import InfoHover from "../../Util/InfoHover";
import { Carousel, Layout, Radio, Row, Col } from "antd";
import { throttle } from "lodash";
import { getWindowBracket, mainSizes, detailSizes, getMainWidth, getDetailWidth } from "../../Util/layout";
import { pressureInjuryInfo } from "../../Util/writing";

const { Content } = Layout;

const PressureUlcerPage = () => {
  const [contentWidth, setContentWidth] = useState(window.innerWidth);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 60);
  const [selectedMain, setSelectedMain] = useState(-1);
  const [selectedChild, setSelectedChild] = useState(-1);
  const sizeBracket = getWindowBracket(contentWidth);
  const carouselRef = useRef();

  const resizeListener = () => {
    setContentWidth(document.getElementById("content").getBoundingClientRect().width);
    setContentHeight(window.innerHeight - 60);
  };
  const throttledResizeListener = throttle(resizeListener, 200);

  // componentDidMount and willUnmount
  useEffect(() => {
    window.addEventListener("resize", throttledResizeListener);
    return () => window.removeEventListener("resize", throttledResizeListener);
  }, []);

  const onRadioChange = (e) => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(e.target.value);
    }
  };

  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <MenuBar bracket={sizeBracket} width={contentWidth} />
        <Content id="content">
          <div style={{ margin: "auto", textAlign: "center" }}>
            <Radio.Group onChange={onRadioChange} defaultValue={0}>
              <Radio.Button value={0}>{"Introduction & Methods"}</Radio.Button>
              <Radio.Button value={1}>Results</Radio.Button>
            </Radio.Group>
          </div>
          <Carousel ref={carouselRef} dots={false} style={{ padding: 10 }}>
            <div>
              <div
                style={{ height: contentHeight - 60, overflowY: "auto", margin: "auto", width: contentWidth * 0.95 }}
              >
                <PressureUlcerIntro width={contentWidth} />
                <InfoHover content={pressureInjuryInfo} backgroundColor="white" />
                <Row>
                  <Col xs={mainSizes.xs} sm={mainSizes.sm} md={mainSizes.md} lg={mainSizes.lg} xl={mainSizes.xl}>
                    <PressureUlcerMethodsTree
                      width={getMainWidth(contentWidth) * 0.9}
                      height={contentHeight - 60}
                      setSelectedMain={setSelectedMain}
                      setSelectedChild={setSelectedChild}
                    />
                  </Col>
                  <Col
                    xs={detailSizes.xs}
                    sm={detailSizes.sm}
                    md={detailSizes.md}
                    lg={detailSizes.lg}
                    xl={detailSizes.xl}
                    style={{ backgroundColor: "white" }}
                  >
                    <PressureUlcerMethodsDetails
                      width={getDetailWidth(contentWidth)}
                      height={contentHeight - 60}
                      selectedMain={selectedMain}
                      selectedChild={selectedChild}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <PressureUlcerResults width={contentWidth} height={contentHeight - 60} />
            </div>
          </Carousel>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default PressureUlcerPage;
