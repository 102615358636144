import React from "react";
import { Typography } from "antd";
const { Paragraph, Title } = Typography;
const largerFont = { fontSize: 16 };

export const connectCareDescription = [
  "is a clinical information system (CIS) where health care providers collect, store, access, and analyze patient and healthcare information",
  "provides a central access point to patient information, clinical standards, and best healthcare practices",
  "ensures healthcare is consistent across Alberta",
  "allows patients to easily move between providers, services, and facilities",
  "improves standards of care",
  "creates better outcomes for patients",
  "is powered by information",
  "bridges information, healthcare teams, patients, and the future",
];

export const scmDescription = (
  <React.Fragment>
    <Paragraph>
      Sunrise Clinical Manager (SCM) contains patient health records, including information such as demographics, test
      results, medications, notes from hospital visits, and more. The aim of SCM is to improve the quality and safety of
      care by providing access to information and decision support tools.
    </Paragraph>
    <Paragraph style={{ marginBottom: -10 }}>
      SCM contains a wide variety of elements, including, but not limited to:
    </Paragraph>
  </React.Fragment>
);

export const scmElements = [
  {
    name: "Clinical Notes",
    children: [
      { name: "Discharge Summaries" },
      { name: "Progress Reports" },
      { name: "Diagnostic Imaging Textual Reports" },
      { name: "Pathology Reports" },
    ],
  },
  {
    name: "Visits",
    children: [
      { name: "Modules", children: [{ name: "Inpatient" }, { name: "Emergency Department" }, { name: "Ambulatory" }] },
      { name: "Example Details", children: [{ name: "Admission information" }, { name: "Discharge information" }] },
    ],
  },
  { name: "Orders" },
  { name: "Medications" },
  { name: "Health Issue", children: [{ name: "Free-text diagnosis" }] },
  {
    name: "Labs",
    children: [
      {
        name: "Categories",
        children: [{ name: "Laboratory" }, { name: "Cardiovascular Labs" }, { name: "Diagnostic Imaging" }],
      },
      { name: "Example Details", children: [{ name: "White blood cell count" }, { name: "Troponins" }] },
    ],
  },
  { name: "Flow Sheets", children: [{ name: "Weight" }, { name: "Temperature" }] },
];

// Button text for more info
export const connectCareButtonText = ["Overview Video", "Details", "Dataset Creation"];
export const scmButtonText = ["SCM Overview", "Dataset Creation"];

export const hypertensionIntro = (
  <React.Fragment>
    <Title level={4} style={{ textAlign: "center" }}>
      Hypertension Identification
    </Title>
    <Paragraph style={largerFont}>
      The prevalence of hypertension is often underestimated. When a patient is discharged from a hospital, their
      conditions are coded using the International Classification of Diseases (ICD). Due to time constraints, and the
      fact that hypertension may not be the cause of the hospital visit, coding of hypertension is often omitted. This
      can lead to issues for surveillance and policy planning.
    </Paragraph>
    <Paragraph style={largerFont}>
      After a patient has been discharged, the most reliable way to determine whether they have hypertension is to have
      a trained clinician manually go through the documents in the patient chart. However, this is expensive and
      time-consuming. Electronic medical records (EMRs) provide all of the information necessary to identify
      hypertension. It is difficult to obtain the necessary information though, as most documents are in free-text form.
    </Paragraph>
    <Paragraph style={largerFont}>
      The Unified Medical Language System (UMLS) maps conditions to Concept Unique Identifiers (CUIs), which helps
      process EMRs. Using these CUIs, we have created a machine learning model that can accurately identify hypertension
      from clinical documents.
    </Paragraph>
  </React.Fragment>
);

export const hypertensionResults = (
  <React.Fragment>
    <Paragraph style={largerFont}>
      SHapley Additive exPlanations (SHAP) were found for the selected features of both the Concept model and the
      Document-Concept model. The SHAP values show that for the concept model, the concept for hypertension is the most
      important feature. For the Document-Concept model, all of the features except for one include the hypertension
      concept. This can then be interpreted as an ordered list of the documents most likely to contain information
      necessary to identify hypertension. The first document on the list is "surgical_assessment_and_history_-_nursing".
      Due to the overwhelming reliance on the concept for hypertension, a simple concept search was also implemented.
      The results of the two models and the concept search were compared to the ICD-10 algorithm, which identifies that
      hypertension is present if the discharge abstract contains an ICD-10 code for hypertension.
    </Paragraph>
    <Paragraph style={largerFont}>
      The sensitivity, positive predictive value (PPV), specificity, and negative predictive value (NPV) can be seen in
      the radar charts below. For a breakdown of what each metric means, see our{" "}
      <a href="/performance-metrics">performance metrics page</a>.
    </Paragraph>
    <Paragraph style={largerFont}>
      The pie charts depict the breakdown of the cases used for the testing.{" "}
      <b>Click on a section of a pie chart to see the results for that population characterisitic</b>, or click the
      "Overall Results" button to see the overall results.
    </Paragraph>
  </React.Fragment>
);

export const hypertensionMatters = (
  <React.Fragment>
    <Paragraph style={largerFont}>
      Accurately identifying hypertension is important for measuring health system performance and risk adjustment. The
      gold standard for identifying hypertension is chart review. However, this is time consuming and costly. Through
      the use of electronic medical records and machine learning, we have shown that good results can be achieved using
      quick, efficient, and reproducible methods.
    </Paragraph>
  </React.Fragment>
);

export const heartFailureIntro = (
  <React.Fragment>
    <Title level={4} style={{ textAlign: "center" }}>
      Heart Failure Identification
    </Title>
    <Paragraph style={{ textAlign: "center" }}>
      <a
        href="https://www.sciencedirect.com/science/article/pii/S1071916420300300?casa_token=RDXaW3WdVP8AAAAA:2wmRG7TiA216dOSLZh7skBreZmr9mccdWmKUt2h_otEAPTG79q7-gJDm0PzjdtUw-2u962Gp7A"
        target="_blank"
        rel="noopener noreferrer"
      >
        Enhancing ICD-Code-Based Case Definition for Heart Failure Using Electronic Medical Record Data
      </a>
    </Paragraph>
    <Paragraph style={largerFont}>
      Heart Failure (HF) is a chronic condition with a large impact on both patients and health care systems. In Canada
      (2004-2013), the average cost of direct services for a patient is $10,000. There have been more than 42,000
      hospitalizations annually in that time frame, with an average stay of six days. Decreasing the cost and improving
      the quality of care for HF patients is a top priority.
    </Paragraph>
    <Paragraph style={largerFont}>
      When identifying how common HF is, surveillance is often done using the International Classification of Diseases
      (ICD) codes in administrative databases. ICD algorithms for identifying the prevalence of HF tend to have poor
      sensitivity. An alternative is to perform manual chart review, though this process is tedious, time consuming, and
      requires medical experts, making it impracticable for large studies.
    </Paragraph>
    <Paragraph style={largerFont}>
      To overcome this, we have created and tested algorithms that identify HF within a patient medical chart. A gold
      standard for evaluation is created by manual chart review, and four algorithms are created. For full details,
      please see our{" "}
      <a
        href="https://www.sciencedirect.com/science/article/pii/S1071916420300300?casa_token=RDXaW3WdVP8AAAAA:2wmRG7TiA216dOSLZh7skBreZmr9mccdWmKUt2h_otEAPTG79q7-gJDm0PzjdtUw-2u962Gp7A"
        target="_blank"
        rel="noopener noreferrer"
      >
        publication
      </a>
      .
    </Paragraph>
  </React.Fragment>
);

export const heartFailureResultsIntro = (
  <React.Fragment>
    <Paragraph style={largerFont}>
      Of the 2106 patient charts that were reviewed, 296 (14.1%) were determined to have Heart Failure (HF). Details of
      the cohort can be seen in the pie charts below.
    </Paragraph>
    <Paragraph style={largerFont}>
      The six selected features in the machine learning algorithm were heart failure, congestive heart failure,
      Carvedilol (a drug that can be used to treat HF), functional disorder, furosemide (a drug that can be used to
      treat HF), and Lasix (the brand name for furosemide). A decision tree was fit with these features, and it showed
      that the only features that separated cases with HF and cases without HF were the concepts of heart failure and
      congestive heart failure. The final machine learning model (decision tree) was fit using only these two features.
    </Paragraph>
    <Paragraph style={largerFont}>
      The sensitivity, specificity, PPV, NPV, and F1 score of the algorithms can be seen in the bar chart below. As can
      be seen, the machine learning algorithm had the highest F1 score, showing that it is the most balanced algorithm
      with regards to sensitivity and PPV. For a breakdown of what each metric means, see our{" "}
      <a href="/performance-metrics">performance metrics page</a>.
    </Paragraph>
  </React.Fragment>
);

export const heartFailureMatters = (
  <Paragraph style={largerFont}>
    Accurately identifying heart failure is important for measuring health system performance and risk adjustment. The
    gold standard for identifying heart failure is chart review. However, this is time consuming and costly. We have
    performed chart review on a cohort of 3043 medical charts. Then, we have shown that heart failure can be accurately
    identified using quick, efficient, and reproducible methods by utilizing electronic medical records and machine
    learning methods.
  </Paragraph>
);

export const conceptModelInfo = (
  <Paragraph style={{ marginBottom: 0 }}>
    A machine learning model was created using UMLS concepts that appear in patient charts, with the top features being
    used to predict whether hypertension is present.
  </Paragraph>
);

export const documentConceptModelInfo = (
  <Paragraph style={{ marginBottom: 0 }}>
    A machine learning model was created using the combination of UMLS concepts and the documents they appear in. The
    top document-concept pair features are used to predict whether hypertension is present.
  </Paragraph>
);

export const conceptSearchInfo = (
  <Paragraph style={{ marginBottom: 0 }}>
    The results of the machine learning models have shown that the UMLS concept for hypertension (CUI C0020538) is the
    most important concept for identifying hypertension. This algorithm predicts hypertension as present if this CUI is
    present.
  </Paragraph>
);

export const icdAlgorithmInfo = (
  <Paragraph style={{ marginBottom: 0 }}>
    The ICD algorithm looks at discharge abstracts and checks for ICD codes corresponding to hypertension. If a matching
    code is found, hypertension is classified as present.
  </Paragraph>
);

export const pressureUlcerIntro = (
  <React.Fragment>
    {" "}
    <Title level={4} style={{ textAlign: "center" }}>
      Pressure Injury Identification
    </Title>
    <Paragraph style={largerFont}>
      Pressure injuries (PIs) are localized damage to skin tissues that occur due to long-term pressure on the tissues.
      PIs can drastically extend the duration of a patient's hospital stay, and can lead to long-term morbidities. This
      can lead to the consumption of significant clinical resources.
    </Paragraph>
    <Paragraph style={largerFont}>
      One method to determine the prevalence of PIs is to use International Classification of Diseases (ICD) codes in
      administrative databases. However, the ICD codes are often incomplete, leading to underrepresentation of PIs in
      administrative data.
    </Paragraph>
    <Paragraph style={largerFont}>
      Electronic medical records (EMRs) are a large wealth of information. However, much of this information comes in
      free-text form, making it tedious to work with. We have used natural language processing (NLP) to detect PIs in
      EMR data, and have achieved higher performance than using ICD codes to determine whether PIs are present.
    </Paragraph>
  </React.Fragment>
);

export const pressureUlcerResultsIntro = (
  <Paragraph style={largerFont}>
    Each of the models were built using the five different combinations of nursing notes. The different types of nursing
    notes used are: multidisciplinary progress records (MPRs), patient assessments (PAs), patient assessment tools
    (PATs), and filtered patient assessments (FPAs). For comparison, a threshold of 99% of the ICD-10 algorithm
    specificity was chosen from the case definition of ICD-10 codes. There are 7 ICD-10-CA codes corresponding to
    pressure injuries which were used in the definition.
  </Paragraph>
);

export const pressureUlcerAlgorithmIntro = (
  <Paragraph style={largerFont}>
    The first chart displays the results from the ICD-10 algorithm, and other results can be added for comparison. The
    charts following display the results of the gradient boost model. Each chart represents the results of the model for
    a given combination of documents. Sensitivity, specificity, positive predictive value (PPV), and negative predictive
    value (NPV) are reported for ICD-10 and comparisons. The F1 score and area under the curve (AUC) are also presented
    for the gradient boost model. For a breakdown of what each metric means, see our{" "}
    <a href="/performance-metrics">performance metrics page</a>.
  </Paragraph>
);

export const specificityThreshold = (
  <Paragraph style={{ ...largerFont, paddingRight: 10, paddingTop: 2 }}>Specificity Threshold: </Paragraph>
);

export const pressureUlcerMatters = (
  <Paragraph style={largerFont}>
    Accurately identifying pressure injuries is important for measuring health system performance and risk adjustment.
    The gold standard for identifying pressure injuries is chart review. However, this is time consuming and costly.
    Using clinical trial data as a reference standard, we have shown that pressure injuries can be detected through the
    use of machine learning and electronic medical records.
  </Paragraph>
);

export const whatArePerformanceMetrics = (
  <>
    <Title level={4} style={{ textAlign: "center" }}>
      Performance Metrics
    </Title>
    <Paragraph style={largerFont}>
      Performance metrics are used to evaluate how well an algorithm works. There are many different metrics that can be
      used, depending on the goal of the algorithm. If the goal of the system is to find all of the positive cases for a
      human to sort through and verify, sensitivity is an important metric. However, if there will be little human input
      and it's more important to only identify positive cases and not any incorrectly identify negative cases as
      positive, the positive predictive value (PPV) may be a more important metric. The most common performance metrics
      are detailed below.
    </Paragraph>
  </>
);

export const confusionMatrixText = (topPadding) => (
  <Paragraph style={{ ...largerFont, paddingTop: topPadding }}>
    This table is known as a confusion matrix, and displays the outcomes available when making a binary prediction. True
    positives (TPs), false negatives (FNs), false positives (FPs) and true negatives (TNs) are the four possible
    outcomes, and the quantity of each can be used to calculate performance metrics.
  </Paragraph>
);

export const sensitivityDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    Sensitivity (also known as recall) is the ratio of true positives to true positives and false negatives. Simplified,
    it is the number of positive cases correctly identified over the total number of actual positive cases. It measures
    how many of the positive cases were correctly indentified.
  </Paragraph>
);

export const specificityDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    Specificity is the ratio of true negatives to true negatives and false positives. Simplified, it is the number of
    negative cases correctly indentified over the total number of actual negative cases. It measures how many of the
    negative cases were correctly identified.
  </Paragraph>
);

export const ppvDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    Positive predictive value (PPV, also known as precision) is the ratio of true positives to true positives and false
    positives. It is the number of correct positive predictions over the total number of positive predictions.
  </Paragraph>
);

export const npvDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    Negative predictive value (NPV) is the ratio of true negatives to true negatives and false negatives. It is the
    number of correct negatives predictions over the total number of negatives predictions.
  </Paragraph>
);

export const fscoreDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    The F-score is the harmonic mean of sensitivity and PPV. It is used when it is desirable to have a balanced
    algorithm that focuses on accurately identifying all positive cases, while avoiding false positives.
  </Paragraph>
);

export const aucrocDescription = (leftPadding) => (
  <Paragraph style={{ ...largerFont, paddingLeft: leftPadding }}>
    The area under the curve - receiver operator characterisitic (AUC-ROC) is a plot of the true positive rate (TPR,
    sensitivity) against the false positive rate (FPR, 1-specificity). The area under the curve is the measure of how
    well a classifier can distinguish between positive and negative cases. A perfect classifier would have a line
    straight up, then straight across, and have a value of 1. A classifier that incorrectly labeled every sample would
    be straight across, then straight up, with a value of 0. The dashed line indicates a classifier which either
    randomly predicts the class, or predicts the same class every time.
  </Paragraph>
);

export const dataAssetsInfo = (
  <Paragraph style={{ margin: 0 }}>
    Click on a data category to get started, then select a data asset to view details.
  </Paragraph>
);

export const heartFailureInfo = <Paragraph style={{ margin: 0 }}>Hover over the cards to see details.</Paragraph>;

export const hypertensionInfo = (
  <Paragraph style={{ margin: 0 }}>Click on a category to view details on the individual steps.</Paragraph>
);

export const pressureInjuryInfo = (
  <Paragraph style={{ margin: 0 }}>
    Click on a category to get started, then click on a subcategory for details.
  </Paragraph>
);
