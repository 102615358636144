import React, { useState, useEffect } from "react";
import MenuBar from "../../Components/MenuBar/MenuBar";
import DataAssetsFooter from "./DataAssetsFooter";
import { Layout, Modal, Row, Col } from "antd";
import HierarchyDatasets from "../../Components/Datasets/HierarchyDatasets";
import DatasetDetails from "../../Components/Datasets/DatasetDetails";
import Comparison from "../../Components/Comparison/Comparison";
import MoreInfo from "../../Components/MoreInfo/MoreInfo";
import InfoHover from "../../Util/InfoHover";
import { throttle } from "lodash";
import { mainSizes, detailSizes, getMainWidth, getDetailWidth, getWindowBracket } from "../../Util/layout";
import { dataAssetsInfo } from "../../Util/writing";

const { Content } = Layout;

const DataAssetsPage = () => {
  const [contentWidth, setContentWidth] = useState(window.innerWidth);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 130);
  const [modalContent, setModalContent] = useState(null);
  const sizeBracket = getWindowBracket(contentWidth);

  const resizeListener = () => {
    setContentWidth(document.getElementById("content").getBoundingClientRect().width);
    setContentHeight(window.innerHeight - 130);
  };
  const throttledResizeListener = throttle(resizeListener, 200);

  // componentDidMount and willUnmount
  useEffect(() => {
    window.addEventListener("resize", throttledResizeListener);
    return () => window.removeEventListener("resize", throttledResizeListener);
  }, []);

  const getModalContent = () => {
    switch (modalContent) {
      case "compare":
        return <Comparison height={contentHeight} width={contentWidth} />;
      case "info":
        return <MoreInfo height={contentHeight} width={contentWidth} />;
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <MenuBar setModalContent={setModalContent} bracket={sizeBracket} compare width={contentWidth} />
        <Content id="content">
          <InfoHover content={dataAssetsInfo} backgroundColor={null} />
          <Row>
            <Col xs={mainSizes.xs} sm={mainSizes.sm} md={mainSizes.md} lg={mainSizes.lg} xl={mainSizes.xl}>
              <HierarchyDatasets
                width={getMainWidth(contentWidth)}
                height={sizeBracket === "xs" || sizeBracket === "sm" ? contentWidth : contentHeight - 30}
              />
            </Col>
            <Col xs={detailSizes.xs} sm={detailSizes.sm} md={detailSizes.md} lg={detailSizes.lg} xl={detailSizes.xl}>
              <DatasetDetails width={getDetailWidth(contentWidth)} height={contentHeight - 30} />
            </Col>
          </Row>
        </Content>
        <DataAssetsFooter bracket={sizeBracket} setModalContent={setModalContent} />
      </Layout>
      <Modal
        visible={Boolean(modalContent)}
        onCancel={() => {
          setModalContent(null);
        }}
        footer={null}
        closable={false}
        width="80%"
        style={{ top: 50 }}
      >
        {getModalContent()}
      </Modal>
    </React.Fragment>
  );
};

export default DataAssetsPage;
