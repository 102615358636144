import React, { useEffect } from "react";
import { getMethodsConstants } from "./HypertensionMethodsConstants";
import { getDataLines } from "../../Util/d3TextUtil";
import * as d3 from "d3";

const hypertensionMethodsClass = "hypertensionMethods";
let svg, categoryGs;
let CONSTANTS;
let handlingClick = false;
let currentState = 0; // 0 - categories, 1 - data, 2 - concept extraction, 3 - feature selection, 4 - models

const HypertensionMethods = (props) => {
  useEffect(() => {
    handlingClick = false;
    CONSTANTS = getMethodsConstants(props.width, props.height);
    drawDetails();
  }, []);

  useEffect(() => {
    handlingClick = false;
    CONSTANTS = getMethodsConstants(props.width, props.height);
    drawDetails();
  }, [props.width, props.height]);

  // Sleep, used to wait between transitions
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleCategoryRectClick = async (d, i, transitionDuration = CONSTANTS.duration) => {
    if (handlingClick) {
      return;
    } else {
      handlingClick = true;
      currentState = i + 1;

      // tranisitioning categoryG
      svg
        .selectAll("g.categoryG")
        .sort((a, _) => {
          if (a === d) {
            return 1;
          }
          return -1;
        })
        .transition()
        .duration(0)
        .attr("cursor", "auto")
        .selectAll("rect.categoryRect")
        .transition()
        .duration(transitionDuration)
        .attr("width", (e) => {
          if (d === e) {
            return props.width - 20;
          }
          return CONSTANTS.categoryRectWidth;
        })
        .attr("height", (e) => {
          if (d === e) {
            return CONSTANTS.totalCategoryHeight;
          }
          return CONSTANTS.categoryRectHeight;
        })
        .attr("x", (e) => {
          if (d === e) {
            return -CONSTANTS.categoryXCoords[i];
          }
        })
        .attr("y", (e) => {
          if (d === e) {
            return -CONSTANTS.categoryYCoords[i] + 1.5 * CONSTANTS.fontSize;
          }
        });

      // transitioning categoryText
      svg
        .selectAll("text.categoryText")
        .transition()
        .duration(transitionDuration)
        .attr("x", (e) => {
          if (d === e) {
            return (props.width - 20) / 2 - CONSTANTS.categoryXCoords[i];
          }
          return CONSTANTS.categoryRectWidth / 2;
        })
        .attr("y", (e) => {
          if (d === e) {
            return 1.2 * CONSTANTS.fontSize - CONSTANTS.categoryYCoords[i];
          }
          return -0.25 * CONSTANTS.fontSize;
        });

      // transitioning details
      const bigData = getBigData(i);
      svg
        .selectAll("g.detailG-" + i)
        .transition()
        .duration(transitionDuration)
        .attr(
          "transform",
          (_, j) => "translate(" + (bigData.x[j] - bigData.categoryX) + "," + (bigData.y[j] - bigData.categoryY) + ")"
        );

      // detail rects
      svg
        .selectAll("rect.detailRect-" + i)
        .transition()
        .duration(transitionDuration)
        .attr("width", bigData.rectWidth)
        .attr("height", bigData.rectHeight);

      // title text
      svg
        .selectAll("text.titleText-" + i)
        .transition()
        .duration(transitionDuration)
        .attr("x", bigData.rectWidth / 2)
        .attr("y", CONSTANTS.detailFontSize)
        .attr("font-weight", "bold")
        .attr("font-size", CONSTANTS.detailFontSize);

      // adding detail text
      for (let j = 0; j < bigData.text.length; j++) {
        const lines = getDataLines(bigData.text[j], CONSTANTS.detailFontSize, bigData.rectWidth, svg);
        svg
          .select("g.detailG-" + i + ":nth-child(" + (j + 4) + ")")
          .selectAll("text.detailText")
          .data(lines)
          .enter()
          .append("text")
          .text((d) => d)
          .attr("x", 5)
          .attr("y", (_, k) => k * CONSTANTS.detailFontSize * 1.1 + props.height / 15)
          .attr("class", "detailText")
          .attr("font-size", 0)
          .style("opacity", 0);
      }

      // transitioning detail text
      svg
        .selectAll("text.detailText")
        .transition()
        .duration(transitionDuration)
        .attr("font-size", CONSTANTS.detailFontSize)
        .style("opacity", 1);

      // transition lines
      svg
        .selectAll("line.detailLine-" + i)
        .transition()
        .duration(transitionDuration)
        .attr("x1", bigData.rectWidth / 2)
        .attr("x2", (_, i) => bigData.lineX2Coords[i])
        .attr("y1", bigData.rectHeight)
        .attr("y2", (_, i) => bigData.lineY2Coords[i])
        .attr("stroke-width", 3)
        .attr("marker-end", (_, i) => bigData.endMarkers[i]);

      await sleep(transitionDuration);
      addBackButton();

      handlingClick = false;
    }
  };

  const addBackButton = () => {
    svg.select("g.backG").remove();
    const backG = svg
      .append("g")
      .attr("class", "backG")
      .attr("transform", "translate(" + 10 + "," + 2 * CONSTANTS.fontSize + ")")
      .on("click", handleBackClick)
      .attr("cursor", "pointer");

    backG
      .append("text")
      .attr("class", "backText")
      .text("<< Back")
      .attr("x", 5)
      .attr("y", 0.7 * CONSTANTS.fontSize)
      .attr("font-size", 0.9 * CONSTANTS.fontSize)
      .style("fill", "white");
  };

  const handleBackClick = async () => {
    if (handlingClick) {
      return;
    } else {
      handlingClick = true;
      svg.selectAll("g.categoryG").transition().duration(CONSTANTS.duration).attr("cursor", "pointer");

      // transitioning category rect to proper spot
      svg
        .selectAll("rect.categoryRect")
        .transition()
        .duration(CONSTANTS.duration)
        .attr("width", CONSTANTS.categoryRectWidth)
        .attr("height", CONSTANTS.categoryRectHeight)
        .attr("x", 0)
        .attr("y", 0);

      // transitioning category title text
      svg
        .selectAll("text.categoryText")
        .transition()
        .duration(CONSTANTS.duration)
        .attr("x", CONSTANTS.categoryRectWidth / 2)
        .attr("y", -0.25 * CONSTANTS.fontSize);

      // remove back G
      svg.select("g.backG").remove();

      // transition detailGs to position
      const smallData = getSmallData(currentState - 1);
      svg
        .selectAll("g.detailG-" + (currentState - 1))
        .transition()
        .duration(CONSTANTS.duration)
        .attr("transform", (_, j) => "translate(" + smallData.x[j] + "," + smallData.y[j] + ")");

      // transition detail Rects to size
      svg
        .selectAll("rect.detailRect-" + (currentState - 1))
        .transition()
        .duration(CONSTANTS.duration)
        .attr("width", CONSTANTS.smallRectWidth)
        .attr("height", CONSTANTS.smallRectHeight);

      // transition title text to position and size
      svg
        .selectAll("text.titleText-" + (currentState - 1))
        .transition()
        .duration(CONSTANTS.duration)
        .attr("font-size", CONSTANTS.smallFontSize)
        .attr("x", CONSTANTS.smallRectWidth / 2)
        .attr("y", CONSTANTS.smallRectHeight / 2 + CONSTANTS.smallFontSize / 4)
        .attr("font-weight", "normal");

      // transition lines
      svg
        .selectAll("line.detailLine-" + (currentState - 1))
        .transition()
        .duration(CONSTANTS.duration)
        .attr("x1", CONSTANTS.smallRectWidth / 2)
        .attr("x2", (_, i) => smallData.smallX2[i])
        .attr("y1", CONSTANTS.smallRectHeight)
        .attr("y2", (_, i) => smallData.smallY2[i])
        .attr("stroke-width", 2)
        .attr("marker-end", (_, i) => smallData.endMarkers[i]);

      // transition detail text out
      svg
        .selectAll("text.detailText")
        .transition()
        .duration(CONSTANTS.duration)
        .attr("font-size", 0)
        .style("opacity", 0);
      await sleep(CONSTANTS.duration);
      svg.selectAll("text.detailText").remove();

      currentState = 0;
      handlingClick = false;
    }
  };

  const addDefs = () => {
    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowMiddleData")
      .attr("refX", -(CONSTANTS.dataRectHeight - (CONSTANTS.dataYCoords[3] - CONSTANTS.dataYCoords[1])) / 6 + 2.5)
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowSideData")
      .attr(
        "refX",
        Math.sqrt(
          Math.pow(CONSTANTS.dataXCoords[3] - CONSTANTS.dataXCoords[0], 2) +
            Math.pow(CONSTANTS.dataYCoords[3] - (CONSTANTS.dataYCoords[0] + CONSTANTS.dataRectHeight), 2)
        ) /
          6 +
          2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowConcept")
      .attr("refX", CONSTANTS.conceptRectVerticalGap / 6 + 2.5)
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "featureSideConcept")
      .attr(
        "refX",
        Math.sqrt(
          Math.pow(CONSTANTS.featureXCoords[2] - CONSTANTS.featureXCoords[0], 2) +
            Math.pow(CONSTANTS.featureYCoords[2] - (CONSTANTS.featureYCoords[0] + CONSTANTS.featureRectHeight), 2)
        ) /
          6 +
          2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "categoryVertical")
      .attr(
        "refX",
        Math.sqrt(
          Math.pow(CONSTANTS.categoryLineX1[1] - CONSTANTS.categoryLineX2[1], 2) +
            Math.pow(CONSTANTS.categoryLineY1[1] - CONSTANTS.categoryLineY2[1], 2)
        ) /
          6 +
          2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.categoryLineColor)
      .style("fill", CONSTANTS.categoryLineColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "categoryHorizontal")
      .attr(
        "refX",
        (CONSTANTS.categoryXCoords[1] - CONSTANTS.categoryXCoords[0] - CONSTANTS.categoryRectWidth) / 6 + 2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.categoryLineColor)
      .style("fill", CONSTANTS.categoryLineColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "smallStandard")
      .attr(
        "refX",
        (CONSTANTS.conceptYCoordsSmall[1] - CONSTANTS.conceptYCoordsSmall[0] - CONSTANTS.smallRectHeight) / 4 + 2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "smallDataMiddle")
      .attr(
        "refX",
        (CONSTANTS.dataYCoordsSmall[3] - CONSTANTS.dataYCoordsSmall[1] - CONSTANTS.smallRectHeight) / 4 + 2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "smallDataSide")
      .attr(
        "refX",
        Math.sqrt(
          Math.pow(CONSTANTS.dataXCoordsSmall[3] - CONSTANTS.dataXCoordsSmall[0], 2) +
            Math.pow(CONSTANTS.dataYCoordsSmall[3] - CONSTANTS.dataYCoordsSmall[1] - CONSTANTS.smallRectHeight, 2)
        ) /
          4 +
          2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);

    svg
      .append("defs")
      .append("marker")
      .attr("id", "smallFeature")
      .attr(
        "refX",
        Math.sqrt(
          Math.pow(CONSTANTS.featureXCoordsSmall[2] - CONSTANTS.featureXCoordsSmall[0], 2) +
            Math.pow(CONSTANTS.featureYCoordsSmall[2] - CONSTANTS.featureYCoordsSmall[0] - CONSTANTS.smallRectHeight, 2)
        ) /
          4 +
          2.5
      )
      .attr("refY", 2.5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(CONSTANTS.arrowPoints))
      .style("stroke", CONSTANTS.detailColor)
      .style("fill", CONSTANTS.detailColor);
  };

  const getSmallData = (i) => {
    switch (i) {
      case 0:
        return {
          titles: CONSTANTS.databases,
          x: CONSTANTS.dataXCoordsSmall,
          y: CONSTANTS.dataYCoordsSmall,
          smallX2: CONSTANTS.dataLineX2Small,
          smallY2: CONSTANTS.dataLineY2Small,
          endMarkers: CONSTANTS.dataSmallEndMarkers,
        };
      case 1:
        return {
          titles: CONSTANTS.concepts,
          x: CONSTANTS.conceptXCoordsSmall,
          y: CONSTANTS.conceptYCoordsSmall,
          smallX2: CONSTANTS.conceptLineX2Small,
          smallY2: CONSTANTS.conceptLineY2Small,
          endMarkers: CONSTANTS.conceptSmallEndMarkers,
        };
      case 2:
        return {
          titles: CONSTANTS.models,
          x: CONSTANTS.modelXCoordsSmall,
          y: CONSTANTS.modelYCoordsSmall,
          smallX2: CONSTANTS.modelLineX2Small,
          smallY2: CONSTANTS.modelLineY2Small,
          endMarkers: CONSTANTS.modelSmallEndMarkers,
        };
      case 3:
        return {
          titles: CONSTANTS.features,
          x: CONSTANTS.featureXCoordsSmall,
          y: CONSTANTS.featureYCoordsSmall,
          smallX2: CONSTANTS.featureLineX2Small,
          smallY2: CONSTANTS.featureLineY2Small,
          endMarkers: CONSTANTS.featureSmallEndMarkers,
        };
      default:
        break;
    }
  };

  const getBigData = (i) => {
    const bigData = {};
    switch (i) {
      case 0:
        bigData.text = CONSTANTS.dataText;
        bigData.x = CONSTANTS.dataXCoords;
        bigData.y = CONSTANTS.dataYCoords;
        bigData.rectWidth = CONSTANTS.dataRectWidth;
        bigData.rectHeight = CONSTANTS.dataRectHeight;
        bigData.endMarkers = CONSTANTS.dataEndMarkers;
        bigData.lineX2Coords = CONSTANTS.dataLineX2Coords;
        bigData.lineY2Coords = CONSTANTS.dataLineY2Coords;
        break;
      case 1:
        bigData.text = CONSTANTS.conceptText;
        bigData.x = CONSTANTS.conceptXCoords;
        bigData.y = CONSTANTS.conceptYCoords;
        bigData.rectWidth = CONSTANTS.conceptRectWidth;
        bigData.rectHeight = CONSTANTS.conceptRectHeight;
        bigData.endMarkers = CONSTANTS.conceptEndMarkers;
        bigData.lineX2Coords = CONSTANTS.conceptLineX2Coords;
        bigData.lineY2Coords = CONSTANTS.conceptLineY2Coords;
        break;
      case 2:
        bigData.text = CONSTANTS.modelText;
        bigData.x = CONSTANTS.modelXCoords;
        bigData.y = CONSTANTS.modelYCoords;
        bigData.rectWidth = CONSTANTS.modelRectWidth;
        bigData.rectHeight = CONSTANTS.modelRectHeight;
        bigData.endMarkers = CONSTANTS.modelEndMarkers;
        bigData.lineX2Coords = CONSTANTS.modelLineX2Coords;
        bigData.lineY2Coords = CONSTANTS.modelLineY2Coords;
        break;
      case 3:
        bigData.text = CONSTANTS.featureText;
        bigData.x = CONSTANTS.featureXCoords;
        bigData.y = CONSTANTS.featureYCoords;
        bigData.rectWidth = CONSTANTS.featureRectWidth;
        bigData.rectHeight = CONSTANTS.featureRectHeight;
        bigData.endMarkers = CONSTANTS.featureEndMarkers;
        bigData.lineX2Coords = CONSTANTS.featureLineX2Coords;
        bigData.lineY2Coords = CONSTANTS.featureLineY2Coords;
        break;
      default:
        break;
    }
    bigData.categoryX = CONSTANTS.categoryXCoords[i];
    bigData.categoryY = CONSTANTS.categoryYCoords[i];
    return bigData;
  };

  const drawDetails = () => {
    d3.select("div." + hypertensionMethodsClass)
      .select("svg")
      .remove();

    svg = d3
      .select("div." + hypertensionMethodsClass)
      .append("svg")
      .attr("width", "100%")
      .attr("height", props.height);

    categoryGs = svg
      .selectAll("g.categoryG")
      .data(CONSTANTS.categories)
      .enter()
      .append("g")
      .attr("class", "categoryG")
      .attr(
        "transform",
        (_, i) => "translate(" + CONSTANTS.categoryXCoords[i] + "," + CONSTANTS.categoryYCoords[i] + ")"
      )
      .attr("cursor", "pointer")
      .on("click", (d, i) => {
        handleCategoryRectClick(d, i);
      });

    // lines between categories
    categoryGs
      .data(CONSTANTS.categories)
      .append("line")
      .attr("class", "categoryLinking")
      .attr("x1", (_, i) => CONSTANTS.categoryLineX1[i])
      .attr("x2", (_, i) => CONSTANTS.categoryLineX2[i])
      .attr("y1", (_, i) => CONSTANTS.categoryLineY1[i])
      .attr("y2", (_, i) => CONSTANTS.categoryLineY2[i])
      .attr("stroke", CONSTANTS.categoryLineColor)
      .attr("stroke-width", 3)
      .attr("marker-end", (_, i) => CONSTANTS.categoryEndMarkers[i]);

    // category rects
    categoryGs
      .data(CONSTANTS.categories)
      .append("rect")
      .attr("class", "categoryRect")
      .attr("width", CONSTANTS.categoryRectWidth)
      .attr("height", CONSTANTS.categoryRectHeight)
      .attr("rx", CONSTANTS.rx)
      .attr("ry", CONSTANTS.ry)
      .attr("fill", (_, i) => CONSTANTS.categoryColors[i]);

    // category title text
    categoryGs
      .data(CONSTANTS.categories)
      .append("text")
      .text((d) => d)
      .attr("class", "categoryText")
      .attr("font-size", CONSTANTS.fontSize)
      .attr("font-weight", "bold")
      .attr("x", CONSTANTS.categoryRectWidth / 2)
      .attr("y", -0.25 * CONSTANTS.fontSize)
      .attr("text-anchor", "middle");

    // details
    for (let i = 0; i < categoryGs._groups[0].length; i++) {
      const smallData = getSmallData(i);
      const contentGs = svg
        .select("g.categoryG:nth-child(" + (i + 1) + ")")
        .selectAll("g.detailG-" + i)
        .data(smallData.titles)
        .enter()
        .append("g")
        .attr("class", "detailG-" + i)
        .attr("transform", (_, j) => "translate(" + smallData.x[j] + "," + smallData.y[j] + ")");

      // rects
      contentGs
        .data(smallData.titles)
        .append("rect")
        .attr("width", CONSTANTS.smallRectWidth)
        .attr("height", CONSTANTS.smallRectHeight)
        .attr("fill", CONSTANTS.detailColor)
        .attr("class", "detailRect-" + i)
        .attr("rx", CONSTANTS.rx / 2)
        .attr("ry", CONSTANTS.ry / 2);

      // text
      contentGs
        .data(smallData.titles)
        .append("text")
        .text((d) => d)
        .attr("x", CONSTANTS.smallRectWidth / 2)
        .attr("y", CONSTANTS.smallRectHeight / 2 + CONSTANTS.smallFontSize / 4)
        .attr("class", "titleText-" + i)
        .attr("text-anchor", "middle")
        .attr("font-size", CONSTANTS.smallFontSize);

      // lines
      contentGs
        .data(smallData.titles)
        .append("line")
        .attr("class", "detailLine-" + i)
        .attr("x1", CONSTANTS.smallRectWidth / 2)
        .attr("x2", (_, i) => smallData.smallX2[i])
        .attr("y1", CONSTANTS.smallRectHeight)
        .attr("y2", (_, i) => smallData.smallY2[i])
        .attr("stroke", CONSTANTS.detailColor)
        .attr("stroke-width", 2)
        .attr("marker-end", (_, i) => smallData.endMarkers[i]);
    }

    addDefs();

    if (currentState) {
      handleCategoryRectClick(CONSTANTS.categories[currentState - 1], currentState - 1, 0);
    }
  };

  return <div className={hypertensionMethodsClass} style={{ backgroundColor: "white" }} />;
};

export default HypertensionMethods;
