import React, { useState } from "react";
import { PieChart, Pie, Cell, RadarChart, Radar, PolarGrid, PolarAngleAxis } from "recharts";
import { Button, Row, Col, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  hypertensionResults,
  hypertensionMatters,
  conceptModelInfo,
  documentConceptModelInfo,
  conceptSearchInfo,
  icdAlgorithmInfo,
} from "../../Util/writing";
import {
  renderLabel,
  agePie,
  servicePie,
  sexPie,
  getResultsConstants,
  radarData,
  categoryMapping,
} from "./HypertensionResultsHelpers";
import { getNarrowTextDivStyle } from "../../Util/util";

const h3Style = { marginBottom: 0, display: "inline-block", margin: "0 auto", fontFamily: "TitleFont" };
const popoverDivStyle = { display: "inline-block", margin: "0 auto", marginLeft: 5 };

const HypertensionResults = (props) => {
  const CONSTANTS = getResultsConstants(props.width, props.height);
  const [selectedPie, setSelectedPie] = useState("Overall");

  const onPieClick = (entry) => {
    setSelectedPie(entry.name);
  };

  const getRadarColor = () => {
    if (selectedPie === "Overall") {
      return CONSTANTS.overallColor;
    }
    const category = categoryMapping[selectedPie];
    let pieToUse;
    if (category === "Age") {
      pieToUse = agePie;
    } else if (category === "Service") {
      pieToUse = servicePie;
    } else {
      // category === "Sex"
      pieToUse = sexPie;
    }
    const value = pieToUse.find((point) => point.name === selectedPie);
    return value.color;
  };

  const getTickFormat = (name, model) => {
    const data = radarData.find((point) => point.name === name);
    return name + ": " + data[selectedPie + model] + "%";
  };

  const makePie = (pie) => {
    return (
      <Pie
        data={pie}
        dataKey="value"
        nameKey="name"
        outerRadius={CONSTANTS.outerRadius}
        cx={CONSTANTS.cx}
        cy={CONSTANTS.cy}
        label={renderLabel}
        onClick={onPieClick}
        style={{ cursor: "pointer" }}
      >
        {pie.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    );
  };

  return (
    <div
      style={{
        margin: "auto",
        width: `${CONSTANTS.viewWidth * 100}%`,
        padding: 20,
        paddingBottom: 0,
        backgroundColor: "white",
        height: props.height,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div style={getNarrowTextDivStyle(props.width)}>{hypertensionResults}</div>
      <Button
        type="primary"
        shape="round"
        size="small"
        style={{
          backgroundColor: CONSTANTS.overallColor,
          borderColor: CONSTANTS.overallColor,
          paddingLeft: 30,
          paddingRight: 30,
        }}
        onClick={() => setSelectedPie("Overall")}
      >
        {"Overall Results"}
      </Button>

      {/* Pie chart row */}
      <Row style={{ textAlign: "center" }}>
        {/* Age column */}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h3 style={{ marginBottom: 0 }}>Age</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidth}>
            {makePie(agePie)}
          </PieChart>
          <br />
        </Col>
        {/* Service column */}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h3 style={{ marginBottom: 0 }}>Service</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidth}>
            {makePie(servicePie)}
          </PieChart>
          <br />
        </Col>
        {/* Sex column */}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h3 style={{ marginBottom: 0 }}>Sex</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidth}>
            {makePie(sexPie)}
          </PieChart>
          <br />
        </Col>
      </Row>

      <h2 style={{ marginTop: 20 }}>
        {selectedPie === "Overall"
          ? "Overall Results"
          : "Results for " + categoryMapping[selectedPie] + ": " + selectedPie}
      </h2>

      {/* Radar chart row */}
      <Row style={{ textAlign: "center", marginTop: 0 }}>
        {/* Concept Model column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <h3 style={h3Style}>Concept Model</h3>
          <div style={popoverDivStyle}>
            <Popover
              trigger="hover"
              content={conceptModelInfo}
              placement="top"
              overlayStyle={{
                width:
                  CONSTANTS.bracket === "xs" || CONSTANTS.bracket === "sm" || CONSTANTS.bracket === "md"
                    ? "66%"
                    : "33%",
              }}
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
          <RadarChart
            outerRadius={CONSTANTS.radarChartOuterRadius}
            width={CONSTANTS.radarChartWidth}
            height={CONSTANTS.radarChartHeight}
            data={radarData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey="name"
              tickFormatter={(name) => getTickFormat(name, "C")}
              style={{ fontSize: CONSTANTS.radarLabelFontSize }}
            />
            <Radar dataKey={selectedPie + "C"} stroke={getRadarColor()} fill={getRadarColor()} fillOpacity={0.7} />
          </RadarChart>
        </Col>
        {/* Document-Concept Model column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <h3 style={h3Style}>Document-Concept Model</h3>
          <div style={popoverDivStyle}>
            <Popover
              trigger="hover"
              content={documentConceptModelInfo}
              placement="top"
              overlayStyle={{
                width:
                  CONSTANTS.bracket === "xs" || CONSTANTS.bracket === "sm" || CONSTANTS.bracket === "md"
                    ? "66%"
                    : "33%",
              }}
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
          <RadarChart
            outerRadius={CONSTANTS.radarChartOuterRadius}
            width={CONSTANTS.radarChartWidth}
            height={CONSTANTS.radarChartHeight}
            data={radarData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey="name"
              tickFormatter={(name) => getTickFormat(name, "DC")}
              style={{ fontSize: CONSTANTS.radarLabelFontSize }}
            />
            <Radar dataKey={selectedPie + "DC"} stroke={getRadarColor()} fill={getRadarColor()} fillOpacity={0.7} />
          </RadarChart>
        </Col>
        {/* Concept Search column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <h3 style={h3Style}>Concept Search</h3>
          <div style={popoverDivStyle}>
            <Popover
              trigger="hover"
              content={conceptSearchInfo}
              placement="top"
              overlayStyle={{
                width:
                  CONSTANTS.bracket === "xs" || CONSTANTS.bracket === "sm" || CONSTANTS.bracket === "md"
                    ? "66%"
                    : "33%",
              }}
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
          <RadarChart
            outerRadius={CONSTANTS.radarChartOuterRadius}
            width={CONSTANTS.radarChartWidth}
            height={CONSTANTS.radarChartHeight}
            data={radarData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey="name"
              tickFormatter={(name) => getTickFormat(name, "CS")}
              style={{ fontSize: CONSTANTS.radarLabelFontSize }}
            />
            <Radar dataKey={selectedPie + "CS"} stroke={getRadarColor()} fill={getRadarColor()} fillOpacity={0.7} />
          </RadarChart>
        </Col>
        {/* ICD Algorithm column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <h3 style={h3Style}>ICD Algorithm</h3>
          <div style={popoverDivStyle}>
            <Popover
              trigger="hover"
              content={icdAlgorithmInfo}
              placement="top"
              overlayStyle={{
                width:
                  CONSTANTS.bracket === "xs" || CONSTANTS.bracket === "sm" || CONSTANTS.bracket === "md"
                    ? "66%"
                    : "33%",
              }}
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
          <RadarChart
            outerRadius={CONSTANTS.radarChartOuterRadius}
            width={CONSTANTS.radarChartWidth}
            height={CONSTANTS.radarChartHeight}
            data={radarData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey="name"
              tickFormatter={(name) => getTickFormat(name, "ICD")}
              style={{ fontSize: CONSTANTS.radarLabelFontSize }}
            />
            <Radar dataKey={selectedPie + "ICD"} stroke={getRadarColor()} fill={getRadarColor()} fillOpacity={0.7} />
          </RadarChart>
          <br />
        </Col>
      </Row>

      <div style={getNarrowTextDivStyle(props.width)}>
        <h2>Why Does This Matter?</h2>
        {hypertensionMatters}
      </div>
    </div>
  );
};
export default HypertensionResults;
