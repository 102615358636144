import React from "react";
import { connect } from "react-redux";
import ConnectCare from "./ConnectCare";
import SCM from "./SCM";

const MoreInfo = (props) => {
  const getInfo = () => {
    switch (props.selectedDataset.title) {
      case "ConnectCare":
        return <ConnectCare height={props.height} width={props.width} />;
      case "Sunrise Clinical Manager":
        return <SCM height={props.height} width={props.width} />;
      default:
        return;
    }
  };

  return getInfo();
};

const mapStateToProps = (state) => {
  return {
    selectedDataset: state.data.selectedDataset,
  };
};

export default connect(mapStateToProps, null)(MoreInfo);
