import React from "react";
import { Typography } from "antd";
import { treeData, detailsWriting } from "./PressureUlcerConstantsHelpers";

const { Title, Paragraph } = Typography;

const PressureUlcerMethodsDetails = (props) => {
  return (
    <div>
      {treeData[props.selectedMain] && treeData[props.selectedMain].children[props.selectedChild] && (
        <>
          <Title level={4} style={{ textAlign: "center" }}>
            {treeData[props.selectedMain].children[props.selectedChild].name}
          </Title>
          <Paragraph style={{ textAlign: "center", paddingRight: 15 }}>
            {detailsWriting[props.selectedMain][props.selectedChild]}
          </Paragraph>
        </>
      )}
    </div>
  );
};

export default PressureUlcerMethodsDetails;
