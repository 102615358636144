import React, { useMemo } from "react";
import { connect } from "react-redux";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell } from "recharts";
import { wordToNumberLag, shortToLong } from "../../Util/util";

const AvailabilityComparison = (props) => {
  const parseData = () => {
    const newData = [];

    for (const dataset of props.datasets) {
      if (dataset.checked) {
        newData.push({
          short: dataset.short ? dataset.short : dataset.title,
          years: [
            dataset.minYear > 2002 ? dataset.minYear : 2002,
            dataset.maxYear === "current"
              ? new Date().getFullYear() + (new Date().getMonth() + 1) / 12 - wordToNumberLag[dataset.lag]
              : dataset.maxYear,
          ],
          lag: dataset.lag,
          lagNumber:
            dataset.maxYear === "current"
              ? [
                  new Date().getFullYear() + (new Date().getMonth() + 1) / 12 - wordToNumberLag[dataset.lag],
                  new Date().getFullYear() + (new Date().getMonth() + 1) / 12,
                ]
              : [dataset.maxYear, dataset.maxYear],
          category: dataset.category,
          minYear: dataset.minYear,
          maxYear: dataset.maxYear,
          minMonth: dataset.minMonth,
          prior: [dataset.minYear < 2002 ? 2002 - (2 * (2002 - dataset.minYear)) / (2002 - 1983) : 2002, 2002],
        });
      }
    }

    return newData;
  };

  const getCategoryColor = (entry) => {
    for (const cat of props.categories) {
      if (cat.name === entry.category) {
        return cat.color;
      }
    }
    return "black";
  };

  const tickFormatter = (value) => {
    if (value === 2000) {
      return 1983;
    }
    if (value === 2001.2 || value === 2000.8) {
      return "/";
    }
    return value;
  };

  const data = useMemo(() => parseData(), [props.datasets, props.bracket]);

  if (!data || data.length <= 0) {
    return (
      <div style={{ height: props.height }}>
        <p style={{ textAlign: "center", fontSize: 18 }}>Add datasets to compare.</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width="95%" height={props.height}>
        <BarChart data={data} margin={{ top: 20, right: 40, left: 15, bottom: 20 }} layout="vertical">
          <XAxis
            type="number"
            label={{
              value: "Year",
              position: "bottom",
              fontSize: 14,
            }}
            domain={[2000, "datamax"]}
            ticks={[2000, 2002, 2005, 2008, 2011, 2014, 2017, 2020, new Date().getFullYear()]}
            tickFormatter={tickFormatter}
          />
          <XAxis
            type="number"
            domain={[2000, new Date().getFullYear()]}
            ticks={[2000.8, 2001.2]}
            tickFormatter={tickFormatter}
            xAxisId="invis"
            axisLine={false}
            tickLine={false}
            mirror
            tickMargin={-14}
            tickSize={16}
            minTickGap={0}
          />
          <XAxis
            type="number"
            domain={[2000, new Date().getFullYear()]}
            ticks={[2000.8, 2001.2]}
            tickFormatter={tickFormatter}
            xAxisId="invis2"
            axisLine={false}
            tickLine={false}
            tickMargin={-46}
            tickSize={16}
            minTickGap={0}
            padding={{ left: -5 }}
          />
          <YAxis
            type="category"
            dataKey="short"
            width={100}
            tick={{ fontWeight: "bold", fontSize: 14 }}
            yAxisId="data"
          />
          <YAxis type="category" dataKey="short" hide yAxisId="lag" />
          <YAxis type="category" dataKey="short" hide yAxisId="prior" />

          <Tooltip
            labelFormatter={(d) => {
              return shortToLong[d];
            }}
            formatter={(_, title, d) => {
              if (title === "years") {
                if (d.payload.short === "ConnectCare") {
                  return ["Implementation ongoing", null];
                }
                const minYear = d.payload.minYear;
                const maxYear = d.payload.maxYear;
                const minMonth = d.payload.minMonth;
                return [
                  "Available From " +
                    (minMonth ? minMonth + " " : "") +
                    minYear +
                    (maxYear === "current" ? "" : " to " + maxYear),
                  null,
                ];
              } else if (title === "prior") {
                return [null, null];
              } else {
                if (d.payload.short === "ConnectCare") {
                  return ["Began " + (d.payload.minMonth ? d.payload.minMonth + " " : "") + d.payload.minYear, null];
                }
                return [d.payload.lag + " Delay", null];
              }
            }}
            itemStyle={{ marginBottom: 0 }}
          />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar dataKey="prior" yAxisId="prior">
            {data.map((entry, index) => (
              <Cell key={index} fill={getCategoryColor(entry)} />
            ))}
          </Bar>
          <Bar dataKey="years" yAxisId="data">
            {data.map((entry, index) => (
              <Cell key={index} fill={getCategoryColor(entry)} />
            ))}
          </Bar>
          <Bar dataKey="lagNumber" fill="grey" yAxisId="lag" />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.data.categories,
    datasets: state.data.datasets,
  };
};

export default connect(mapStateToProps, null)(AvailabilityComparison);
