import { getWindowBracket } from "../../Util/layout";

export const getMethodsConstants = (width, height) => {
  const bracket = getWindowBracket(width);

  let svgHeight,
    rectWidth,
    rectHeight,
    algoRectXs,
    algoRectYs,
    dataRectXs,
    dataRectYs,
    cohortRectXs,
    cohortRectYs,
    headerYs,
    detailFontSize,
    titleFontSize;
  if (bracket === "xs" || bracket === "sm") {
    // calc for small
    // General
    svgHeight = height * 1.5;
    rectWidth = width * 0.7;
    rectHeight = svgHeight * 0.15;
    titleFontSize = Math.min(rectHeight / 12, rectWidth / 12);
    detailFontSize = Math.max(rectHeight / 28, rectWidth / 30);
    headerYs = [svgHeight * 0.04, svgHeight * 0.39, svgHeight * 0.69];

    // Cohort
    cohortRectXs = [width * 0.5 - rectWidth / 2, width * 0.5 - rectWidth / 2, width * 0.5 - rectWidth / 2];
    cohortRectYs = [svgHeight * 0.05, svgHeight * 0.1, svgHeight * 0.15];

    // Data
    dataRectXs = [width * 0.5 - rectWidth / 2, width * 0.5 - rectWidth / 2];
    dataRectYs = [svgHeight * 0.4, svgHeight * 0.45];

    // Algorithms
    algoRectXs = [
      width * 0.5 - rectWidth / 2,
      width * 0.5 - rectWidth / 2,
      width * 0.5 - rectWidth / 2,
      width * 0.5 - rectWidth / 2,
    ];
    algoRectYs = [svgHeight * 0.7, svgHeight * 0.75, svgHeight * 0.8, svgHeight * 0.85];
  } else {
    // calc for big
    // General
    svgHeight = height - 30;
    rectWidth = width * 0.22;
    rectHeight = height * 0.45;
    const cohortStart = width * 0.075;
    const horizontalGap = (width - 2 * cohortStart - 3 * rectWidth) / 2;
    titleFontSize = Math.min(rectHeight / 12, rectWidth / 15);
    detailFontSize = Math.max(rectHeight / 30, rectWidth / 20);
    headerYs = [height * 0.1, height * 0.1, height * 0.1];

    // Cohort
    cohortRectXs = [cohortStart, cohortStart, cohortStart];
    cohortRectYs = [height * 0.15, height * 0.25, height * 0.35];

    // Data
    dataRectXs = [width * 0.5 - rectWidth / 2, width * 0.5 - rectWidth / 2];
    dataRectYs = [height * 0.15, height * 0.25];

    // Algorithms
    algoRectXs = [
      cohortStart + 2 * rectWidth + 2 * horizontalGap,
      cohortStart + 2 * rectWidth + 2 * horizontalGap,
      cohortStart + 2 * rectWidth + 2 * horizontalGap,
      cohortStart + 2 * rectWidth + 2 * horizontalGap,
    ];
    algoRectYs = [height * 0.15, height * 0.25, height * 0.35, height * 0.45];
  }

  // General
  const headerFontSize = titleFontSize * 1.2;
  const mouseOverShift = width * 0.03;
  const selectedRectWidth = rectWidth + mouseOverShift;
  const rectTextOffset = 10;

  ///////////////////////////
  ////// RETURN /////////////
  return {
    // General
    svgHeight: svgHeight,
    rectWidth: rectWidth,
    rectHeight: rectHeight,
    titleFontSize: titleFontSize,
    detailFontSize: detailFontSize,
    headerFontSize: headerFontSize,
    mouseOverShift: mouseOverShift,
    selectedRectWidth: selectedRectWidth,
    rectTextOffset: rectTextOffset,
    rectTextOffsetSelected: (selectedRectWidth - (rectWidth - 2 * rectTextOffset)) / 2,
    headerYs: headerYs,
    duration: 250,
    // Cohort
    cohorts: ["3043 Medical Charts", "2118 With Discharge Summaries", "Chart Review"],
    cohortText: [
      "There were 3043 randomly selected adult patients who were admitted to one of three major acute care hospitals in Calgary. Only live birth admissions were excluded. The charts for these patients were linked to the Sunrise Clinical Manager EMR system, and the DAD.",
      "Of the 3043 patient charts, 2118 included complete discharge summaries. The discharge summary is an essential component of an electronic medical record, so charts without complete discharge summaries were excluded from analyses.",
      "Six nurses underwent training by a clinician researcher. They were tasked with reviewing the charts and determining whether or not heart failure was present. Charts were categorized as 'yes', 'no', or 'possible'. In the cases of a 'possible' classification (n=12), the chart was removed, leading to a data set with 2106 patients.",
    ],
    cohortColors: ["#ffb061", "#ff9324", "#f55b00"],
    cohortRectXs: cohortRectXs,
    cohortRectYs: cohortRectYs,
    // Data
    data: ["Discharge Abstract Database", "Sunrise Clinical Manager"],
    dataText: [
      "The Discharge Abstract Database (DAD) contains coded administrative data that covers all inpatient hospital admissions within Canada. ICD-10 codes are included in the DAD, which represent the diagnoses relevant to the hospital visit.",
      "Sunrise Clinical Manager (SCM) contains EMRs and is the city-wide system used in Calgary. SCM contains both structured (lab values, patient sex/age, etc.) and unstructured (free text) data.",
    ],
    dataColors: ["#b1d3d8", "#82b2b8"],
    dataRectXs: dataRectXs,
    dataRectYs: dataRectYs,
    // Algorithms
    algorithms: ["ICD-10", "Keyword", "Machine Learning", "Structured Data"],
    algoText: [
      "The ICD-10 algorithm was previously established, and is used to identify heart failure from the Discharge Abstract Database (DAD). If any of the codes corresponding to heart failure are present in the DAD entry, heart failure is identified as positive.",
      "The Unified Medical Language System (UMLS) metathesaurus provides Concept Unique Identifiers (CUIs) for medical conditions, and provides many synonyms for each condition. The CUI for 'heart failure' is C0018801. UMLS contains 62 synonyms which map to this CUI. The keyword algorithm searches the discharge summary for these terms and identifies heart failure as present if these terms occur without exclusion terms (e.g. no, not, denied).",
      "The 2106 free text discharge summaries were processed to determine all of the Unified Medical Language System (UMLS) Concept Unique Identifiers (CUIs) that were present in each document. XGBoost was used to select the most important features (CUIs). The top six features were kept, which leads to an easy to interpret algorithm for determining whether heart failure is present.",
      "The B-type natriuretic peptide (BNP) or N-terminal prop BNP test results were extracted from laboratory data, and the inotropic and intravenous diuretic medications from medication data. Heart failure was determined to be present if the BNP or N-terminal pro BNP was over a cutoff value, or if inotropic or intravenous diuretic medications were administered.",
    ],
    algoColors: ["#f5fe64", "#e5ee44", "#b6bd36", "#868d06"], // hf pie colours 2nd and 3rd
    algoRectXs: algoRectXs,
    algoRectYs: algoRectYs,
  };
};
