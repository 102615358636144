import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { feature } from "topojson-client";
import { getDetailText, getDetailImage } from "./HierarchyHelperFunctions";
import { getLines, getTextRadius, wrapText } from "../../Util/d3TextUtil";

// constants
const detailClass = "detailVis";
const duration = 1000;
const textColor = "black";
const fontSize = 10;
const lineHeight = fontSize * 1.1;

const details = [0, 1, 2, 3, 4];
const mapIndex = 4;

// variables
let detailsShowing = false;
let svg, detailGs, titleG, detailTextR, detailScale, titleScale, abMap;

// map projection
const projection = d3.geoAlbers().angle(10);
const path = d3.geoPath().projection(projection);
let features;

const DatasetDetails = (props) => {
  //constants
  const yInitialOffset = props.height / 8;
  const spacingX = props.width * 0.02;
  const spacingY = props.height * 0.03;
  const imageSize = Math.min(
    props.width / 2 - 2 * spacingX,
    (0.85 * props.height - (details.length - 1) * spacingY) / details.length
  );
  const rectWidth = props.width - 4 * spacingX - imageSize;

  useEffect(() => {
    d3.json(process.env.PUBLIC_URL + "/maps/map.json").then((data) => {
      features = feature(data, data.objects["6ab2bb1e-b006-4ce4-a217-1ce9567b9b01202045-1-1ye97fs"]);
      drawDetails();
    });
  }, []);

  useEffect(() => {
    getDetailTextR();
    if (features) {
      drawDetails();
    }
  }, [props.width, props.height]);

  // useRef to hold onto the previous dataset, to generate lines for removeDetails()
  const datasetRef = useRef();
  useEffect(() => {
    // getDetailTextR();
    datasetRef.current = props.selectedDataset;
    if (props.selectedDataset) {
      if (detailsShowing) {
        changeDataset();
      } else {
        addDetails();
      }
    } else {
      if (detailsShowing) {
        removeDetails();
      }
    }
  }, [props.selectedDataset]);
  // const dataset = datasetRef.current;

  // Sleep, used to wait between transitions
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // gets the colour for each zone
  const getMapFill = (obj) => {
    if (props.selectedDataset) {
      let color;
      for (const category of props.categories) {
        if (category.name === props.selectedDataset.category) {
          color = category.color;
          break;
        }
      }
      if (props.selectedDataset.zone.includes(obj.properties.ZONE_NAME)) {
        return color;
      }
    }
    return "white";
  };

  const getDetailTextR = () => {
    detailTextR = 0;
    for (const dataset of props.datasets) {
      for (const i of details) {
        let lines;
        if (dataset.short === "CPCSSN" && i === 3) {
          lines = getLines(getDetailText(dataset, i), lineHeight, rectWidth / (imageSize * 0.6)); //, true);
        } else {
          lines = getLines(getDetailText(dataset, i), lineHeight, rectWidth / (imageSize * 0.6));
        }
        const textR = getTextRadius(lines, lineHeight);
        if (textR > detailTextR) {
          detailTextR = textR;
        }
      }
    }
    detailScale = rectWidth / detailTextR;
    titleScale = 1.2 * detailScale;
  };

  const changeDataset = async () => {
    removeDetails();
    await sleep(duration);
    addDetails();
  };

  const addDetails = (transitionDuration = duration) => {
    createDetails();
    transitionDetails(transitionDuration);
    detailsShowing = true;
  };

  const createDetails = () => {
    detailGs = svg
      .selectAll("g.detailG")
      .data(details)
      .enter()
      .append("g")
      .attr("class", "detailG")
      .attr(
        "transform",
        (_, i) =>
          "translate(" + spacingX + "," + (props.height * 0.15 + i * (imageSize + spacingY) + yInitialOffset) + ")"
      );

    // adding images
    detailGs
      .data(details)
      .append("image")
      .attr("xlink:href", (d) => getDetailImage(props.selectedDataset, d))
      .attr("class", "detailImage")
      .attr("opacity", 0)
      .attr("width", imageSize)
      .attr("height", imageSize);

    // adding text
    detailGs
      .data(details)
      .append("text")
      .attr("class", "detailText")
      .attr("x", (imageSize + spacingX) / detailScale)
      .text((d) => getDetailText(props.selectedDataset, d))
      .attr("transform", "scale(" + detailScale + ") translate(" + 0 + "," + fontSize / 2 + ")")
      .style("fill-opacity", 0)
      .attr("fill", textColor)
      .attr("font-size", fontSize)
      .call(wrapText, rectWidth / detailScale);

    // adding map
    abMap = svg
      .select("g.detailG:nth-child(" + (mapIndex + 1) + ")")
      .selectAll("path.map")
      .data(features.features)
      .enter()
      .append("path")
      .attr("class", "map")
      .attr("d", path)
      .attr("transform", "scale(" + 0.005 * imageSize + ") translate(-130,115)")
      .style("fill", getMapFill)
      .style("stroke", "black")
      .style("stroke-width", 1)
      .style("opacity", 0);

    // adding title
    titleG = svg
      .append("g")
      .attr("class", "titleG")
      .attr(
        "transform",
        "translate(" +
          props.width / 2 +
          "," +
          (props.height * 0.15 - lineHeight * 1.5 * titleScale + yInitialOffset) +
          ")"
      );

    titleG
      .append("text")
      .text(props.selectedDataset.title)
      .attr("class", "titleText")
      .attr("x", 0)
      .attr("transform", "scale(" + titleScale + ")")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("font-size", fontSize)
      .attr("fill-opacity", 0)
      .attr("fill", textColor)
      .call(wrapText, (props.width - 2 * spacingX) / titleScale); //, true);
  };

  const transitionDetails = (transitionDuration) => {
    // transition group element
    svg
      .selectAll("g.detailG")
      .data(details)
      .transition()
      .duration(transitionDuration / details.length)
      .delay((_, i) => ((i + 1) * transitionDuration) / details.length)
      .attr(
        "transform",
        (_, i) => "translate(" + spacingX + "," + (props.height * 0.15 + i * (imageSize + spacingY)) + ")"
      );

    // fade image in
    svg
      .selectAll("image.detailImage")
      .transition()
      .duration(transitionDuration)
      .delay((_, i) => ((i + 1) * transitionDuration) / details.length)
      .attr("opacity", 1);

    abMap
      .transition()
      .duration(transitionDuration)
      .delay(((mapIndex + 1) * transitionDuration) / details.length)
      .style("opacity", 1);

    // fading text in
    svg
      .selectAll("text.detailText")
      .transition(transitionDuration)
      .delay((_, i) => ((i + 1) * transitionDuration) / details.length)
      .style("fill-opacity", 1);

    // title text
    const numLines = svg.selectAll("text.titleText").selectAll("tspan")._groups[0].length;
    titleG
      .transition()
      .duration(transitionDuration / (details.length + 1))
      .attr(
        "transform",
        "translate(" + props.width / 2 + "," + (props.height * 0.15 - lineHeight * (0.5 + numLines) * titleScale) + ")"
      );
    svg
      .selectAll("text.titleText")
      .transition()
      .duration(transitionDuration / (details.length + 1))
      .attr("fill-opacity", 1);
  };

  const removeDetails = async () => {
    // transitioning detailGs
    svg
      .selectAll("g.detailG")
      .data(details)
      .transition()
      .duration(duration / details.length)
      .delay((d) => ((d + 1) * duration) / (details.length + 1))
      .attr("transform", (d, i) => {
        return (
          "translate(" + spacingX + "," + (props.height * 0.15 + i * (imageSize + spacingY) - yInitialOffset) + ")"
        );
      });

    // transitioning image to 0 opacity
    svg
      .selectAll("image.detailImage")
      .data(details)
      .transition()
      .duration(duration / (details.length + 1))
      .delay((d) => ((d + 1) * duration) / (details.length + 1))
      .attr("opacity", 0);

    // fading out map
    abMap
      .transition()
      .duration(duration / (details.length + 1))
      .delay(((mapIndex + 1) * duration) / (details.length + 1))
      .style("opacity", 0);

    // fading out detail text
    svg
      .selectAll("text.detailText")
      .data(details)
      .transition()
      .duration(duration / (details.length + 1))
      .delay((d) => ((d + 1) * duration) / (details.length + 1))
      .style("fill-opacity", 0);

    // fading out title text
    titleG
      .transition()
      .duration(duration / (details.length + 1))
      .attr(
        "transform",
        "translate(" +
          props.width / 2 +
          "," +
          (props.height * 0.15 - lineHeight * 1.5 * titleScale - yInitialOffset) +
          ")"
      );
    svg
      .selectAll("text.titleText")
      .transition()
      .duration(duration / (details.length + 1))
      .attr("fill-opacity", 0);

    await sleep(duration);
    svg.selectAll("g.detailG").remove();
    svg.selectAll("g.titleG").remove();
    detailsShowing = false;
  };

  const drawDetails = () => {
    d3.select("div." + detailClass)
      .select("svg")
      .remove();

    svg = d3
      .select("div." + detailClass)
      .append("svg")
      .attr("width", props.width)
      .attr("height", props.height);

    if (props.selectedDataset) {
      addDetails(0);
    }
  };

  return (
    <React.Fragment>
      <div className={detailClass} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    datasets: state.data.datasets,
    categories: state.data.categories,
    selectedDataset: state.data.selectedDataset,
  };
};

export default connect(mapStateToProps, null)(DatasetDetails);
