import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
} from "recharts";
import { Row, Col } from "antd";
import { heartFailureResultsIntro, heartFailureMatters } from "../../Util/writing";
import {
  getResultsConstants,
  barChartData,
  barChartColors,
  hfPie,
  agePie,
  sexPie,
  charlsonPie,
  bnpPie,
  ivPie,
  surgicalPie,
  stayPie,
  deathPie,
} from "./HeartFailureResultHelpers";
import { getWindowBracket } from "../../Util/layout";
import { renderLabel } from "../Hypertension/HypertensionResultsHelpers";
import HeartFailureAlgorithmFilter from "./HeartFailureAlgorithmFilter";
import { getNarrowTextDivStyle } from "../../Util/util";

const h3Style = { marginBottom: 0, fontFamily: "TitleFont" };

const HeartFailureResults = (props) => {
  const CONSTANTS = getResultsConstants(props.width, props.height);
  const [algorithmsChecked, setAlgorithmsChecked] = useState([
    { algorithm: "ICD-10", checked: true },
    { algorithm: "Keyword", checked: true },
    { algorithm: "ICD-10 & Keyword", checked: true },
    { algorithm: "Machine Learning", checked: true },
    { algorithm: "ICD-10 & Machine Learning", checked: true },
    { algorithm: "Structured Data", checked: true },
    { algorithm: "ICD-10 & Structured Data", checked: true },
    { algorithm: "ICD-10, Keyword & Structured Data", checked: true },
  ]);

  const makeBars = () => {
    return algorithmsChecked.map((algorithm, i) => {
      if (algorithm.checked) {
        return (
          <Bar
            key={i}
            dataKey={algorithm.algorithm}
            unit="%"
            fill={barChartColors[algorithm.algorithm]}
            animationDuration={900 + i * 100}
          />
        );
      }
      return null;
    });
  };

  const makePie = (pie) => {
    return (
      <Pie
        data={pie}
        dataKey="value"
        nameKey="name"
        outerRadius={CONSTANTS.outerRadius}
        cx={CONSTANTS.cx}
        cy={CONSTANTS.cy}
        label={renderLabel}
      >
        {pie.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    );
  };

  return (
    <div
      style={{
        margin: "auto",
        width: `${CONSTANTS.viewWidth * 100}%`,
        padding: 20,
        paddingBottom: 0,
        backgroundColor: "white",
        height: props.height,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div style={getNarrowTextDivStyle(props.width)}>{heartFailureResultsIntro}</div>

      {/* Heart Failure */}
      <h2 style={{ marginBottom: 20, textAlign: "center", marginTop: 40, fontSize: "2em", fontFamily: "TitleFont" }}>
        The Cohort
      </h2>
      <Row style={{ textAlign: "center" }}>
        {/* Heart failure column */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3 style={h3Style}>Heart Failure</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthSingle}>
            {makePie(hfPie)}
          </PieChart>
          <br />
        </Col>
      </Row>

      {/* Structured Data */}
      <h2 style={{ marginBottom: 10, textAlign: "center", fontFamily: "TitleFont" }}>Structured Data</h2>
      <Row style={{ textAlign: "center" }}>
        {/* bnp column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3 style={h3Style}>Blood BNP Level</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthDouble}>
            {makePie(bnpPie)}
          </PieChart>
          <br />
        </Col>
        {/* iv column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3 style={h3Style}>Inotropics or Intravenous Diuretics</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthDouble}>
            {makePie(ivPie)}
          </PieChart>
          <br />
        </Col>
      </Row>

      {/* The Patient */}
      <h2 style={{ marginBottom: 10, marginTop: 15, textAlign: "center", fontFamily: "TitleFont" }}>The Patient</h2>
      <Row style={{ textAlign: "center" }}>
        {/* Charlson column */}
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <h3 style={h3Style}>Charlson Score (excluding HF)</h3>
          <PieChart
            height={CONSTANTS.pieChartHeight}
            width={CONSTANTS.bracket === "md" ? CONSTANTS.pieChartWidthSingle : CONSTANTS.pieChartWidthTriple}
          >
            {makePie(charlsonPie)}
          </PieChart>
          <br />
        </Col>
        {/* Age column */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <h3 style={h3Style}>Age</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthTriple}>
            {makePie(agePie)}
          </PieChart>
          <br />
        </Col>
        {/* Sex column */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <h3 style={h3Style}>Sex</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthTriple}>
            {makePie(sexPie)}
          </PieChart>
          <br />
        </Col>
      </Row>

      {/* Hospital Visit */}
      <h2 style={{ marginBottom: 10, marginTop: 20, textAlign: "center", fontFamily: "TitleFont" }}>
        The Hospital Visit
      </h2>
      <Row style={{ textAlign: "center" }}>
        {/* surgical column */}
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <h3 style={h3Style}>Service Type</h3>
          <PieChart
            height={CONSTANTS.pieChartHeight}
            width={CONSTANTS.bracket === "md" ? CONSTANTS.pieChartWidthSingle : CONSTANTS.pieChartWidthTriple}
          >
            {makePie(surgicalPie)}
          </PieChart>
          <br />
        </Col>
        {/* stay duration column */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <h3 style={h3Style}>Stay Duration</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthTriple}>
            {makePie(stayPie)}
          </PieChart>
          <br />
        </Col>
        {/* death column */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <h3 style={h3Style}>In-hospital Death</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidthTriple}>
            {makePie(deathPie)}
          </PieChart>
          <br />
          <br />
        </Col>
      </Row>

      <h2 style={{ marginBottom: 30, textAlign: "center", fontFamily: "TitleFont" }}>Algorithm Results</h2>

      {/* Bar Chart */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <ResponsiveContainer width="95%" height={props.height / 2}>
            <BarChart data={barChartData} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[0, 100]} unit="%" />
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              {makeBars()}
            </BarChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={24} sm={24} md={16} lg={6} xl={6}>
          <HeartFailureAlgorithmFilter
            algorithmsChecked={algorithmsChecked}
            setAlgorithmsChecked={setAlgorithmsChecked}
            bracket={getWindowBracket(props.width)}
          />
          <br />
        </Col>
      </Row>

      <div style={getNarrowTextDivStyle(props.width)}>
        <h2>Why Does This Matter?</h2>
        {heartFailureMatters}
      </div>
    </div>
  );
};
export default HeartFailureResults;
