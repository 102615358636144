const wordZones = (zones) => {
  let output = "";
  for (let i = 0; i < zones.length; i++) {
    output += zones[i].charAt(0) + zones[i].substring(1, zones[i].length);
    if (i < zones.length - 1) {
      if (i === zones.length - 2) {
        output += " and ";
      } else {
        output += ", ";
      }
    }
  }
  output += " zone";
  if (zones.length > 1) {
    output += "s";
  }
  return output;
};

export const getDetailText = (dataset, i) => {
  switch (i) {
    case 0:
      return "Capture Focus: " + dataset.focus;
    case 1:
      return (
        "Availability: from " +
        (dataset.minMonth ? dataset.minMonth + ", " : "") +
        dataset.minYear +
        (dataset.maxYear === "current" ? "" : " to " + dataset.maxYear)
      );
    case 2:
      return "Updated: " + dataset.refresh + (dataset.lag ? " (" + dataset.lag.toLowerCase() + " delay)" : "");
    case 3:
      return "Data Custodian: " + dataset.custodian;
    case 4:
      if (dataset.short === "CPCSSN") {
        return "Coverage: various community-based practices across Alberta (37 sites in Southern Alberta)";
      }
      return "Coverage: " + (dataset.zone.length === 5 ? "all of Alberta" : wordZones(dataset.zone));
    default:
      return;
  }
};

export const getDetailImage = (dataset, i) => {
  const imageFolder = process.env.PUBLIC_URL + "/data_assets_images/";
  switch (i) {
    case 0:
      return imageFolder + dataset.image;
    case 1:
      return imageFolder + "calendar.png";
    case 2:
      return imageFolder + "refresh.png";
    case 3:
      return imageFolder + "custodian.png";
    case 4:
      return null;
    default:
      return;
  }
};
