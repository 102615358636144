import React, { useEffect } from "react";
import * as d3 from "d3";
import { treeData, getMethodsConstants } from "./PressureUlcerConstantsHelpers";
import { getLines, getTextRadius, getOffsetY } from "../../Util/d3TextUtil";

// constants
let CONSTANTS;
const treeClass = "treeVis";
const duration = 1000;

// indicators on how to draw
let selectedMain = -1;
let selectedChild = -1;

const PressureUlcerMethodsTree = (props) => {
  // variables
  let svg, mainGs, childrenGs, handlingClick, childrenTextR;

  useEffect(() => {
    CONSTANTS = getMethodsConstants(props.width, props.height);
    getDatasetTextR();
    drawTree();
  }, []);

  useEffect(() => {
    CONSTANTS = getMethodsConstants(props.width, props.height);
    getDatasetTextR();
    drawTree();
  }, [props.width, props.height]);

  const getDatasetTextR = () => {
    childrenTextR = 0;
    for (const main of treeData) {
      for (const child of main.children) {
        const lines = getLines(child.name, CONSTANTS.childrenLineHeight);
        const textR = getTextRadius(lines, CONSTANTS.childrenLineHeight, "circle");
        if (textR > childrenTextR) {
          childrenTextR = textR;
        }
      }
    }
  };

  // Sleep, used to wait between transitions
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Function to create links
  const link = d3
    .linkHorizontal()
    .x((d) => d.x)
    .y((d) => d.y);

  /**
   * Gets the y coordinate for a main G
   * @param {*} i - index to get coordinate for
   * @returns - y coordinate
   */
  const getMainY = (i) => {
    return (props.height / 2) * i + CONSTANTS.vPadding * (-i + 1);
  };

  /**
   * Gets the translate function for mainG location
   * @param {*} i - index to get location for
   * @returns - translate function
   */
  const getMainGLocation = (i) => {
    return "translate(" + CONSTANTS.hPadding + "," + getMainY(i) + ")";
  };

  /**
   * Gets the y coordinate for a child G
   * @param {*} i - index to get coordinate for
   * @returns - y coordinate
   */
  const getChildrenY = (i) => {
    return (props.height / 2) * i + CONSTANTS.vPadding * (-i + 1);
  };

  /**
   * Gets the translate function for childrenG location
   * @param {*} i - index to get location for
   * @returns - translate function
   */
  const getChildrenGLocation = (i) => {
    return "translate(" + (props.width - CONSTANTS.hPadding) + "," + getChildrenY(i) + ")";
  };

  /**
   * Removes children from the previously selected mainG
   * @param {*} i - index of the previously selected mainG
   */
  const removeChildren = async (i) => {
    // g
    svg
      .selectAll("g.childrenG")
      .data(treeData[i].children)
      .transition()
      .duration(duration)
      .attr("transform", getMainGLocation(i));

    // circle
    svg
      .selectAll("circle.childrenCircle")
      .data(treeData[i].children)
      .transition()
      .duration(duration)
      .attr("fill-opacity", 0)
      .attr("r", 0);

    // text
    for (let j = 0; j < childrenGs._groups[0].length; j++) {
      const lines = getLines(treeData[i].children[j].name, CONSTANTS.childrenLineHeight);

      svg
        .select("g.childrenG:nth-child(" + (j + 1 + treeData.length) + ")")
        .selectAll("text.childrenText")
        .data(lines)
        .transition()
        .duration(duration)
        .attr("font-size", 0)
        .style("fill-opacity", 0);
    }

    // link
    svg
      .selectAll("path.link")
      .data(treeData[i].children)
      .transition()
      .duration(duration)
      .attr("d", link({ source: { x: CONSTANTS.mainRadius, y: 0 }, target: { x: CONSTANTS.mainRadius, y: 0 } }))
      .attr("opacity", 0);

    await sleep(duration);
    svg.selectAll("g.childrenG").remove();
  };

  const handleChildClick = (i, j) => {
    if (!handlingClick) {
      handlingClick = true;
      if (selectedChild === j) {
        return;
      }

      selectedChild = j;
      props.setSelectedChild(j);
    }
    handlingClick = false;
  };

  /**
   * Creates children circles and text at the parent, invisible
   * @param {*} i - index of parent
   */
  const createChildrenAtMain = (i) => {
    // g
    childrenGs = svg
      .selectAll("g.childrenG")
      .data(treeData[i].children)
      .enter()
      .append("g")
      .attr("class", "childrenG")
      .attr("transform", getMainGLocation(i))
      .style("cursor", "pointer")
      .on("click", (_, j) => handleChildClick(i, j));

    // circle
    childrenGs
      .data(treeData[i].children)
      .append("circle")
      .attr("class", "childrenCircle")
      .attr("r", 0)
      .attr("fill", treeData[i].color)
      .attr("fill-opacity", 0);

    // text
    for (let j = 0; j < childrenGs._groups[0].length; j++) {
      const lines = getLines(treeData[i].children[j].name, CONSTANTS.childrenLineHeight);

      svg
        .select("g.childrenG:nth-child(" + (j + 1 + treeData.length) + ")")
        .selectAll("text.childrenText")
        .data(lines)
        .enter()
        .append("text")
        .text((d) => d.text)
        .attr("class", "childrenText")
        .attr("font-size", 0)
        .attr("transform", "scale(" + (CONSTANTS.childrenRadius * 0.9) / childrenTextR + ")")
        .attr("y", (_, k) => getOffsetY(k, CONSTANTS.childrenLineHeight, "circle", lines.length))
        .attr("text-anchor", "middle")
        .style("fill-opacity", 0)
        .attr("fill", CONSTANTS.textColor);
    }

    // link
    childrenGs
      .data(treeData[i].children)
      .append("path")
      .attr("d", link({ source: { x: CONSTANTS.mainRadius, y: 0 }, target: { x: CONSTANTS.mainRadius, y: 0 } }))
      .attr("class", "link")
      .attr("opacity", 0)
      .attr("fill", "none")
      .attr("stroke", CONSTANTS.linkColor)
      .attr("stroke-width", CONSTANTS.linkWidth);
  };

  /**
   * Transitions children from main circle to their spots and to visible
   * @param {*} i - index of main G that was clicked
   */
  const transitionChildrenIn = (i, transitionDuration = duration) => {
    // g
    svg
      .selectAll("g.childrenG")
      .data(treeData[i].children)
      .transition()
      .duration(transitionDuration)
      .attr("transform", (_, j) => getChildrenGLocation(j));

    // circle
    svg
      .selectAll("circle.childrenCircle")
      .data(treeData[i].children)
      .transition()
      .duration(transitionDuration)
      .attr("fill-opacity", 1)
      .attr("r", CONSTANTS.childrenRadius);

    // transitioning text
    for (let j = 0; j < childrenGs._groups[0].length; j++) {
      const lines = getLines(treeData[i].children[j].name, CONSTANTS.childrenLineHeight);

      svg
        .select("g.childrenG:nth-child(" + (j + 1 + treeData.length) + ")")
        .selectAll("text.childrenText")
        .data(lines)
        .transition()
        .duration(transitionDuration)
        .attr("font-size", CONSTANTS.childrenFontSize)
        .style("fill-opacity", 1);
    }

    // link
    svg
      .selectAll("path.link")
      .data(treeData[i].children)
      .transition()
      .duration(transitionDuration)
      .attr("d", (_, j) =>
        link({
          source: {
            x: -props.width + 2 * CONSTANTS.hPadding + CONSTANTS.mainRadius,
            y: -getChildrenY(j) + getMainY(i),
          },
          target: { x: -CONSTANTS.childrenRadius, y: 0 },
        })
      )
      .attr("opacity", 1);
  };

  /**
   * Handles clicking on a main G, transitioning children
   * @param {*} i - index of main G that was clicked
   */
  const handleMainGClick = async (i) => {
    if (!handlingClick) {
      handlingClick = true;
      if (selectedMain === i) {
        return;
      }
      if (selectedMain >= 0) {
        removeChildren(selectedMain);
        await sleep(duration);
      }

      createChildrenAtMain(i);
      transitionChildrenIn(i);

      selectedMain = i;
      selectedChild = -1;
      props.setSelectedMain(i);
      props.setSelectedChild(-1);
    }
    handlingClick = false;
  };

  const addMainCircles = () => {
    mainGs = svg
      .selectAll("g.mainG")
      .data(treeData)
      .enter()
      .append("g")
      .attr("class", "mainG")
      .attr("transform", (_, i) => getMainGLocation(i))
      .style("cursor", "pointer")
      .on("click", (_, i) => handleMainGClick(i));

    mainGs
      .data(treeData)
      .append("circle")
      .attr("class", "mainCircle")
      .attr("r", CONSTANTS.mainRadius)
      .attr("fill", (d) => d.color);

    mainGs
      .data(treeData)
      .append("text")
      .attr("class", "mainText")
      .text((d) => d.name)
      .attr("font-size", CONSTANTS.mainFontSize)
      .attr("y", CONSTANTS.mainFontSize / 4)
      .attr("fill", CONSTANTS.textColor)
      .attr("text-anchor", "middle");
  };

  // draw datasets upon prop change
  // needs to look at current state of diagram to match
  const drawTree = () => {
    d3.select("div." + treeClass)
      .select("svg")
      .remove();

    svg = d3
      .select("div." + treeClass)
      .append("svg")
      .attr("width", props.width)
      .attr("height", props.height + 10)
      .style("background-color", "white");

    addMainCircles();

    // draw based on current indicators
    if (selectedMain >= 0) {
      createChildrenAtMain(selectedMain);
      transitionChildrenIn(selectedMain, 0);
    }
  };

  return <div className={treeClass} style={{ backgroundColor: "white" }} />;
};

export default PressureUlcerMethodsTree;
