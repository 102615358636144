import * as actionTypes from "./actionTypes";

export const setDatasets = (value) => {
  return {
    type: actionTypes.SET_DATASETS,
    value,
  };
};

export const setSelectedDataset = (value) => {
  return {
    type: actionTypes.SET_SELECTED_DATASET,
    value,
  };
};

export const setChecked = (value) => {
  return {
    type: actionTypes.SET_CHECKED,
    value,
  };
};
