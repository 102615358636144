export const allItems =
  process.env.REACT_APP_VERSION === "dev"
    ? [
        {
          name: "Official Site",
          href: "https://cumming.ucalgary.ca/centres/centre-health-informatics",
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "COVID-19 Tracker",
          href: "https://covid-tracker.chi-csm.ca/",
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "Data Assets",
          href: "/ab-data-assets",
          target: "",
          rel: "",
        },
        {
          name: "Heart Failure",
          href: "/heart-failure",
          target: "",
          rel: "",
        },
        {
          name: "Hypertension",
          href: "/hypertension",
          target: "",
          rel: "",
        },
        {
          name: "Pressure Injury",
          href: "/pressure-injury",
          target: "",
          rel: "",
        },
        {
          name: "Performance Metrics",
          href: "/performance-metrics",
          target: "",
          rel: "",
        },
      ]
    : [
        {
          name: "Official Site",
          href: "https://cumming.ucalgary.ca/centres/centre-health-informatics",
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "COVID-19 Tracker",
          href: "https://covid-tracker.chi-csm.ca/",
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "Data Assets",
          href: "/ab-data-assets",
          target: "",
          rel: "",
        },
        {
          name: "Heart Failure",
          href: "/heart-failure",
          target: "",
          rel: "",
        },
        {
          name: "Performance Metrics",
          href: "/performance-metrics",
          target: "",
          rel: "",
        },
      ];

export const resources = [
  {
    name: "Official Site",
    href: "https://cumming.ucalgary.ca/centres/centre-health-informatics",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "COVID-19 Tracker",
    href: "https://covid-tracker.chi-csm.ca/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "Performance Metrics",
    href: "/performance-metrics",
    target: "",
    rel: "",
  },
];

export const visualizations =
  process.env.REACT_APP_VERSION === "dev"
    ? [
        {
          name: "Data Assets",
          href: "/ab-data-assets",
          target: "",
          rel: "",
        },
        {
          name: "Heart Failure",
          href: "/heart-failure",
          target: "",
          rel: "",
        },
        {
          name: "Hypertension",
          href: "/hypertension",
          target: "",
          rel: "",
        },
        {
          name: "Pressure Injury",
          href: "/pressure-injury",
          target: "",
          rel: "",
        },
      ]
    : [
        {
          name: "Data Assets",
          href: "/ab-data-assets",
          target: "",
          rel: "",
        },
        {
          name: "Heart Failure",
          href: "/heart-failure",
          target: "",
          rel: "",
        },
        {
          name: "Hypertension",
          href: "/hypertension",
          target: "",
          rel: "",
        },
      ];
