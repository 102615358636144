import { getWindowBracket } from "../../Util/layout";

export const getResultsConstants = (width, height) => {
  const bracket = getWindowBracket(width);

  const viewWidth = 0.95;
  const outerRadius =
    bracket === "xs" || bracket === "sm"
      ? Math.min((width * viewWidth) / 6, height / 8)
      : bracket === "md"
      ? Math.min((width * viewWidth) / 12, height / 3)
      : (width * viewWidth) / 18;
  const cy = outerRadius * 1.1 + 30;
  return {
    bracket: bracket,
    viewWidth: viewWidth,
    outerRadius: outerRadius,
    cy: cy,
    cx: bracket === "xs" || bracket === "sm" ? "43%" : bracket === "md" ? "45%" : bracket === "lg" ? "46.5%" : "48%",
    pieChartWidthSingle: width * viewWidth,
    pieChartWidthDouble: (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : 2),
    pieChartWidthTriple: (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : bracket === "md" ? 2 : 3),
    pieChartHeight: cy * 2.2,
    barChartFilterLeftPadding: bracket === "xs" || bracket === "sm" || bracket === "md" ? 100 : 0,
  };
};

// Pie chart data
export const hfPie = [
  { name: "Absent", value: 1810, color: "#b6bd36" },
  { name: "Present", value: 296, color: "#e5ee44" },
];
export const bnpPie = [
  { name: "Normal", value: 1775, color: "#2d4b7a" },
  { name: "Abnormal", value: 331, color: "#617eac" },
];
export const ivPie = [
  { name: "Not administered", value: 1886, color: "#82b2b8" },
  { name: "Administered", value: 220, color: "#b1d3d8" },
];
export const charlsonPie = [
  { name: "0", value: 535, color: "#af2626" },
  { name: "1", value: 435, color: "#df5656" },
  { name: "Greater than 1", value: 1136, color: "#662400" },
];
export const agePie = [
  { name: "Less than or equal to 64", value: 1083, color: "#f57b00" },
  { name: "Greater than 64", value: 1023, color: "#ffb061" },
];
export const sexPie = [
  { name: "Female", value: 1057, color: "#efbd00" },
  { name: "Male", value: 1049, color: "#ffed50" },
];
export const surgicalPie = [
  { name: "Non-Surgical", value: 1580, color: "#433e3a" },
  { name: "Surgical", value: 526, color: "#716761" },
];
export const stayPie = [
  { name: "Less than 4 days", value: 771, color: "#464652" },
  { name: "4-7 days", value: 716, color: "#606070" },
  { name: "8-11 days", value: 246, color: "#9090a8" },
  { name: "Greater than 11 days", value: 385, color: "#dadaff" },
];
export const deathPie = [
  { name: "No", value: 2058, color: "#5e5f5e" },
  { name: "Yes", value: 48, color: "#cacccb" },
];

export const barChartData = [
  {
    name: "Sensitivity",
    "ICD-10": 57.4,
    Keyword: 65.6,
    "ICD-10 & Keyword": 77.0,
    "Machine Learning": 80.0,
    "ICD-10 & Machine Learning": 83.3,
    "Structured Data": 78.0,
    "ICD-10 & Structured Data": 82.4,
    "ICD-10, Keyword & Structured Data": 88.2,
  },
  {
    name: "Specificity",
    "ICD-10": 99.2,
    Keyword: 96.9,
    "ICD-10 & Keyword": 98.4,
    "Machine Learning": 97.5,
    "ICD-10 & Machine Learning": 97.3,
    "Structured Data": 89.2,
    "ICD-10 & Structured Data": 88.9,
    "ICD-10, Keyword & Structured Data": 87.5,
  },
  {
    name: "PPV",
    "ICD-10": 92.4,
    Keyword: 77.6,
    "ICD-10 & Keyword": 78.4,
    "Machine Learning": 88.9,
    "ICD-10 & Machine Learning": 83.3,
    "Structured Data": 54.2,
    "ICD-10 & Structured Data": 54.8,
    "ICD-10, Keyword & Structured Data": 53.5,
  },
  {
    name: "NPV",
    "ICD-10": 93.4,
    Keyword: 94.5,
    "ICD-10 & Keyword": 96.3,
    "Machine Learning": 96.8,
    "ICD-10 & Machine Learning": 97.3,
    "Structured Data": 96.1,
    "ICD-10 & Structured Data": 96.9,
    "ICD-10, Keyword & Structured Data": 97.8,
  },
  {
    name: "F1 Score",
    "ICD-10": 70.8,
    Keyword: 71.0,
    "ICD-10 & Keyword": 77.7,
    "Machine Learning": 84.2,
    "ICD-10 & Machine Learning": 83.3,
    "Structured Data": 64.0,
    "ICD-10 & Structured Data": 65.8,
    "ICD-10, Keyword & Structured Data": 66.6,
  },
];

export const barChartColors = {
  "ICD-10": "#42b861",
  Keyword: "#3c4cb5",
  "ICD-10 & Keyword": "#5cd1d1",
  "Machine Learning": "#d13f3f",
  "ICD-10 & Machine Learning": "#dbcf5c",
  "Structured Data": "#c742b5",
  "ICD-10 & Structured Data": "#eba434",
  "ICD-10, Keyword & Structured Data": "#000000",
};
