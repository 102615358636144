import React, { useState, useEffect } from "react";
import { Row, Col, Layout } from "antd";
import MenuBar from "../../Components/MenuBar/MenuBar.js";
import { throttle } from "lodash";
import { getWindowBracket } from "../../Util/layout";
import { getNarrowTextDivStyle } from "../../Util/util";
import {
  whatArePerformanceMetrics,
  confusionMatrixText,
  sensitivityDescription,
  specificityDescription,
  ppvDescription,
  npvDescription,
  fscoreDescription,
  aucrocDescription,
} from "../../Util/writing";

const { Content } = Layout;

const headerStyle = {
  marginBottom: 20,
  textAlign: "center",
  marginTop: 40,
  fontSize: "1.5em",
  fontFamily: "TitleFont",
};

const MetricsPage = () => {
  const [contentWidth, setContentWidth] = useState(window.innerWidth);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 60);
  const sizeBracket = getWindowBracket(contentWidth);

  const resizeListener = () => {
    setContentWidth(document.getElementById("content").getBoundingClientRect().width);
    setContentHeight(window.innerHeight - 60);
  };
  const throttledResizeListener = throttle(resizeListener, 200);

  // componentDidMount and willUnmount
  useEffect(() => {
    window.addEventListener("resize", throttledResizeListener);
    return () => window.removeEventListener("resize", throttledResizeListener);
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <MenuBar bracket={sizeBracket} width={contentWidth} />
        <Content id="content">
          <div
            style={{
              margin: "auto",
              marginTop: 20,
              width: "90%",
              padding: 20,
              paddingBottom: 0,
              backgroundColor: "white",
              height: contentHeight - 50,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div style={getNarrowTextDivStyle(contentWidth)}>{whatArePerformanceMetrics}</div>

            {/* Confusion Matrix */}
            <Row>
              <Col xs={24} md={12}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/confusionmatrix.png"}
                  alt="confusion matrix"
                  width={contentWidth < 900 ? "90%" : 400}
                  style={{ float: contentWidth < 900 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {confusionMatrixText(contentWidth < 768 ? 0 : 60)}
              </Col>
            </Row>

            {/* Sensitivity */}
            <h2 style={headerStyle}>Sensitivity</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/sensitivity.png"}
                  alt="sensitivity"
                  height={contentWidth < 768 ? 50 : contentWidth > 1200 ? 70 : contentWidth / 15}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {sensitivityDescription(30)}
              </Col>
            </Row>

            {/* Specificity */}
            <h2 style={headerStyle}>Specificity</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/specificity.png"}
                  alt="specificity"
                  height={contentWidth < 768 ? 50 : contentWidth > 1200 ? 70 : contentWidth / 15}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {specificityDescription(30)}
              </Col>
            </Row>

            {/* Positive Predictive Value */}
            <h2 style={headerStyle}>Positive Predictive Value</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/PPV.png"}
                  alt="ppv"
                  height={contentWidth < 768 ? 50 : contentWidth > 1200 ? 70 : contentWidth / 15}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {ppvDescription(30)}
              </Col>
            </Row>

            {/* Negative Predictive Value */}
            <h2 style={headerStyle}>Negative Predictive Value</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/NPV.png"}
                  alt="npv"
                  height={contentWidth < 768 ? 50 : contentWidth > 1200 ? 70 : contentWidth / 15}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {npvDescription(30)}
              </Col>
            </Row>

            {/* F1 */}
            <h2 style={headerStyle}>F-Score</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/fscore.png"}
                  alt="fscore"
                  height={contentWidth < 768 ? 50 : contentWidth > 1200 ? 70 : contentWidth / 15}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {fscoreDescription(30)}
              </Col>
            </Row>

            {/* AUC-ROC */}
            <h2 style={headerStyle}>Area Under the Curve - Receiver Operator Characteristic</h2>
            <Row>
              <Col xs={24} md={12} style={{ textAlign: "center" }}>
                <img
                  src={process.env.PUBLIC_URL + "/metrics/auc.png"}
                  alt="auc"
                  height={250}
                  style={{ float: contentWidth < 768 ? "" : "right" }}
                />
              </Col>
              <Col xs={24} md={12}>
                {aucrocDescription(30)}
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};
export default MetricsPage;
