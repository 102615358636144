import * as d3 from "d3";

const measureWidth = (text) => {
  const context = document.createElement("canvas").getContext("2d");
  return context.measureText(text).width;
};

const getTargetWidth = (text, lineHeight, ratio = 1) => {
  return Math.sqrt(measureWidth(text.trim()) * lineHeight * ratio);
};

export const getTextRadius = (lines, lineHeight, shape = "rect") => {
  let radius = 0;
  for (let i = 0; i < lines.length; ++i) {
    const dy = (Math.abs(i - lines.length / 2 + 0.5) + 0.5) * lineHeight;
    const dx = lines[i].width / (shape === "circle" ? 2 : 1);
    radius = Math.max(radius, Math.sqrt(dx ** 2 + dy ** 2));
  }
  return radius;
};

export const getLines = (text, lineHeight, ratio = 1) => {
  const words = text.split(/\s+/g); // to hyphenate: /\s+|(?<=-)/
  const targetWidth = getTargetWidth(text, lineHeight, ratio);

  let line;
  let lineWidth0 = Infinity;
  const lines = [];
  for (let i = 0; i < words.length; ++i) {
    let lineText1 = (line ? line.text + " " : "") + words[i];
    let lineWidth1 = measureWidth(lineText1);
    if ((lineWidth0 + lineWidth1) / 2 < targetWidth) {
      line.width = lineWidth0 = lineWidth1;
      line.text = lineText1;
    } else {
      lineWidth0 = measureWidth(words[i]);
      line = { width: lineWidth0, text: words[i] };
      lines.push(line);
    }
  }
  return lines;
};

export const getOffsetY = (index, lineHeight, shape = "rect", length = 0) => {
  if (shape === "circle") {
    const mid = length / 2 + 0.5;
    return -(mid - (index + 1)) * lineHeight + 3.6;
  }
  return (index + 1) * lineHeight;
};

export const wrapText = (text, width) => {
  text.each(function () {
    let text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      x = text.attr("x"),
      y = text.attr("y"),
      dy = "1.1em",
      isLink = false,
      link,
      linkText,
      linkIndex,
      tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy);
    while ((word = words.pop())) {
      // looking for a link
      if (word.startsWith("<a")) {
        const startIndex = word.indexOf(">") + 1;
        const endIndex = word.indexOf("<", startIndex);
        link = word.substring(2, startIndex - 1);
        linkText = word.substring(startIndex, endIndex);
        word = word.substring(startIndex).replace("</a>", "");
        isLink = true;
        linkIndex = line.length;
      }

      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width || word.startsWith("/n")) {
        if (word.startsWith("/n")) {
          word = word.substring(2);
        }
        // removing overflowing word and setting text
        line.pop();
        if (isLink) {
          const linkToOpen = link;
          if (linkIndex === line.length) {
            // make span as per normal, link is on next line
            tspan.text(line.join(" "));
            linkIndex = 0;
          } else {
            // make spans for the link
            tspan.text(line.slice(0, linkIndex).join(" ") + (linkIndex === 0 ? "‎" : " ")); // there is invisible character in quotes
            tspan = text
              .append("a")
              .attr("href", linkToOpen)
              .attr("target", "_blank")
              .attr("rel", "noopeneer noreferrer")
              .append("tspan")
              .text(linkText)
              .style("fill", "#11abf2")
              .attr("text-decoration", "underline");
            tspan = text
              .append("tspan")
              .text(line[linkIndex].replace(linkText, "") + " " + line.slice(linkIndex + 1).join(" "));
            isLink = false;
          }
        } else {
          tspan.text(line.join(" "));
        }

        // creating new line
        line = [word];
        tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", dy).text(word);
      }
    }
    // after loop, in case there is a link on the last line
    if (isLink) {
      const linkToOpen = link;
      tspan.text(line.slice(0, linkIndex).join(" ") + (linkIndex === 0 ? "‎" : " ")); // there is invisible character in quotes
      tspan = text
        .append("a")
        .attr("href", linkToOpen)
        .attr("target", "_blank")
        .attr("rel", "noopeneer noreferrer")
        .append("tspan")
        .text(linkText)
        .style("fill", "#11abf2")
        .attr("text-decoration", "underline");
      tspan = text
        .append("tspan")
        .text(line[linkIndex].replace(linkText, "") + " " + line.slice(linkIndex + 1).join(" "));
      isLink = false;
    }
  });
};

export const getDataLines = (text, detailFontSize, rectWidth, svg) => {
  const lines = [];
  let line = "";
  let testLine = "";
  const textArray = text.split(" ").reverse();

  while (textArray.length > 0) {
    const word = textArray.pop();
    testLine += word + " ";
    svg
      .append("text")
      .attr("class", "testText")
      .attr("font-size", detailFontSize + 1)
      .text(testLine)
      .attr("x", -99999)
      .attr("y", -99999);

    const values = svg.selectAll("text.testText").node().getBBox();
    svg.selectAll("text.testText").remove();
    if (values.width > rectWidth - 10) {
      lines.push(line);
      line = word;
      testLine = word + " ";
    } else {
      line = testLine;
    }
  }
  lines.push(line);

  return lines;
};
