export const wordToNumberLag = {
  No: 0,
  "1 Day": 0.002739726, // 1 / 365
  "1 Week": 0.0191780822, // 7 / 365
  "1 Month": 0.083333333333, // 1 / 12
  "3 Month": 0.25,
  "4 Month": 0.333333,
  "6 Month": 0.5,
  "7 Month": 0.583333, // 7 / 12
  "1 Year": 1,
  "At least 1 Year": 1,
};

export const numberToWordLag = {
  0: "No",
  0.00273972: "1 Day",
  0.0191780822: "1 Week",
  0.083333333333: "1 Month",
  0.25: "3 Month",
  0.333333: "4 Month",
  0.5: "6 Month",
  0.583333: "7 Month",
  1: "1 Year",
};

export const shortToLong = {
  DAD: "Discharge Abstract Database",
  NACRS: "National Ambulatory Care Reporting System",
  "Physician Claims": "Physician Claims",
  PIN: "Pharmaceutical Information Network",
  LAB: "Provincial Lab Data",
  "VS - DR": "Vital Statistics - Death Registry",
  "VS - BR": "Vital Statistics - Birth Registry",
  REG: "Provincial Registry",
  APPROACH: "Alberta Provincial Project for Outcome Assessment in Coronary Heart Disease",
  CPCSSN: "Canadian Primary Care Sentinel Surveillance Network",
  SCM: "Sunrise Clinical Manager",
  ConnectCare: "ConnectCare",
};

/**
 * rounds to the specified number of decimales
 */
export const roundNumber = (number, decimals) => {
  if (number) {
    return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
  return 0;
};

export const getNarrowTextDivStyle = (width) => {
  return {
    padding: 20,
    paddingLeft: width > 1000 ? "20%" : width > 800 ? "15%" : "5%",
    paddingRight: width > 1000 ? "20%" : width > 800 ? "15%" : "5%",
    backgroundColor: "white",
    overflowY: "auto",
  };
};
