import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell } from "recharts";
import { shortToLong } from "../../Util/util";

const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={10} textAnchor="middle">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

const RecordComparison = (props) => {
  const [useLogScale, setUseLogScale] = useState(false);
  const parseData = () => {
    const newData = [];

    for (const dataset of props.datasets) {
      if (dataset.checked) {
        if (dataset.title !== "ConnectCare") {
          newData.push({
            short: dataset.short ? dataset.short : dataset.title,
            records: dataset.recordsPerYear,
            category: dataset.category,
          });
        }
      }
    }

    return newData;
  };

  const getCategoryColor = (entry) => {
    for (const cat of props.categories) {
      if (cat.name === entry.category) {
        return cat.color;
      }
    }
    return "black";
  };

  const data = useMemo(() => parseData(), [props.datasets, props.bracket, useLogScale]);

  if (!data || data.length <= 0) {
    return (
      <div style={{ height: props.height }}>
        <p style={{ textAlign: "center", fontSize: 18 }}>Add datasets to compare.</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div style={{ marginLeft: 115 }}>
        Log Scale <Switch checked={useLogScale} onChange={(checked) => setUseLogScale(checked)} size="small" />
      </div>
      <ResponsiveContainer width="95%" height={props.height}>
        <BarChart data={data} margin={{ top: 20, right: 40, left: 15, bottom: 20 }} layout="vertical">
          <XAxis
            type="number"
            label={{
              value: "Records",
              position: "bottom",
              fontSize: 14,
            }}
            tick={<CustomizedTick />}
            scale={useLogScale ? "log" : "auto"}
            domain={[useLogScale ? 1 : 0, "auto"]}
          />
          <YAxis type="category" dataKey="short" width={100} tick={{ fontWeight: "bold", fontSize: 14 }} />

          <Tooltip
            labelFormatter={(d) => {
              return shortToLong[d];
            }}
            formatter={(value, _, d) => {
              if (d.payload.short === "ConnectCare") {
                return ["Implementation ongoing", null];
              }
              return [value.toLocaleString(), "Average Records per Year"];
            }}
            itemStyle={{ marginBottom: 0 }}
          />

          <CartesianGrid stroke="#f5f5f5" />

          <Bar dataKey="records">
            {data.map((entry, index) => (
              <Cell key={index} fill={getCategoryColor(entry)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    datasets: state.data.datasets,
    categories: state.data.categories,
  };
};

export default connect(mapStateToProps, null)(RecordComparison);
