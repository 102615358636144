import React, { useState, useEffect } from "react";
import "./util.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const InfoHover = (props) => {
  const [pulsing, setPulsing] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPulsing(false);
    }, 5000);
  }, []);

  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
      }}
    >
      <Popover content={props.content} placement="rightTop">
        <InfoCircleOutlined
          style={{ left: 5, marginTop: 5, marginLeft: 5, fontSize: 22, color: "black", zIndex: 5 }}
          className={pulsing ? "pulsing" : ""}
        />
      </Popover>
    </div>
  );
};

export default InfoHover;
