import React, { useState, useEffect, useRef } from "react";
import { Layout, Button, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined } from "@ant-design/icons";
import { allItems, resources, visualizations } from "./Links";

const { Header } = Layout;

const resButtonWidth = 170;
const resButtonRight = 10;
const visButtonWidth = 140;
const visButtonRight = resButtonWidth + resButtonRight;

const MenuBar = (props) => {
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [mouseOut, setMouseOut] = useState(true);
  const mouseOutRef = useRef(mouseOut); // ref because setTimeout only looks at value before it starts the timer

  const [menuCollapsedVis, setMenuCollapsedVis] = useState(true);
  const [mouseOutVis, setMouseOutVis] = useState(true);
  const [mouseOutVisButton, setMouseOutVisButton] = useState(true);
  const mouseOutRefVis = useRef(mouseOutVis); // ref because setTimeout only looks at value before it starts the timer

  const [menuCollapsedRes, setMenuCollapsedRes] = useState(true);
  const [mouseOutRes, setMouseOutRes] = useState(true);
  const [mouseOutResButton, setMouseOutResButton] = useState(true);
  const mouseOutRefRes = useRef(mouseOutRes); // ref because setTimeout only looks at value before it starts the timer

  const [linkHovered, setLinkHovered] = useState(null);

  // handles mouse moving in and out of menu, but allows a small grace period
  useEffect(() => {
    mouseOutRef.current = mouseOut;
    setTimeout(() => {
      if (mouseOutRef.current) {
        setMenuCollapsed(true);
      } else {
        setMenuCollapsed(false);
      }
    }, 100);
  }, [mouseOut]);

  // handles mouse moving in and out of visualization menu, but allows a small grace period
  useEffect(() => {
    mouseOutRefVis.current = mouseOutVis;
    setTimeout(() => {
      if (mouseOutRefVis.current) {
        setMenuCollapsedVis(true);
      } else {
        setMenuCollapsedVis(false);
      }
    }, 100);
  }, [mouseOutVis]);

  // handles mouse moving in and out of resource menu, but allows a small grace period
  useEffect(() => {
    mouseOutRefRes.current = mouseOutRes;
    setTimeout(() => {
      if (mouseOutRefRes.current) {
        setMenuCollapsedRes(true);
      } else {
        setMenuCollapsedRes(false);
      }
    }, 100);
  }, [mouseOutRes]);

  return (
    <React.Fragment>
      <Header
        style={{
          height: 60,
          backgroundColor: "white",
          padding: 0,
          margin: 0,
        }}
      >
        {/* U of C logo */}
        {props.width > 588 && (
          <img
            src={process.env.PUBLIC_URL + "/uofc.png"}
            alt="Univeristy of Calgary"
            height={40}
            style={{ marginTop: -10, marginLeft: 10 }}
          />
        )}

        {/* Full width div with CHI logo and text centered */}
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            textAlign: props.width < 420 ? "left" : "center",
          }}
        >
          <a
            href="https://cumming.ucalgary.ca/centres/centre-health-informatics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.PUBLIC_URL + "/chi.png"}
              alt="Centre for Health Informatics"
              height={55}
              style={{ marginTop: -10 }}
            />
          </a>
          {/* Title */}
          <a
            href="https://cumming.ucalgary.ca/centres/centre-health-informatics"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "TitleFont",
              color: "black",
              width: 250,
              paddingLeft: 10,
              fontSize: props.bracket === "xs" || props.bracket === "sm" ? 14 : 18,
            }}
          >
            Centre for Health Informatics
          </a>
        </div>

        {/*  */}
        {/* Navigation Menu */}
        {props.width < 966 ? (
          // small screen, only single button with all links
          <>
            <Button
              onMouseOver={() => {
                setMenuCollapsed(false);
                setMouseOut(false);
              }}
              onMouseLeave={() => setMouseOut(true)}
              style={{
                top: 14,
                position: "absolute",
                right: 20,
                color: mouseOut ? "" : "#2d4b7b",
                borderColor: mouseOut ? "" : "#2d4b7b",
              }}
            >
              {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            {/* {!false && ( */}
            {!menuCollapsed && (
              <Menu
                style={{
                  right: 20,
                  position: "absolute",
                  marginTop: -18,
                  zIndex: 1,
                  borderRadius: 10,
                  boxShadow:
                    "2px 2px 2px rgb(0,0,0,0.1), -2px 2px 2px rgb(0,0,0,0.1), -2px -2px 2px rgb(0,0,0,0.1), 2px -2px 2px rgb(0,0,0,0.1)",
                }}
                theme="light"
                onMouseLeave={() => {
                  setMouseOut(true);
                  setLinkHovered(null);
                }}
                onMouseEnter={() => setMouseOut(false)}
              >
                {allItems.map((item, i) => (
                  <Menu.Item
                    key={item.name.replace(" ", "-")}
                    onMouseOver={() => setLinkHovered(i)}
                    style={{
                      backgroundColor: i === linkHovered ? "#2d4b7b" : "",
                      margin: 0,
                      borderRadius: i === 0 ? "10px 10px 0px 0px" : i === allItems.length - 1 ? "0px 0px 10px 10px" : 0,
                    }}
                  >
                    <a href={item.href} target={item.target} rel={item.rel}>
                      <span style={{ color: linkHovered === i ? "white" : "#2d4b7b" }}>{item.name}</span>
                    </a>
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </>
        ) : (
          // large screen, 2 sections with split links
          <>
            {/* Visualizations */}
            <Button
              shape="round"
              onMouseOver={() => {
                setMenuCollapsedVis(false);
                setMouseOutVis(false);
                setMouseOutVisButton(false);
              }}
              onMouseLeave={() => {
                setMouseOutVis(true);
                setMouseOutVisButton(true);
              }}
              style={{
                width: visButtonWidth,
                top: 14,
                position: "absolute",
                right: visButtonRight,
                height: 46,
                border: "none",
                backgroundColor: mouseOutVisButton ? "white" : "#2d4b7b",
              }}
            >
              <span style={{ color: mouseOutVisButton ? "#2d4b7b" : "white" }}>Visualizations</span>
              <DownOutlined style={{ color: mouseOutVisButton ? "#2d4b7b" : "white" }} />
            </Button>
            {!menuCollapsedVis && (
              <Menu
                style={{
                  width: visButtonWidth,
                  right: visButtonRight,
                  position: "absolute",
                  marginTop: -4,
                  zIndex: 1,
                  backgroundColor: "white",
                  borderRadius: 30,
                  boxShadow:
                    "2px 2px 2px rgb(0,0,0,0.1), -2px 2px 2px rgb(0,0,0,0.1), -2px -2px 2px rgb(0,0,0,0.1), 2px -2px 2px rgb(0,0,0,0.1)",
                }}
                onMouseLeave={() => {
                  setMouseOutVis(true);
                  setLinkHovered(null);
                }}
                onMouseEnter={() => setMouseOutVis(false)}
              >
                {visualizations.map((item, i) => (
                  <Menu.Item
                    key={item.name.replace(" ", "-")}
                    onMouseOver={() => setLinkHovered(i)}
                    style={{
                      backgroundColor: i === linkHovered ? "#2d4b7b" : "",
                      margin: 0,
                      borderRadius:
                        i === 0 ? "30px 30px 0px 0px" : i === visualizations.length - 1 ? "0px 0px 30px 30px" : 0,
                    }}
                  >
                    <a href={item.href} target={item.target} rel={item.rel}>
                      <span style={{ color: i === linkHovered ? "white" : "#2d4b7b" }}>{item.name}</span>
                    </a>
                  </Menu.Item>
                ))}
              </Menu>
            )}

            {/* Resources */}
            <Button
              shape="round"
              onMouseOver={() => {
                setMenuCollapsedRes(false);
                setMouseOutRes(false);
                setMouseOutResButton(false);
              }}
              onMouseLeave={() => {
                setMouseOutRes(true);
                setMouseOutResButton(true);
              }}
              style={{
                width: resButtonWidth,
                top: 14,
                position: "absolute",
                right: resButtonRight,
                height: 46,
                border: "none",
                backgroundColor: mouseOutResButton ? "white" : "#2d4b7b",
              }}
            >
              <span style={{ color: mouseOutResButton ? "#2d4b7b" : "white" }}>Resources</span>
              <DownOutlined style={{ color: mouseOutResButton ? "#2d4b7b" : "white" }} />
            </Button>
            {!menuCollapsedRes && (
              <Menu
                style={{
                  width: resButtonWidth,
                  right: resButtonRight,
                  position: "absolute",
                  marginTop: -4,
                  zIndex: 1,
                  backgroundColor: "white",
                  borderRadius: 30,
                  boxShadow:
                    "2px 2px 2px rgb(0,0,0,0.1), -2px 2px 2px rgb(0,0,0,0.1), -2px -2px 2px rgb(0,0,0,0.1), 2px -2px 2px rgb(0,0,0,0.1)",
                }}
                onMouseLeave={() => {
                  setMouseOutRes(true);
                  setLinkHovered(null);
                }}
                onMouseEnter={() => setMouseOutRes(false)}
              >
                {resources.map((item, i) => (
                  <Menu.Item
                    key={item.name.replace(" ", "-")}
                    onMouseOver={() => setLinkHovered(i)}
                    style={{
                      backgroundColor: i === linkHovered ? "#2d4b7b" : "",
                      margin: 0,
                      borderRadius:
                        i === 0 ? "30px 30px 0px 0px" : i === resources.length - 1 ? "0px 0px 30px 30px" : 0,
                    }}
                  >
                    <a href={item.href} target={item.target} rel={item.rel}>
                      <span style={{ color: i === linkHovered ? "white" : "#2d4b7b" }}>{item.name}</span>
                    </a>
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </>
        )}
      </Header>
    </React.Fragment>
  );
};

export default MenuBar;
