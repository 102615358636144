import * as actionTypes from "../Actions/actionTypes.js";

// refresh: "None", "Daily", "Weekly", "Monthly", "Quarterly", "Six Months", "Yearly"
// lag: "No", "1 Day", "1 Week", "1 Month", "3 Month", "6 Month", "1 Year"
// Average records per year
//    2016-2019 for AHS big 7.
//    2010-2013 for APPROACH
//
const initialState = {
  categories: [
    { name: "Admin", long: "Administrative Data", color: "#b6bd36" },
    { name: "Clinical", long: "Clinical Data", color: "#82b2b8" },
    { name: "EMR", long: "Electronic Medical Records", color: "#ff9324" },
  ],
  datasets: [
    {
      title: "Discharge Abstract Database",
      short: "DAD",
      minYear: 2002,
      maxYear: "current",
      minMonth: "April",
      maxMonth: null,
      category: "Admin",
      level: "National",
      lag: "1 Month",
      refresh: "Monthly",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "dad.png",
      recordsPerYear: 415462,
      focus: "Hospital visits",
      custodian: "Alberta Health Services",
    },
    {
      title: "National Ambulatory Care Reporting System",
      short: "NACRS",
      minYear: 2002,
      maxYear: "current",
      minMonth: "April",
      maxMonth: null,
      category: "Admin",
      level: "National",
      lag: "1 Month",
      refresh: "Monthly",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "nacrs.png",
      recordsPerYear: 8755933,
      focus: "Ambulatory care",
      custodian: "Alberta Health Services",
    },
    {
      title: "Physician Claims",
      short: null,
      minYear: 1994,
      maxYear: "current",
      minMonth: "April",
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "6 Month",
      refresh: "Quarterly",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "claims.png",
      recordsPerYear: 48563894,
      focus:
        "claims submitted for payment of Alberta service providers for health services delivered under the Alberta Health Care Insurance Plan",
      custodian: "Alberta Health",
    },
    {
      title: "Pharmaceutical Information Network",
      short: "PIN",
      minYear: 2008,
      maxYear: "current",
      minMonth: "January",
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "1 Week",
      refresh: "Weekly",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "pin.png",
      recordsPerYear: 55212627,
      focus: "Pharmaceutical records from out-patient clinics",
      custodian: "Alberta Health",
    },
    {
      title: "Provincial Lab Data",
      short: "LAB",
      minYear: 2012,
      maxYear: "current",
      minMonth: "April",
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "1 Week",
      refresh: "Daily",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "lab.png",
      recordsPerYear: 205812948,
      focus: "Laboratory test results",
      custodian: "Alberta Health Services",
    },
    {
      title: "Vital Statistics - Death Registry",
      short: "VS - DR",
      minYear: 1983,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "3 Month",
      refresh: "Daily",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "vs.png",
      recordsPerYear: 25626,
      focus: "Death records",
      custodian: "Alberta Health",
    },
    {
      title: "Vital Statistics - Birth Registry",
      short: "VS - BR",
      minYear: 1983,
      maxYear: "current",
      minMonth: "January",
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "3 Month",
      refresh: "Daily",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "vsb.png",
      recordsPerYear: 53800,
      focus: "Birth records",
      custodian: "Alberta Health",
    },
    {
      title: "Provincial Registry",
      short: "REG",
      minYear: 1994,
      maxYear: "current",
      minMonth: "April",
      maxMonth: null,
      category: "Admin",
      level: "Provincial",
      lag: "6 Month",
      refresh: "Annually",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "reg.png",
      recordsPerYear: 4687121,
      focus: "Alberta Health Care Insurance Plan (AHCIP) registrant and plan information",
      custodian: "Alberta Health",
    },
    {
      title: "Alberta Provincial Project for Outcome Assessment in Coronary Heart Disease",
      short: "APPROACH",
      minYear: 1995,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "No",
      refresh: "Daily",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "approach.png",
      recordsPerYear: 2915209,
      focus: "Cardiac health",
      custodian: "Alberta Health Services",
    },
    {
      title: "Cardiovascular Imaging Registry of Canada",
      short: "CIROC",
      minYear: 2014,
      maxYear: "current",
      minMonth: 'August',
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "",
      refresh: "Coming soon",
      zone: ["Calgary"],
      checked: false,
      image: "approach.png",
      recordsPerYear: null,
      focus: "Cardiovascular disease",
      custodian: "University of Calgary",
    },
    {
      title: "TotalCardiology Electronic Record",
      short: "TCer",
      minYear: 1996,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "No",
      refresh: "Real-time",
      zone: ["All cardiac rehabilitation referrals within Calgary"],
      checked: false,
      image: "approach.png",
      recordsPerYear: 56500,
      focus: "Demographic/clinical characteristics, medications, cardiovascular risk factors, exercise stress test, and cardiac rehabilitation attendance data among patients referred to TotalCardiology Rehabilitation.",
      custodian: "TotalCardiology Inc.",
    },
    {
      title: "Canadian Observational Study on Epilepsy - Calgary Site",
      short: "CANOE",
      minYear: 2007,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "No",
      refresh: "Daily",
      zone: ["All referrals to the Calgary Comprehensive Epilepsy Program (catchment area of Southern Alberta and Eastern BC)"],
      checked: false,
      image: "brain.png",
      recordsPerYear: 7000,
      focus: "Highly granular patient and physician entered information on demographics, patient reported outcomes, epilepsy type, seizure types and frequency, medications, and outcomes.",
      custodian: "Colin Josephson (cbjoseph@ucalgary.ca) & Samuel Wiebe (swiebe@ucalgary.ca)",
    },
    {
      title: "Alberta Kidney Disease Network",
      short: "AKDN",
      minYear: "January 1, 2005. Comprehensive laboratory and administrative data available from January 1, 2005 to March 31, 2021. Administrative data no longer update passed March 31, 2021. Laboratory data updated monthly. ",
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "No",
      refresh: "Monthly",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "kidney.png",
      recordsPerYear: 3200000,
      focus: "Population-based laboratory and administrative data used to guide the delivery of health care for people at risk or living with kidney disease in Alberta.",
      custodian: "AHS/AH - queries can be made to icdc@ucalgary.ca",
    },
    {
      title: "Rheum4U Precision Health Registry",
      short: "Rheum4U",
      minYear: 2016,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "Clinical",
      level: "Provincial",
      lag: "No",
      refresh: "Daily",
      zone: ["Calgary", "South"],
      checked: false,
      image: "arthritis.png",
      recordsPerYear: 1800,
      focus: "Inflammatory arthritis registry that captures patient demographics, clinical presentation, laboratory markers, pharmacotherapeutic treatment, and patient reported outcomes.",
      custodian: "University of Calgary - rheum4U@ucalgary.ca",
    },
    {
      title: "Canadian Primary Care Sentinel Surveillance Network",
      short: "CPCSSN",
      minYear: 2008,
      maxYear: "current",
      minMonth: null,
      maxMonth: null,
      category: "EMR",
      level: "National",
      lag: "6 Month",
      refresh: "Bi-annually",
      zone: ["Calgary", "Central", "Edmonton", "North", "South"],
      checked: false,
      image: "cpcssn.png",
      recordsPerYear: 8700000,
      focus:
        "Southern Alberta primary care EMR data. (Information on Northern Alberta and pan-Canadian data available <ahttps://cpcssn.ca/>here</a>)",
      custodian: "Family physicians /nData Steward: SAPCReN/NAPCReN",
    },
    {
      title: "Sunrise Clinical Manager",
      short: "SCM",
      minYear: 2011,
      maxYear: "current",
      minMonth: "January",
      maxMonth: null,
      category: "EMR",
      lag: "No",
      refresh: "Daily",
      zone: ["Calgary"],
      checked: false,
      image: "scm.png",
      recordsPerYear: 1453417,
      focus: "a Calgary zone clinical information system collecting in-patient EMR data",
      custodian: "Alberta Health Services",
    },
    {
      title: "ConnectCare",
      short: null,
      minYear: 2019, // phased implementation
      maxYear: "current",
      minMonth: "November",
      maxMonth: null,
      category: "EMR",
      lag: "No",
      refresh: "Daily",
      zone: ["Edmonton"], // phased implementation
      checked: false,
      image: "epic.png",
      recordsPerYear: 1, // phased implementation
      focus: "a single, unified Alberta Health Services provincial clinical information system collecting EMR data",
      custodian: "Alberta Health Services",
    },
  ],
  selectedDataset: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATASETS:
      return { ...state, datasets: action.value };
    case actionTypes.SET_SELECTED_DATASET:
      return { ...state, selectedDataset: action.value };
    case actionTypes.SET_CHECKED:
      return {
        ...state,
        datasets: state.datasets.map((dataset) =>
          dataset.title === action.value ? { ...dataset, checked: !dataset.checked } : dataset
        ),
      };

    default:
      return state;
  }
};

export default reducer;
