import React, { useState, useEffect, useRef } from "react";
import MenuBar from "../../Components/MenuBar/MenuBar.js";
import HypertensionIntro from "../../Components/Hypertension/HypertensionIntro";
import HypertensionMethods from "../../Components/Hypertension/HypertensionMethods";
import HypertensionResults from "../../Components/Hypertension/HypertensionResults";
import InfoHover from "../../Util/InfoHover";
import { Carousel, Layout, Radio } from "antd";
import { throttle } from "lodash";
import { getWindowBracket } from "../../Util/layout";
import { hypertensionInfo } from "../../Util/writing";

const { Content } = Layout;

const HypertensionPage = () => {
  const [contentWidth, setContentWidth] = useState(window.innerWidth);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 60);
  const sizeBracket = getWindowBracket(contentWidth);
  const carouselRef = useRef();

  const resizeListener = () => {
    setContentWidth(document.getElementById("content").getBoundingClientRect().width);
    setContentHeight(window.innerHeight - 60);
  };
  const throttledResizeListener = throttle(resizeListener, 200);

  // componentDidMount and willUnmount
  useEffect(() => {
    window.addEventListener("resize", throttledResizeListener);
    return () => window.removeEventListener("resize", throttledResizeListener);
  }, []);

  const onRadioChange = (e) => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(e.target.value);
    }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <MenuBar bracket={sizeBracket} width={contentWidth} />
        <Content id="content">
          <div style={{ margin: "auto", textAlign: "center" }}>
            <Radio.Group onChange={onRadioChange} defaultValue={0}>
              <Radio.Button value={0}>{"Introduction & Methods"}</Radio.Button>
              <Radio.Button value={1}>Results</Radio.Button>
            </Radio.Group>
          </div>
          <Carousel ref={carouselRef} dots={false} style={{ padding: 10 }}>
            <div>
              <div
                style={{ height: contentHeight - 60, overflowY: "auto", margin: "auto", width: contentWidth * 0.95 }}
              >
                <HypertensionIntro width={contentWidth} />
                <InfoHover content={hypertensionInfo} backgroundColor="white" />
                <HypertensionMethods width={contentWidth * 0.95} height={contentHeight - 60} />
              </div>
            </div>
            <div>
              <HypertensionResults width={contentWidth} height={contentHeight - 60} />
            </div>
          </Carousel>
        </Content>
      </Layout>
    </>
  );
};

export default HypertensionPage;
