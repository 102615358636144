import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
} from "recharts";
import { Row, Col } from "antd";
import { pressureUlcerResultsIntro, pressureUlcerMatters, pressureUlcerAlgorithmIntro } from "../../Util/writing";
import {
  getResultsConstants,
  puPie,
  barChartDataPatientCount,
  barChartDataMeanNumDoc,
  barChartDataMeanWords,
  radarData,
  radarDataComparison,
  algorithmColors,
} from "./PressureUlcerConstantsHelpers";
import { renderLabel } from "../Hypertension/HypertensionResultsHelpers";
import PressureUlcerAlgorithmFilter from "./PressureUlcerAlgorithmFilter";
import { getNarrowTextDivStyle } from "../../Util/util";

const h3Style = { marginBottom: 0, display: "inline-block", margin: "0 auto", width: "93%", fontFamily: "TitleFont" };

const PressureUlcerResults = (props) => {
  const CONSTANTS = getResultsConstants(props.width, props.height);
  const [algorithmsChecked, setAlgorithmsChecked] = useState([
    { algorithm: "MPR99", name: "MPR", checked: false },
    { algorithm: "MPRPA99", name: "MPR & PA", checked: false },
    { algorithm: "MPRPAT99", name: "MPR & PAT", checked: false },
    { algorithm: "MPRPAPAT99", name: "MPR & PA & PAT", checked: false },
    { algorithm: "MPRFPA99", name: "MPR & FPA", checked: false },
  ]);

  const formatTooltipLabel = (short) => {
    switch (short) {
      case "PA":
        return "Patient Assessment";
      case "PAT":
        return "Patient Assessment Tools";
      case "MPR":
        return "Multidisciplinary Progress Records";
      default:
        break;
    }
  };

  const getTickFormat = (name, model, data) => {
    const datapoint = data.find((point) => point.name === name);
    return name + ": " + datapoint[model] + "%";
  };

  const makeRadarChart = (width, data, datakey) => {
    return (
      <RadarChart
        outerRadius={CONSTANTS.radarChartOuterRadius}
        width={width}
        height={CONSTANTS.radarChartHeight}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        cx={CONSTANTS.cx}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey="name"
          tickFormatter={(name) => getTickFormat(name, datakey, data)}
          style={{ fontSize: CONSTANTS.radarLabelFontSize }}
        />
        {makeRadars(datakey)}
      </RadarChart>
    );
  };

  const makeRadars = (datakey) => {
    if (datakey === "ICD10") {
      return [{ algorithm: "ICD10", name: "ICD-10", checked: true }, ...algorithmsChecked].map((algorithm) => (
        <Radar
          dataKey={algorithm.algorithm}
          stroke={algorithmColors[algorithm.algorithm]}
          fill={algorithmColors[algorithm.algorithm]}
          fillOpacity={algorithm.name === "ICD-10" ? 0.5 : algorithm.checked ? 0.3 : 0}
          strokeOpacity={algorithm.checked ? 1 : 0}
        />
      ));
    } else {
      return (
        <Radar dataKey={datakey} stroke={algorithmColors[datakey]} fill={algorithmColors[datakey]} fillOpacity={0.7} />
      );
    }
  };

  return (
    <div
      style={{
        margin: "auto",
        width: `${CONSTANTS.viewWidth * 100}%`,
        padding: 20,
        paddingBottom: 0,
        backgroundColor: "white",
        height: props.height,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div style={getNarrowTextDivStyle(props.width)}>{pressureUlcerResultsIntro}</div>

      {/* Pie Chart */}
      <h2 style={{ marginBottom: 20, textAlign: "center", marginTop: 40, fontSize: "2em", fontFamily: "TitleFont" }}>
        The Data
      </h2>
      <Row style={{ textAlign: "center" }}>
        <Col xs={24}>
          <h3 style={{ marginBottom: 0, fontFamily: "TitleFont" }}>Pressure Injuries</h3>
          <PieChart height={CONSTANTS.pieChartHeight} width={CONSTANTS.pieChartWidth}>
            <Pie
              data={puPie}
              dataKey="value"
              nameKey="name"
              outerRadius={CONSTANTS.outerRadius}
              cx={CONSTANTS.cx}
              cy={CONSTANTS.cy}
              label={renderLabel}
            >
              {puPie.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </Col>
      </Row>

      {/* Bar Charts */}
      <Row style={{ textAlign: "center" }}>
        {/* Patients Count (%) */}
        <Col xs={24} lg={8}>
          <h3 style={{ marginBottom: 0, fontFamily: "TitleFont" }}>Patients Count (%)</h3>
          <ResponsiveContainer width="95%" height={CONSTANTS.outerRadius * 2.5}>
            <BarChart data={barChartDataPatientCount} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[0, 100]} unit="%" />
              <Tooltip labelFormatter={formatTooltipLabel} />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="Injury Absent" fill="#2d4b7a" unit="%" />
              <Bar dataKey="Injury Present" fill="#617eac" unit="%" />
            </BarChart>
          </ResponsiveContainer>
        </Col>

        {/* Mean Number of Documents */}
        <Col xs={24} lg={8}>
          <h3 style={{ marginBottom: 0, fontFamily: "TitleFont" }}>Mean Number of Documents</h3>
          <ResponsiveContainer width="95%" height={CONSTANTS.outerRadius * 2.5}>
            <BarChart data={barChartDataMeanNumDoc} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
              <XAxis dataKey="name" />
              <YAxis type="number" />
              <Tooltip labelFormatter={formatTooltipLabel} />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="Injury Absent" fill="#2d4b7a" />
              <Bar dataKey="Injury Present" fill="#617eac" />
            </BarChart>
          </ResponsiveContainer>
        </Col>

        {/* Mean Word Count */}
        <Col xs={24} lg={8}>
          <h3 style={{ marginBottom: 0, fontFamily: "TitleFont" }}>Mean Word Count</h3>
          <ResponsiveContainer width="95%" height={CONSTANTS.outerRadius * 2.5}>
            <BarChart data={barChartDataMeanWords} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
              <XAxis dataKey="name" />
              <YAxis type="number" />
              <Tooltip labelFormatter={formatTooltipLabel} />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="Injury Absent" fill="#2d4b7a" />
              <Bar dataKey="Injury Present" fill="#617eac" />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <br />

      <h2 style={{ marginBottom: 3, textAlign: "center", fontSize: "2em", fontFamily: "TitleFont" }}>
        Gradient Boost Results
      </h2>
      {pressureUlcerAlgorithmIntro}

      {/* Radar Chart Comparison */}
      <Row style={{ marginTop: 0 }}>
        <Col xs={0} sm={0} md={0} lg={6} xl={7} />
        <Col xs={24} sm={24} md={16} lg={12} xl={10} style={{ textAlign: "center" }}>
          <h3 style={h3Style}>ICD-10 Comparison</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthICD, radarDataComparison, "ICD10")}
        </Col>
        <Col xs={6} sm={7} md={0} />
        <Col xs={12} sm={10} md={8} lg={5} xl={4}>
          <PressureUlcerAlgorithmFilter
            algorithmsChecked={algorithmsChecked}
            setAlgorithmsChecked={setAlgorithmsChecked}
          />
        </Col>
        <Col xs={6} sm={7} md={0} lg={1} xl={3} />
      </Row>

      <br />

      {/* Radar Charts Algorithm */}
      <Row style={{ textAlign: "center", marginTop: 0 }}>
        {/* MPR */}
        <Col xs={24} md={24} xl={12}>
          <h3 style={h3Style}>Multidisciplinary Progress Records</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthMPR, radarData, "MPR99")}
        </Col>

        {/* MPR + PA */}
        <Col xs={24} md={12} xl={12}>
          <h3 style={h3Style}>Multidisciplinary Progress Records & Patient Assessment</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthMPRPA, radarData, "MPRPA99")}
        </Col>

        {/* MPR + PAT */}
        <Col xs={24} md={12} xl={8}>
          <h3 style={h3Style}>Multidisciplinary Progress Records & Patient Assessment Tools</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthThree, radarData, "MPRPAT99")}
        </Col>

        {/* MPR + PA + PAT */}
        <Col xs={24} md={12} xl={8}>
          <h3 style={h3Style}>Multidisciplinary Progress Records & Patient Assessment & Patient Assessment Tools</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthThree, radarData, "MPRPAPAT99")}
        </Col>

        {/* MPR + FPA */}
        <Col xs={24} md={12} xl={8}>
          <h3 style={h3Style}>Multidisciplinary Progress Records & filtered Patient Assessment</h3>
          {makeRadarChart(CONSTANTS.radarChartWidthThree, radarData, "MPRFPA99")}
        </Col>
      </Row>

      <div style={getNarrowTextDivStyle(props.width)}>
        <h2>Why Does This Matter?</h2>
        {pressureUlcerMatters}
      </div>
    </div>
  );
};
export default PressureUlcerResults;
