export const getMethodsConstants = (width, height) => {
  ///// CONSTANTS FOR CATEGORIES ////////////
  ///////////////////////////////////////////
  const rectProportionWidth = 0.3;
  const rectProportionHeight = 0.3;
  const categoryRectWidth = width * rectProportionWidth;
  const categoryRectHeight = height * rectProportionHeight;
  const fontSize = Math.min(categoryRectHeight / 9, categoryRectWidth / 15);
  const totalCategoryHeight = height - 1.5 * fontSize;
  const categoryXCoords = [
    ((1 - 2 * rectProportionWidth) / 3) * width,
    (1 - (1 - 2 * rectProportionWidth) / 3) * width - categoryRectWidth,
    ((1 - 2 * rectProportionWidth) / 3) * width,
    (1 - (1 - 2 * rectProportionWidth) / 3) * width - categoryRectWidth,
  ];
  const categoryYCoords = [
    ((1 - 2 * rectProportionHeight) / 3) * height,
    ((1 - 2 * rectProportionHeight) / 3) * height,
    (1 - (1 - 2 * rectProportionHeight) / 3) * height - categoryRectHeight,
    (1 - (1 - 2 * rectProportionHeight) / 3) * height - categoryRectHeight,
  ];
  const rx = 20;
  const ry = 20;

  ///// DETAIL CONSTANTS ////////////////////
  ///////////////////////////////////////////
  const smallRectWidth = 0.3 * categoryRectWidth;
  const smallRectHeight = 0.2 * categoryRectHeight;

  ///// CONSTANTS FOR DATA CATEGORY ////////////
  //////////////////////////////////////////////
  const dataRectWidth = 0.25 * width;
  const dataRectHorizontalGap = (width - 3 * dataRectWidth) / 4;
  const dataRectHeight = 0.275 * height;
  const dataXCoords = [
    dataRectHorizontalGap - 5,
    2 * dataRectHorizontalGap + dataRectWidth - 5,
    3 * dataRectHorizontalGap + 2 * dataRectWidth - 5,
    2 * dataRectHorizontalGap + dataRectWidth - 5,
  ];
  const dataYCoords = [0.2 * height, 0.2 * height, 0.2 * height, 0.4 * height + dataRectHeight];
  const dataXCoordsSmall = [
    0.025 * categoryRectWidth,
    categoryRectWidth / 2 - smallRectWidth / 2,
    0.975 * categoryRectWidth - smallRectWidth,
    categoryRectWidth / 2 - smallRectWidth / 2,
  ];
  const dataYCoordsSmall = [
    0.05 * categoryRectHeight,
    0.05 * categoryRectHeight,
    0.05 * categoryRectHeight,
    categoryRectHeight * 0.95 - smallRectHeight,
  ];

  ///// CONSTANTS FOR CONCEPT CATEGORY ////////////
  /////////////////////////////////////////////////
  const conceptRectWidth = 0.4 * width;
  const conceptRectHeight = 0.2 * height;
  const conceptRectVerticalGap = (totalCategoryHeight * 0.85 - 3 * conceptRectHeight) / 2;
  const conceptYCoords = [
    height - 0.925 * totalCategoryHeight,
    height - 0.925 * totalCategoryHeight + conceptRectHeight + conceptRectVerticalGap,
    height - 0.925 * totalCategoryHeight + 2 * (conceptRectHeight + conceptRectVerticalGap),
  ];
  const conceptYCoordsSmall = [
    0.05 * categoryRectHeight,
    categoryRectHeight / 2 - smallRectHeight / 2,
    categoryRectHeight * 0.95 - smallRectHeight,
  ];

  ///// CONSTANTS FOR FEATURE CATEGORY ////////////
  /////////////////////////////////////////////////
  const featureRectWidth = 0.35 * width;
  const featureRectHeight = 0.2 * height;
  const featureXCoords = [
    width / 2 - (width - 2 * featureRectWidth) / 6 - featureRectWidth,
    width / 2 + (width - 2 * featureRectWidth) / 6,
    width / 2 - featureRectWidth / 2,
    width / 2 - featureRectWidth / 2,
  ];
  const featureRectVerticalGap = (totalCategoryHeight * 0.85 - 3 * featureRectHeight) / 2;
  const featureYCoords = [
    height - 0.925 * totalCategoryHeight,
    height - 0.925 * totalCategoryHeight,
    height - 0.925 * totalCategoryHeight + featureRectHeight + featureRectVerticalGap,
    height - 0.925 * totalCategoryHeight + 2 * (featureRectHeight + featureRectVerticalGap),
  ];
  const featureYCoordsSmall = [
    0.05 * categoryRectHeight,
    0.05 * categoryRectHeight,
    categoryRectHeight / 2 - smallRectHeight / 2,
    categoryRectHeight * 0.95 - smallRectHeight,
  ];
  const featureXCoordsSmall = [
    categoryRectWidth / 4 - smallRectWidth / 2,
    (categoryRectWidth * 3) / 4 - smallRectWidth / 2,
    categoryRectWidth / 2 - smallRectWidth / 2,
    categoryRectWidth / 2 - smallRectWidth / 2,
  ];

  ///// CONSTANTS FOR MODEL CATEGORY //////////////
  /////////////////////////////////////////////////
  const modelRectWidth = 0.4 * width;
  const modelRectHeight = 0.2 * height;
  const modelRectVerticalGap = (totalCategoryHeight * 0.85 - 3 * modelRectHeight) / 2;
  const modelYCoords = [
    height - 0.925 * totalCategoryHeight,
    height - 0.925 * totalCategoryHeight + modelRectHeight + modelRectVerticalGap,
    height - 0.925 * totalCategoryHeight + 2 * (modelRectHeight + modelRectVerticalGap),
  ];
  const modelYCoordsSmall = [
    0.05 * categoryRectHeight,
    categoryRectHeight / 2 - smallRectHeight / 2,
    categoryRectHeight * 0.95 - smallRectHeight,
  ];

  ///// END OF CONSTANTS //////////////////////////
  /////////////////////////////////////////////////

  return {
    // CATEGORIES
    categoryLineColor: "#6e6666",
    categories: ["Data", "Concept Extraction", "Creating & Refining Models", "Feature Selection"],
    categoryRectWidth: categoryRectWidth,
    categoryRectHeight: categoryRectHeight,
    categoryXCoords: categoryXCoords,
    categoryYCoords: categoryYCoords,
    fontSize: fontSize,
    categoryEndMarkers: [
      "url(#categoryHorizontal)",
      "url(#categoryVertical)",
      "url(#categoryHorizontal)",
      "url(#categoryHorizontal)",
    ],
    categoryLineX1: [categoryRectWidth, rx / 3, categoryRectWidth, 0],
    categoryLineX2: [
      categoryXCoords[1] - categoryXCoords[0],
      -(categoryXCoords[1] - categoryXCoords[2]) + categoryRectWidth - rx / 3,
      categoryXCoords[1] - categoryXCoords[0],
      categoryXCoords[0] - categoryXCoords[1] + categoryRectWidth,
    ],
    categoryLineY1: [
      categoryRectHeight / 2,
      categoryRectHeight - ry / 3,
      categoryRectHeight / 2 - 10,
      categoryRectHeight / 2 + 10,
    ],
    categoryLineY2: [
      categoryRectHeight / 2,
      categoryYCoords[2] - categoryYCoords[1] + ry / 3,
      categoryRectHeight / 2 - 10,
      categoryRectHeight / 2 + 10,
    ],
    totalCategoryHeight: totalCategoryHeight,
    categoryColors: ["#2d4b7a", "#82b2b8", "#ff9324", "#b6bd36"],
    rx: rx,
    ry: ry,
    // DATA CATEGORY
    databases: ["Sunrise Clinical Manager", "Discharge Abstract Database", "Medical Chart Review", "Final Dataset"],
    dataText: [
      "Sunrise Clinical Manager (SCM) is an electronic medical record (EMR) system operating through all acute care facilities in Calgary. SCM is managed by Alberta Health Services (AHS).",
      "The Discharge Abstract Database (DAD) is an administrative database where discharge abstracts are store. Discharges abstracts contain information about a patient's visit to a hospital, including diagnosis codes and basic demographic information.",
      "Patient charts were extracted and reviewed by nurses with the purpose of identifying charts with a listed diagnosis of hypertension.",
      "The final dataset is created by linking the three databases using Personal Health number (PHN), chart number, and admission date. Documents were then separated by type (58 different types).",
    ],
    dataRectWidth: dataRectWidth,
    dataRectHorizontalGap: dataRectHorizontalGap,
    dataRectHeight: dataRectHeight,
    dataXCoords: dataXCoords,
    dataYCoords: dataYCoords,
    dataFontSize: 0.65 * fontSize,
    dataEndMarkers: ["url(#arrowSideData)", "url(#arrowMiddleData)", "url(#arrowSideData)", null],
    dataLineX2Coords: [
      dataRectWidth / 2 - dataXCoords[0] + dataXCoords[3],
      dataRectWidth / 2 - dataXCoords[1] + dataXCoords[3],
      dataRectWidth / 2 - dataXCoords[2] + dataXCoords[3],
      dataRectWidth / 2,
    ],
    dataLineY2Coords: [
      dataYCoords[3] - dataYCoords[0],
      dataYCoords[3] - dataYCoords[1],
      dataYCoords[3] - dataYCoords[2],
      dataRectHeight,
    ],
    dataXCoordsSmall: dataXCoordsSmall,
    dataYCoordsSmall: dataYCoordsSmall,
    dataLineX2Small: [
      dataXCoordsSmall[3] - dataXCoordsSmall[0] + smallRectWidth / 2,
      dataXCoordsSmall[3] - dataXCoordsSmall[1] + smallRectWidth / 2,
      dataXCoordsSmall[3] - dataXCoordsSmall[2] + smallRectWidth / 2,
      smallRectWidth / 2,
    ],
    dataLineY2Small: [
      dataYCoordsSmall[3] - dataYCoordsSmall[0],
      dataYCoordsSmall[3] - dataYCoordsSmall[1],
      dataYCoordsSmall[3] - dataYCoordsSmall[2],
      smallRectHeight,
    ],
    dataSmallEndMarkers: ["url(#smallDataSide)", "url(#smallDataMiddle)", "url(#smallDataSide)", null],
    // CONCEPT CATEGORY
    concepts: ["Clinical Documents", "Concept Unique Identifiers", "Document-Concept Matrix"],
    conceptText: [
      "Clinical documents were analyzed with the default pipeline of the clinical Text Analysis and Knowledge Extraction System (cTAKES) to extract clinical concepts. Concepts were mapped to Concept Unique Identifiers (CUIs) from the Unified Medical Language System (UMLS).",
      'All synonyms of "Hypertensive disease" map to the same CUI (C0020538), which allows for non-redundant feature generation. The negation and subject attribute annotators in cTAKES are used to identify if the concept is negated, or belonging to someone other than the patient.',
      "With only non-negated CUIs that are associated with the patient, a document-concept matrix can be created. The matrix contains the counts of each CUI for each document type, and each chart.",
    ],
    conceptRectWidth: conceptRectWidth,
    conceptRectHeight: conceptRectHeight,
    conceptXCoords: [
      width / 2 - conceptRectWidth / 2,
      width / 2 - conceptRectWidth / 2,
      width / 2 - conceptRectWidth / 2,
    ],
    conceptRectVerticalGap: conceptRectVerticalGap,
    conceptYCoords: conceptYCoords,
    conceptFontSize: 0.65 * fontSize,
    conceptEndMarkers: ["url(#arrowConcept)", "url(#arrowConcept)", null],
    conceptLineX2Coords: [conceptRectWidth / 2, conceptRectWidth / 2, conceptRectWidth / 2],
    conceptLineY2Coords: [
      conceptYCoords[1] - conceptYCoords[0],
      conceptYCoords[2] - conceptYCoords[1],
      conceptRectHeight,
    ],
    conceptXCoordsSmall: [
      categoryRectWidth / 2 - smallRectWidth / 2,
      categoryRectWidth / 2 - smallRectWidth / 2,
      categoryRectWidth / 2 - smallRectWidth / 2,
    ],
    conceptYCoordsSmall: conceptYCoordsSmall,
    conceptLineX2Small: [smallRectWidth / 2, smallRectWidth / 2, smallRectWidth / 2],
    conceptLineY2Small: [
      conceptYCoordsSmall[1] - conceptYCoordsSmall[0],
      conceptYCoordsSmall[2] - conceptYCoordsSmall[1],
      smallRectHeight,
    ],
    conceptSmallEndMarkers: ["url(#smallStandard)", "url(#smallStandard)", null],
    // FEATURE CATEGORY
    features: ["Concept Features", "Document-Concept Features", "Models", "Feature Selection"],
    featureText: [
      "From the previously generated Concept Unique Identifiers (CUIs), two types of feature sets are created. The first set contains the number of times each concept occurs within each patient chart.",
      "The second feature set contains the number of times each concept occurred in each document type.",
      'Five XGBoost models were fit for each feature set (see "Creating & Refining Models" for more information).',
      "Gain (the improvement in accuracy of classification that can be attributed to a feature) was used to select the most important features from each set.",
    ],
    featureRectWidth: featureRectWidth,
    featureRectHeight: featureRectHeight,
    featureXCoords: featureXCoords,
    featureYCoords: featureYCoords,
    featureFontSize: 0.65 * fontSize,
    featureEndMarkers: ["url(#featureSideConcept)", "url(#featureSideConcept)", "url(#arrowConcept)", null],
    featureLineX2Coords: [
      -featureXCoords[0] + width / 2,
      -featureXCoords[1] + width / 2,
      featureRectWidth / 2,
      featureRectWidth / 2,
    ],
    featureLineY2Coords: [
      featureYCoords[2] - featureYCoords[0],
      featureYCoords[2] - featureYCoords[1],
      featureYCoords[3] - featureYCoords[2],
      featureRectHeight,
    ],
    featureXCoordsSmall: featureXCoordsSmall,
    featureYCoordsSmall: featureYCoordsSmall,
    featureLineX2Small: [
      featureXCoordsSmall[2] - featureXCoordsSmall[0] + smallRectWidth / 2,
      featureXCoordsSmall[2] - featureXCoordsSmall[1] + smallRectWidth / 2,
      smallRectWidth / 2,
      smallRectWidth / 2,
    ],
    featureLineY2Small: [
      featureYCoordsSmall[2] - featureYCoordsSmall[0],
      featureYCoordsSmall[2] - featureYCoordsSmall[1],
      featureYCoordsSmall[3] - featureYCoordsSmall[2],
      smallRectHeight,
    ],
    featureSmallEndMarkers: ["url(#smallFeature)", "url(#smallFeature)", "url(#smallStandard)", null],
    // MODEL CATEGORY
    models: ["Initial Models", "Feature Selection", "Final Models"],
    modelText: [
      "Five XGBoost models were created for both of the previously generated feature sets (concepts and document-concepts). The models were fit using 5-fold cross-validation, optimizing for Area Under the Curve (AUC).",
      'The most relevant features were selected to tune the models (see "Feature Selection" for more information).',
      "One final XGBoost model was fit for each of the reduced feature sets. SHapley Additive exPlanations (SHAP) values were used to assign influence values to each feature, allowing for more interpretable models. The SHAP values were then used to create a simpler concept search strategy for case identification.",
    ],
    modelRectWidth: modelRectWidth,
    modelRectHeight: modelRectHeight,
    modelXCoords: [width / 2 - modelRectWidth / 2, width / 2 - modelRectWidth / 2, width / 2 - modelRectWidth / 2],
    modelYCoords: modelYCoords,
    modelEndMarkers: ["url(#arrowConcept)", "url(#arrowConcept)", null],
    modelLineX2Coords: [modelRectWidth / 2, modelRectWidth / 2, modelRectWidth / 2],
    modelLineY2Coords: [modelYCoords[1] - modelYCoords[0], modelYCoords[2] - modelYCoords[1], modelRectHeight],
    modelXCoordsSmall: [
      categoryRectWidth / 2 - smallRectWidth / 2,
      categoryRectWidth / 2 - smallRectWidth / 2,
      categoryRectWidth / 2 - smallRectWidth / 2,
    ],
    modelYCoordsSmall: modelYCoordsSmall,
    modelLineX2Small: [smallRectWidth / 2, smallRectWidth / 2, smallRectWidth / 2],
    modelLineY2Small: [
      modelYCoordsSmall[1] - modelYCoordsSmall[0],
      modelYCoordsSmall[2] - modelYCoordsSmall[1],
      smallRectHeight,
    ],
    modelSmallEndMarkers: ["url(#smallStandard)", "url(#smallStandard)", null],
    // SMALL
    smallRectWidth: smallRectWidth,
    smallRectHeight: smallRectHeight,
    smallFontSize: 0.075 * smallRectWidth,
    // EXTRA
    detailColor: "white",
    detailFontSize: 0.65 * fontSize,
    duration: 1000,
    arrowPoints: [
      [0, 0],
      [0, 5],
      [5, 2.5],
    ],
  };
};
