import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import * as actions from "../../Store/Actions/index.js";
import { getLines, getTextRadius, getOffsetY } from "../../Util/d3TextUtil";

// constants
const datasetClass = "datasetVis";
const duration = 500;
const textColor = "white";
const datasetFontSize = 10;
const lineHeight = datasetFontSize * 1.1;
const mainColor = "#6e6666";

// indicators on how to draw
let selectedCategory = -1;
let selectedDataset = -1;
let datasets = [];

const datasetSpread = 1.3 * Math.PI;
const datasetOffset = datasetSpread / 2;

const HierarchyDatasets = (props) => {
  // constants
  const minSize = Math.min(props.width, props.height);
  // main
  const centerCircleInnerR = 0.09 * minSize;
  const centerCircleMiddleR = 0.11 * minSize;
  const centerCircleOuterR = 0.13 * minSize;
  const mainTextSize = minSize / 35;
  // category
  const categoryCircleOuterR = 0.115 * minSize;
  const categoryCircleInnerR = 0.1 * minSize;
  const categoryTextSize = minSize / 40;
  // dataset
  const datasetCircleInnerR = 0.08 * minSize;
  const datasetCircleOuterR = 0.095 * minSize;
  // Links
  const centerCategoryLinkLength = 0.18 * minSize;
  const categoryDatasetLinkLength = 0.11 * minSize;

  // variables
  let svg, datasetGs, categoryGs, mainG, handlingClick, datasetTextR;

  useEffect(() => {
    getDatasetTextR();
    drawDatasets();
  }, []);

  useEffect(() => {
    getDatasetTextR();
    drawDatasets();
  }, [props.width, props.height]);

  const getDatasetTextR = () => {
    datasetTextR = 0;
    for (const dataset of props.datasets) {
      const lines = getLines(dataset.title + (dataset.short ? " (" + dataset.short + ")" : ""), lineHeight);
      const textR = getTextRadius(lines, lineHeight, "circle");
      if (textR > datasetTextR) {
        datasetTextR = textR;
      }
    }
  };

  // Sleep, used to wait between transitions
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // used to get coordinates for category-dataset connection lines
  // stage 0 = initial placement, in create datasets
  // stage 1 = placement in transition datasets
  const getCategoryDatasetConnectionLineCoordinates = (i, coord, stage = 0) => {
    const scale =
      (i === selectedDataset ? datasetCircleOuterR : datasetCircleInnerR) +
      stage * (categoryDatasetLinkLength + (selectedDataset === i) * (datasetCircleInnerR - datasetCircleOuterR));
    const angle =
      (2 * Math.PI * selectedCategory) / props.categories.length +
      (datasets.length > 1
        ? -datasetOffset + (datasetSpread * i) / (datasets.length > 1 ? datasets.length - 1 : 1)
        : 0);

    if (coord === "x") {
      return -Math.sin(angle) * scale;
    } else if (coord === "y") {
      return -Math.cos(angle) * scale;
    }
    return;
  };

  // used to get location for dataset Gs
  // stage 0 = initial placement - center of the screen inside categories
  // stage 1 = placement after transitioning out
  const getDatasetGLocation = (i = 0, stage = 0) => {
    const scale = (categoryCircleOuterR + datasetCircleInnerR + categoryDatasetLinkLength) * stage;
    const angle =
      (2 * Math.PI * selectedCategory) / props.categories.length +
      (datasets.length > 1
        ? -datasetOffset + (datasetSpread * i) / (datasets.length > 1 ? datasets.length - 1 : 1)
        : 0);

    return (
      "translate(" +
      (props.width / 2 + scale * Math.sin(angle)) +
      "," +
      (props.height / 2 + scale * Math.cos(angle)) +
      ")"
    );
  };

  // used to get coordinates for main-category connection lines
  // stage 0 - no category selected
  // stage 1 - category is selected
  const getMainCategoryConnectionLineCoordinates = (i, coord, stage = 0, outer = 1) => {
    const scale =
      (outer ? centerCategoryLinkLength + categoryCircleOuterR - categoryCircleInnerR : centerCircleMiddleR) +
      stage * (i === selectedCategory) * (categoryCircleInnerR - categoryCircleOuterR);
    const angle = (Math.PI * 2 * i) / props.categories.length;
    if (coord === "x") {
      return Math.sin(angle) * scale;
    } else if (coord === "y") {
      return Math.cos(angle) * scale;
    }
    return;
  };

  // used to get location for category Gs
  // stage 0 = inintial placement - main centered and categories around it
  // stage 1 = placement after selecting a category and transitioning out
  const getCategoryGLocation = (i, stage = 0) => {
    const centerScale = categoryCircleOuterR + centerCategoryLinkLength;
    const centerAngle = (Math.PI * 2 * i) / props.categories.length;
    const shiftedScale = stage * (categoryCircleOuterR + centerCategoryLinkLength);
    const shiftedAngle = (2 * Math.PI * selectedCategory) / props.categories.length;

    return (
      "translate(" +
      (props.width / 2 + centerScale * Math.sin(centerAngle) - shiftedScale * Math.sin(shiftedAngle)) +
      "," +
      (props.height / 2 + centerScale * Math.cos(centerAngle) - shiftedScale * Math.cos(shiftedAngle)) +
      ")"
    );
  };

  // used to get location for main G
  // stage = 0 - centered
  // stage = 1 - a category is selected
  const getMainGLocation = (stage = 0) => {
    const scale = stage * (categoryCircleOuterR + centerCategoryLinkLength);
    const angle = (2 * Math.PI * selectedCategory) / props.categories.length;

    return (
      "translate(" +
      (props.width / 2 - scale * Math.sin(angle)) +
      "," +
      (props.height / 2 - scale * Math.cos(angle)) +
      ")"
    );
  };

  // handles clicking on the main circle
  const handleMainClick = async () => {
    if (!handlingClick) {
      handlingClick = true;
      if (selectedCategory >= 0) {
        removeDatasets(true);
        await sleep(duration);
        selectedCategory = -1;
        transitionToCenter();
      }
    }
    handlingClick = false;
  };

  // handles clicking on a category
  const handleCategoryClick = async (category, index) => {
    if (!handlingClick) {
      handlingClick = true;
      if (selectedCategory === index) {
        return;
      }
      if (selectedCategory >= 0) {
        removeDatasets();
        await sleep(2 * duration);
      }
      datasets = props.datasets.filter((dataset) => dataset.category === category.name);
      selectedCategory = index;
      transitionMainAndCategories();
      await sleep(duration);
      createDatasets();
      transitionDatasets();
    }
    handlingClick = false;
  };

  // handles clicking on a dataset
  const handleDatasetClick = async (index) => {
    if (!handlingClick) {
      handlingClick = true;
      if (selectedDataset === index) {
        handlingClick = false;
        return;
      }

      selectedDataset = index;
      transitionDatasetOuterCircle();
      props.setSelectedDataset(datasets[selectedDataset]);
    }
    handlingClick = false;
  };

  // remove datasets - to be done when selecting another category
  const removeDatasets = async (shrinkCircle = false) => {
    if (selectedDataset >= 0) {
      selectedDataset = -1;
      props.setSelectedDataset(null);
      await sleep(duration);
    }

    // transitioning datasetGs to center of category
    svg
      .selectAll("g.datasetG")
      .data(datasets)
      .transition()
      .duration(duration / datasets.length)
      .delay((_, i) => (i * duration) / datasets.length)
      .attr("transform", getDatasetGLocation());

    // transitioning dataset circles to 0 radius
    svg
      .selectAll("circle.datasetInnerCircle")
      .data(datasets)
      .transition()
      .duration(duration / datasets.length)
      .delay((d, i) => (i * duration) / datasets.length)
      .attr("r", 0)
      .attr("fill-opacity", 0);

    // transitioning dataset circles to 0 radius
    svg
      .selectAll("circle.datasetOuterCircle")
      .data(datasets)
      .transition()
      .duration(duration / datasets.length)
      .delay((_, i) => (i * duration) / datasets.length)
      .attr("r", 0)
      .style("stroke", "none");

    // fading out dataset text
    for (let i = 0; i < datasetGs._groups[0].length; i++) {
      const lines = getLines(datasets[i].title + (datasets[i].short ? " (" + datasets[i].short + ")" : ""), lineHeight);

      svg
        .select("g.datasetG:nth-child(" + (i + 2 + props.categories.length) + ")")
        .selectAll("text.datasetText")
        .data(lines)
        .transition()
        .duration(duration / datasets.length)
        .delay((i * duration) / datasets.length)
        .attr("font-size", 0)
        .style("fill-opacity", 0);
    }

    // if main is clicked, 2nd circle around main should be removed,
    // and there will be no outer circle around a category
    if (shrinkCircle) {
      svg.select("circle.mainCircleOuter").transition().duration(duration).attr("r", centerCircleInnerR);
      svg.selectAll("circle.categoryOuterCircle").transition().duration(duration).attr("r", categoryCircleInnerR);

      // adjust the line to be go to the inner category circle
      svg
        .selectAll("line.mainCategoryConnection")
        .transition()
        .duration(duration)
        .attr("x2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "x"))
        .attr("y2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "y"));
    }

    // transition out lines
    svg
      .selectAll("line.categoryDatasetConnection")
      .data(datasets)
      .transition()
      .duration(duration / datasets.length)
      .delay((_, i) => (i * duration) / datasets.length)
      .attr("x2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "x"))
      .attr("y2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "y"));

    await sleep(duration);
    svg.selectAll("g.datasetG").remove();
  };

  // creates datasets invisible at category location, to be transitioned in later
  const createDatasets = () => {
    // creating Gs
    datasetGs = svg
      .selectAll("g.datasetG")
      .data(datasets)
      .enter()
      .append("g")
      .attr("class", "datasetG")
      .attr("transform", getDatasetGLocation())
      .style("cursor", "pointer")
      .on("click", (_, i) => handleDatasetClick(i));

    // creating circles
    datasetGs
      .data(datasets)
      .append("circle")
      .attr("class", "datasetInnerCircle")
      .attr("r", 0)
      .attr("fill", props.categories[selectedCategory].color)
      .style("fill-opacity", 0);

    datasetGs
      .data(datasets)
      .append("circle")
      .attr("class", "datasetOuterCircle")
      .attr("r", 0)
      .style("stroke", props.categories[selectedCategory].color)
      .style("fill-opacity", 0);

    // adding dataset text
    for (let i = 0; i < datasetGs._groups[0].length; i++) {
      const lines = getLines(datasets[i].title + (datasets[i].short ? " (" + datasets[i].short + ")" : ""), lineHeight);

      svg
        .select("g.datasetG:nth-child(" + (i + 2 + props.categories.length) + ")")
        .selectAll("text.datasetText")
        .data(lines)
        .enter()
        .append("text")
        .text((d) => d.text)
        .attr("class", "datasetText")
        .attr("font-size", 0)
        .attr("transform", "scale(" + datasetCircleInnerR / datasetTextR + ")")
        .attr("y", (_, i) => getOffsetY(i, lineHeight, "circle", lines.length))
        .attr("text-anchor", "middle")
        .style("fill-opacity", 0)
        .attr("fill", textColor);
    }

    // creating connection lines
    datasetGs
      .data(datasets)
      .append("line")
      .attr("class", "categoryDatasetConnection")
      .attr("x1", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "x"))
      .attr("y1", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "y"))
      .attr("x2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "x"))
      .attr("y2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "y"))
      .attr("stroke", props.categories[selectedCategory].color)
      .attr("stroke-width", 2);
  };

  // transitions datasets in
  const transitionDatasets = (transitionDuration = duration) => {
    // transitioning dataset groups to proper position
    svg
      .selectAll("g.datasetG")
      .data(datasets)
      .transition()
      .duration(transitionDuration / datasets.length)
      .delay((_, i) => ((i + 1) * transitionDuration) / datasets.length)
      .attr("transform", (_, i) => getDatasetGLocation(i, 1));

    // transitioning dataset connection lines
    svg
      .selectAll("line.categoryDatasetConnection")
      .data(datasets)
      .transition()
      .duration(transitionDuration / datasets.length)
      .delay((_, i) => ((i + 1) * transitionDuration) / datasets.length)
      .attr("x2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "x", 1))
      .attr("y2", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "y", 1));

    // showing next circle around main
    svg.select("circle.mainCircleOuter").transition().duration(transitionDuration).attr("r", centerCircleOuterR);

    // showing dataset circles
    svg
      .selectAll("circle.datasetInnerCircle")
      .data(datasets)
      .transition()
      .duration(transitionDuration / datasets.length)
      .delay((_, i) => ((i + 1) * transitionDuration) / datasets.length)
      .attr("r", datasetCircleInnerR)
      .style("fill-opacity", 1);

    svg
      .selectAll("circle.datasetOuterCircle")
      .data(datasets)
      .transition()
      .duration(transitionDuration / datasets.length)
      .delay((_, i) => ((i + 1) * transitionDuration) / datasets.length)
      .attr("r", datasetCircleInnerR);

    // transitioning text
    for (let i = 0; i < datasetGs._groups[0].length; i++) {
      const lines = getLines(datasets[i].title + (datasets[i].short ? " (" + datasets[i].short + ")" : ""), lineHeight);

      svg
        .select("g.datasetG:nth-child(" + (i + 2 + props.categories.length) + ")")
        .selectAll("text.datasetText")
        .data(lines)
        .transition()
        .duration(transitionDuration / datasets.length)
        .delay(((i + 1) * transitionDuration) / datasets.length)
        .attr("font-size", datasetFontSize)
        .style("fill-opacity", 1);
    }
  };

  // changes outer dataset circle
  const transitionDatasetOuterCircle = (transitionDuration = duration) => {
    svg
      .selectAll("circle.datasetOuterCircle")
      .data(datasets)
      .transition()
      .duration(transitionDuration)
      .attr("r", (_, i) => (i === selectedDataset ? datasetCircleOuterR : datasetCircleInnerR));

    // transitioning dataset connection lines
    svg
      .selectAll("line.categoryDatasetConnection")
      .data(datasets)
      .transition()
      .duration(transitionDuration)
      .attr("x1", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "x"))
      .attr("y1", (_, i) => getCategoryDatasetConnectionLineCoordinates(i, "y"));
  };

  // transitions main circle to center and categories in their spots around it
  const transitionToCenter = () => {
    mainG.transition().duration(duration).attr("transform", getMainGLocation());

    categoryGs
      .data(props.categories)
      .transition()
      .duration(duration)
      .attr("transform", (_, i) => getCategoryGLocation(i));

    // transition main-category connection lines
    mainG
      .selectAll("line.mainCategoryConnection")
      .data(props.categories)
      .transition()
      .duration(duration)
      .attr("x2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "x"))
      .attr("y2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "y"));

    // shrinks category outer circle
    svg
      .selectAll("circle.categoryOuterCircle")
      .data(props.categories)
      .transition()
      .duration(duration)
      .attr("r", categoryCircleInnerR);
  };

  // transitions selected category to the center, and shifts main/other categories as necessary
  const transitionMainAndCategories = (transitionDuration = duration) => {
    // transition main away from center
    mainG.transition().duration(transitionDuration).attr("transform", getMainGLocation(1));

    // transition category Gs to appropriate spots
    categoryGs
      .data(props.categories)
      .transition()
      .duration(transitionDuration)
      .attr("transform", (_, i) => getCategoryGLocation(i, 1));

    // transition main-category connection lines
    mainG
      .selectAll("line.mainCategoryConnection")
      .data(props.categories)
      .transition()
      .duration(transitionDuration)
      .attr("x2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "x", 1))
      .attr("y2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "y", 1));

    // expands category outer circle
    svg
      .selectAll("circle.categoryOuterCircle")
      .data(props.categories)
      .transition()
      .duration(transitionDuration)
      .attr("r", (_, i) => (i === selectedCategory ? categoryCircleOuterR : categoryCircleInnerR))
      .attr("stroke", (d) => d.color);
  };

  // creates categories
  const createCategories = () => {
    // creating Gs
    categoryGs = svg
      .selectAll("g.categoryG")
      .data(props.categories)
      .enter()
      .append("g")
      .attr("class", "categoryG")
      .attr("transform", (_, i) => getCategoryGLocation(i))
      .style("cursor", "pointer")
      .on("click", (d, i) => handleCategoryClick(d, i));

    // creating outer circles
    categoryGs
      .data(props.categories)
      .append("circle")
      .attr("class", "categoryOuterCircle")
      .attr("r", categoryCircleOuterR)
      .style("fill-opacity", 0);

    // creating circles
    categoryGs
      .data(props.categories)
      .append("circle")
      .attr("class", "categoryInnerCircle")
      .attr("r", categoryCircleInnerR)
      .attr("fill", (d) => d.color)
      .style("fill-opacity", 1);

    // creating text
    const categoryText = categoryGs
      .data(props.categories)
      .append("text")
      .attr("class", "categoryText")
      .attr("font-size", categoryTextSize)
      .attr("y", (d) => 0.25 - (d.long.split(" ").length + 1) / 2 + "em")
      .attr("fill", textColor)
      .style("text-anchor", "middle")
      .style("fill-opacity", 1);

    categoryText
      .selectAll("tspan.categoryTspan")
      .data((d) => d.long.split(" "))
      .enter()
      .append("tspan")
      .attr("class", "categoryTspan")
      .text((d) => d)
      .attr("dy", "1em")
      .attr("x", 0);

    // creating main-category connection lines
    mainG
      .selectAll("line.mainCategoryConnection")
      .data(props.categories)
      .enter()
      .append("line")
      .attr("class", "mainCategoryConnection")
      .attr("x1", (_, i) => getMainCategoryConnectionLineCoordinates(i, "x", 0, 0))
      .attr("y1", (_, i) => getMainCategoryConnectionLineCoordinates(i, "y", 0, 0))
      .attr("x2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "x"))
      .attr("y2", (_, i) => getMainCategoryConnectionLineCoordinates(i, "y"))
      .attr("stroke", mainColor)
      .attr("stroke-width", 2);
  };

  // draw datasets upon prop change
  // needs to look at current state of diagram to match
  const drawDatasets = () => {
    d3.select("div." + datasetClass)
      .select("svg")
      .remove();

    svg = d3
      .select("div." + datasetClass)
      .append("svg")
      .attr("width", props.width)
      .attr("height", props.height);

    mainG = svg
      .append("g")
      .attr("class", "mainG")
      .attr("transform", getMainGLocation())
      .style("cursor", "pointer")
      .on("click", handleMainClick);

    // main circles
    mainG
      .append("circle")
      .attr("r", centerCircleInnerR)
      .attr("class", "mainCircleOuter")
      .attr("stroke", mainColor)
      .style("fill-opacity", 0);
    mainG
      .append("circle")
      .attr("r", centerCircleMiddleR)
      .attr("class", "mainCircleMiddle")
      .attr("stroke", mainColor)
      .style("fill-opacity", 0);
    mainG
      .append("circle")
      .attr("r", centerCircleInnerR)
      .attr("fill", mainColor)
      .attr("stroke", mainColor)
      .style("fill-opacity", 1);

    // main text
    const mainText = ["Health", "Data Assets", "in Alberta"];
    const mainTextSelection = mainG
      .append("text")
      .text("")
      .attr("class", "mainText")
      .attr("font-size", mainTextSize)
      .attr("text-anchor", "middle")
      .attr("fill", textColor)
      .attr("y", 0.25 - (mainText.length + 1) / 2 + "em");

    mainTextSelection
      .selectAll("tspan.mainTspan")
      .data(mainText)
      .enter()
      .append("tspan")
      .attr("class", "mainTspan")
      .text((d) => d)
      .attr("dy", "1em")
      .attr("x", 0);

    createCategories();

    // draw based on current indicators
    if (selectedCategory >= 0) {
      transitionMainAndCategories(0);
      createDatasets();
      transitionDatasets(0);
      transitionDatasetOuterCircle(0);
    }
  };

  return <div className={datasetClass} />;
};

const mapStateToProps = (state) => {
  return {
    datasets: state.data.datasets,
    categories: state.data.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedDataset: (dataset) => dispatch(actions.setSelectedDataset(dataset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyDatasets);
