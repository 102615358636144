import React, { useRef, useState } from "react";
import { Carousel, Radio, Row, Col } from "antd";
import RecordComparison from "./RecordComparison";
import AvailabilityComparison from "./AvailabilityComparison";
import DatasetFilter from "../DatasetFilter/DatasetFilter.js";
import { compSizes, checkSizes, getWindowBracket } from "../../Util/layout";

const Comparison = (props) => {
  const carouselRef = useRef();
  const [disabledList, setDisabledList] = useState([]);

  const onRadioChange = (e) => {
    const value = e.target.value;
    let step;
    switch (value) {
      case "availability":
        step = 0;
        setDisabledList([]);
        break;
      case "records":
        step = 1;
        setDisabledList(["ConnectCare"]);
        break;
      default:
        return;
    }
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(step);
    }
  };

  return (
    <React.Fragment>
      <Radio.Group onChange={onRadioChange} defaultValue="availability">
        <Radio.Button value="availability">Data Availability</Radio.Button>
        <Radio.Button value="records">Number of Records</Radio.Button>
      </Radio.Group>
      <Row>
        <Col xs={checkSizes.xs} sm={checkSizes.sm} md={checkSizes.md} lg={checkSizes.lg} xl={checkSizes.xl}>
          <DatasetFilter disabledList={disabledList} />
        </Col>
        <Col xs={compSizes.xs} sm={compSizes.sm} md={compSizes.md} lg={compSizes.lg} xl={compSizes.xl}>
          <Carousel ref={carouselRef} dots={false} style={{ paddingTop: 10 }}>
            <div>
              <AvailabilityComparison bracket={getWindowBracket(props.width)} height={props.height * 0.9} />
            </div>
            <div>
              <RecordComparison bracket={getWindowBracket(props.width)} height={props.height * 0.9} />
            </div>
          </Carousel>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Comparison;
