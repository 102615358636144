import React, { useEffect, useState, useRef } from "react";
import { getMethodsConstants } from "./HeartFailureMethodsConstants";
import { getDataLines } from "../../Util/d3TextUtil";
import * as d3 from "d3";

const heartFailureMethodsClass = "heartFailureMethods";
let svg, cohortGs, dataGs, algoGs;
let CONSTANTS;

const HeartFailureMethods = (props) => {
  const [cohortHovered, setCohortHovered] = useState(null);
  const [dataHovered, setDataHovered] = useState(null);
  const [algoHovered, setAlgoHovered] = useState(null);

  useEffect(() => {
    CONSTANTS = getMethodsConstants(props.width, props.height);
    drawDetails();
  }, []);

  useEffect(() => {
    CONSTANTS = getMethodsConstants(props.width, props.height);
    drawDetails();
  }, [props.width, props.height]);

  // useRefs to hold onto previous states for which rect was hovered
  // necessary to know whether to transition out the previous text (case of last rect -> null)
  const cohortRef = useRef(null);
  const dataRef = useRef(null);
  const algoRef = useRef(null);

  useEffect(() => {
    let ref, hovered, maxLength, classAttr, gClass, text, nthChild;
    // determining which category hovering triggered this useEffect
    if (cohortRef.current !== cohortHovered) {
      // for cohort
      ref = cohortRef;
      hovered = cohortHovered;
      maxLength = CONSTANTS.cohorts.length - 1;
      classAttr = "detailTextCohort";
      gClass = "cohortG";
      text = hovered !== null ? CONSTANTS.cohortText[hovered] : CONSTANTS.cohortText[CONSTANTS.cohortText.length - 1];
      nthChild = 4;
    } else if (dataRef.current !== dataHovered) {
      // for data
      ref = dataRef;
      hovered = dataHovered;
      maxLength = CONSTANTS.data.length - 1;
      classAttr = "detailTextData";
      gClass = "dataG";
      text = hovered !== null ? CONSTANTS.dataText[hovered] : CONSTANTS.dataText[CONSTANTS.dataText.length - 1];
      nthChild = 7;
    } else if (algoRef.current !== algoHovered) {
      // for algorithm
      ref = algoRef;
      hovered = algoHovered;
      maxLength = CONSTANTS.algorithms.length - 1;
      classAttr = "detailTextAlgo";
      gClass = "algoG";
      text = hovered !== null ? CONSTANTS.algoText[hovered] : CONSTANTS.algoText[CONSTANTS.algoText.length - 1];
      nthChild = 12;
    } else {
      return;
    }

    // if prev value of algoHovered is not null
    if (ref.current !== null) {
      // if new value is null
      if (hovered === null) {
        // if moving from last rect to null - shift to contracted rect
        if (ref.current === maxLength) {
          shiftDetailText(true, classAttr); // add necessary arguments
        }
        // moving from rect other than last to null - remove text from non-last and add text to last
        else {
          removeDetailText(classAttr); // add necessary arguments
          addDetailText(classAttr, gClass, text, nthChild, CONSTANTS.rectTextOffset);
        }
      }
      // new value is not null - moving from one rect to another
      else {
        removeDetailText(classAttr); // add necessary arguments
        addDetailText(classAttr, gClass, text, nthChild, CONSTANTS.rectTextOffsetSelected);
      }
    }
    // else prev value of hovered is null
    else {
      // don't change text, simply shift it for expanded rect
      if (hovered === maxLength) {
        shiftDetailText(false, classAttr); // add necessary arguments
      }
      // else remove text from last rect and add text to newly hovered
      else {
        removeDetailText(classAttr); // add necessary arguments
        addDetailText(classAttr, gClass, text, nthChild, CONSTANTS.rectTextOffsetSelected);
      }
    }

    ref.current = hovered;
  }, [cohortHovered, dataHovered, algoHovered]);

  // shifts detail text for expanding/contracting rect - only used for last rect
  const shiftDetailText = (contracting, classAttr) => {
    svg
      .selectAll("text." + classAttr)
      .transition()
      .duration(0)
      .attr("x", contracting ? CONSTANTS.rectTextOffset : CONSTANTS.rectTextOffsetSelected);
  };

  // removes detail text for newly unhovered cohort/data/algorithm
  const removeDetailText = (classAttr) => {
    svg.selectAll("text." + classAttr).remove();
  };

  // adds detail text for hovered cohort/data/algorithm
  const addDetailText = (classAttr, gClass, text, nthChild, textOffset) => {
    const lines = getDataLines(text, CONSTANTS.detailFontSize, CONSTANTS.rectWidth - CONSTANTS.rectTextOffset * 2, svg);

    svg
      .select("g." + gClass + ":nth-child(" + nthChild + ")")
      .selectAll("text." + classAttr)
      .data(lines)
      .enter()
      .append("text")
      .text((d) => d)
      .style("font-size", CONSTANTS.detailFontSize)
      .attr("x", textOffset)
      .attr("y", (_, i) => i * CONSTANTS.detailFontSize * 1.1 + CONSTANTS.detailFontSize * 3)
      .attr("class", classAttr);
  };

  // adds header for cohorts/data/algorithms
  const addHeader = (classAttr, text, x, y) => {
    svg
      .append("text")
      .attr("class", classAttr)
      .text(text)
      .attr("font-size", CONSTANTS.headerFontSize)
      .attr("x", x + CONSTANTS.rectWidth / 2)
      .attr("y", y)
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle");
  };

  // adds rects for cohorts/data/algorithms
  const addRects = (gVariable, data, classAttr, colors) => {
    gVariable
      .data(data)
      .append("rect")
      .attr("class", classAttr)
      .attr("width", CONSTANTS.rectWidth)
      .attr("height", CONSTANTS.rectHeight)
      .attr("rx", 20)
      .attr("ry", 20)
      .attr("fill", (_, i) => colors[i]);
  };

  // adds title text for cohorts/data/algorithms
  const addTitleText = (gVariable, data) => {
    gVariable
      .data(data)
      .append("text")
      .text((d) => d)
      .attr("class", "titleText")
      .attr("font-size", CONSTANTS.titleFontSize)
      .attr("x", CONSTANTS.rectWidth / 2)
      .attr("y", 1.1 * CONSTANTS.titleFontSize)
      .attr("text-anchor", "middle");
  };

  const createCohort = () => {
    cohortGs = svg
      .selectAll("g.cohortG")
      .data(CONSTANTS.cohorts)
      .enter()
      .append("g")
      .attr("class", "cohortG")
      .attr("transform", (_, i) => "translate(" + CONSTANTS.cohortRectXs[i] + "," + CONSTANTS.cohortRectYs[i] + ")")
      .on("mouseover", (d, i) => {
        sortGs(i, "cohortG", CONSTANTS.cohorts);
        shiftG(d, cohortGs, CONSTANTS.cohortRectXs, CONSTANTS.cohortRectYs);
        setCohortHovered(i);
      })
      .on("mouseout", () => {
        sortGs(-1, "cohortG", CONSTANTS.cohorts);
        shiftG(-1, cohortGs, CONSTANTS.cohortRectXs, CONSTANTS.cohortRectYs);
      });

    addHeader("cohortHeaderText", "Gold Standard", CONSTANTS.cohortRectXs[0], CONSTANTS.headerYs[0]);
    addRects(cohortGs, CONSTANTS.cohorts, "cohortRect", CONSTANTS.cohortColors);
    addTitleText(cohortGs, CONSTANTS.cohorts);
    addDetailText(
      "detailTextCohort",
      "cohortG",
      CONSTANTS.cohortText[CONSTANTS.cohortText.length - 1],
      4,
      CONSTANTS.rectTextOffset
    );
  };

  const createData = () => {
    dataGs = svg
      .selectAll("g.dataG")
      .data(CONSTANTS.data)
      .enter()
      .append("g")
      .attr("class", "dataG")
      .attr("transform", (_, i) => "translate(" + CONSTANTS.dataRectXs[i] + "," + CONSTANTS.dataRectYs[i] + ")")
      .on("mouseover", (d, i) => {
        sortGs(i, "dataG", CONSTANTS.data);
        shiftG(d, dataGs, CONSTANTS.dataRectXs, CONSTANTS.dataRectYs);
        setDataHovered(i);
      })
      .on("mouseout", () => {
        sortGs(-1, "dataG", CONSTANTS.data);
        shiftG(-1, dataGs, CONSTANTS.dataRectXs, CONSTANTS.dataRectYs);
      });

    addHeader("dataHeaderText", "Data", CONSTANTS.dataRectXs[0], CONSTANTS.headerYs[1]);
    addRects(dataGs, CONSTANTS.data, "dataRect", CONSTANTS.dataColors);
    addTitleText(dataGs, CONSTANTS.data);
    addDetailText(
      "detailTextData",
      "dataG",
      CONSTANTS.dataText[CONSTANTS.dataText.length - 1],
      7,
      CONSTANTS.rectTextOffset
    );
  };

  const createAlgorithms = () => {
    algoGs = svg
      .selectAll("g.algoG")
      .data(CONSTANTS.algorithms)
      .enter()
      .append("g")
      .attr("class", "algoG")
      .attr("transform", (_, i) => "translate(" + CONSTANTS.algoRectXs[i] + "," + CONSTANTS.algoRectYs[i] + ")")
      .on("mouseover", (d, i) => {
        sortGs(i, "algoG", CONSTANTS.algorithms);
        shiftG(d, algoGs, CONSTANTS.algoRectXs, CONSTANTS.algoRectYs);
        setAlgoHovered(i);
      })
      .on("mouseout", () => {
        sortGs(-1, "algoG", CONSTANTS.algorithms);
        shiftG(-1, algoGs, CONSTANTS.algoRectXs, CONSTANTS.algoRectYs);
      });

    addHeader("algoHeaderText", "Algorithms", CONSTANTS.algoRectXs[0], CONSTANTS.headerYs[2]);
    addRects(algoGs, CONSTANTS.algorithms, "algoRect", CONSTANTS.algoColors);
    addTitleText(algoGs, CONSTANTS.algorithms);
    addDetailText(
      "detailTextAlgo",
      "algoG",
      CONSTANTS.algoText[CONSTANTS.algoText.length - 1],
      12,
      CONSTANTS.rectTextOffset
    );
  };

  const sortGs = (i, gName, data) => {
    // need to create sortOrder due to .sort working differently in firefox
    const sortOrder = [];
    for (let j = 0; j < data.length; j++) {
      if (j === i) {
        sortOrder.push(data.length);
      } else {
        sortOrder.push(j);
      }
    }

    svg.selectAll("g." + gName).sort((a, b) => {
      a = data.indexOf(a);
      b = data.indexOf(b);
      return sortOrder[a] - sortOrder[b];
    });
  };

  const shiftG = (data, Gs, Xs, Ys) => {
    // transition G
    Gs.transition()
      .duration(CONSTANTS.duration)
      .attr("transform", (d, i) => {
        if (d === data) {
          return "translate(" + (Xs[i] - CONSTANTS.mouseOverShift - 15) + "," + Ys[i] + ")";
        }
        return "translate(" + Xs[i] + "," + Ys[i] + ")";
      });

    // make rect bigger
    Gs.selectAll("rect")
      .transition()
      .duration(CONSTANTS.duration)
      .attr("width", (d) => {
        if (d === data) {
          return CONSTANTS.selectedRectWidth;
        }
        return CONSTANTS.rectWidth;
      });

    // transition title text
    Gs.selectAll("text.titleText")
      .transition()
      .duration(CONSTANTS.duration)
      .attr("x", (d) => {
        if (d === data) {
          return CONSTANTS.selectedRectWidth / 2;
        }
        return CONSTANTS.rectWidth / 2;
      });
  };

  /**
   * Is called when the user mouses out of all rectangles
   * Triggers text to be drawn on all of the bottom rectangles
   */
  const setBottomText = () => {
    setCohortHovered(null);
    setDataHovered(null);
    setAlgoHovered(null);
  };

  const drawDetails = () => {
    d3.select("div." + heartFailureMethodsClass)
      .select("svg")
      .remove();

    svg = d3
      .select("div." + heartFailureMethodsClass)
      .append("svg")
      .attr("width", props.width)
      .attr("height", CONSTANTS.svgHeight);

    svg
      .append("rect")
      .attr("class", "backgroundRect")
      .attr("width", props.width - 5)
      .attr("height", CONSTANTS.svgHeight - 5)
      // .style("fill", "#f0f2f5")
      .style("fill", "white")
      .on("mouseover", setBottomText);

    createCohort();
    createData();
    createAlgorithms();
  };

  return (
    <div
      className={heartFailureMethodsClass}
      style={{
        margin: "auto",
        width: props.width,
        padding: 0,
        paddingBottom: 20,
        overflowX: "hidden",
        backgroundColor: "white",
      }}
    />
  );
};
export default HeartFailureMethods;
