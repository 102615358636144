export const getWindowBracket = (width) => {
  if (width > 1199) {
    return "xl";
  } else if (width > 991) {
    return "lg";
  } else if (width > 767) {
    return "md";
  } else if (width > 575) {
    return "sm";
  } else {
    return "xs";
  }
};

// main vis size and detail panel size
export const mainSizes = { xs: 24, sm: 24, md: 18, lg: 17, xl: 16 };
export const detailSizes = {
  xs: mainSizes.xs === 24 ? 24 : 24 - mainSizes.xs,
  sm: mainSizes.sm === 24 ? 24 : 24 - mainSizes.sm,
  md: mainSizes.md === 24 ? 24 : 24 - mainSizes.md,
  lg: mainSizes.lg === 24 ? 24 : 24 - mainSizes.lg,
  xl: mainSizes.xl === 24 ? 24 : 24 - mainSizes.xl,
};

// comparison chart size and checkbox size
export const compSizes = { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 };
export const checkSizes = {
  xs: compSizes.xs === 24 ? 24 : 24 - compSizes.xs,
  sm: compSizes.sm === 24 ? 24 : 24 - compSizes.sm,
  md: compSizes.md === 24 ? 24 : 24 - compSizes.md,
  lg: compSizes.lg === 24 ? 24 : 24 - compSizes.lg,
  xl: compSizes.xl === 24 ? 24 : 24 - compSizes.xl,
};

// picture size and bullet point size (connect care)
export const picSizes = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
export const bulletSizes = {
  xs: picSizes.xs === 24 ? 24 : 24 - picSizes.xs,
  sm: picSizes.sm === 24 ? 24 : 24 - picSizes.sm,
  md: picSizes.md === 24 ? 24 : 24 - picSizes.md,
  lg: picSizes.lg === 24 ? 24 : 24 - picSizes.lg,
  xl: picSizes.xl === 24 ? 24 : 24 - picSizes.xl,
};

export const getMainWidth = (width) => {
  const bracket = getWindowBracket(width);
  return (mainSizes[bracket] / 24) * width;
};

export const getDetailWidth = (width) => {
  const bracket = getWindowBracket(width);
  return (detailSizes[bracket] / 24) * width;
};
