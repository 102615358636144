import React, { useState, useRef } from "react";
import { Carousel, Row, Col, Typography } from "antd";
import ReactPlayer from "react-player/youtube";
import { connectCareDescription, connectCareButtonText } from "../../Util/writing";
import { picSizes, bulletSizes } from "../../Util/layout";
const { Title } = Typography;

const ConnectCare = (props) => {
  const [step, setStep] = useState(0);
  const carouselRef = useRef();
  const maxStep = process.env.REACT_APP_VERSION === "dev" ? 2 : 1;

  const handlePreviousClick = () => {
    if (step > 0) {
      carouselRef.current.goTo(step - 1);
      setStep(step - 1);
    }
  };

  const handleNextClick = () => {
    if (step < maxStep) {
      carouselRef.current.goTo(step + 1);
      setStep(step + 1);
    }
  };

  const getButtonText = (side = 0) => {
    if (side) {
      // get text for "next" button
      return connectCareButtonText[step + 1];
    } else {
      // get text for "previous" button
      return connectCareButtonText[step - 1];
    }
  };

  const generateBulletPoints = () => {
    return connectCareDescription.map((point, i) => {
      return (
        <div style={{ display: "flex", flexDirection: "row", margin: 0, padding: 0 }} key={"div-" + i}>
          <p style={{ fontSize: 28, paddingLeft: 8, marginBottom: -10 }} key={"bullet-" + i}>
            {"\u2022"}
          </p>
          <p style={{ fontSize: 16, paddingLeft: 4, paddingTop: 10, marginBottom: -10 }} key={"point-" + i}>
            {point}
          </p>
        </div>
      );
    });
  };

  const getVideoDimensions = () => {
    const maxWidth = props.width * 0.7;
    const heightForMaxWidth = (maxWidth * 9) / 16;
    const maxHeight = props.height * 0.8;
    const WidthForMaxHeight = (maxHeight * 16) / 9;

    return maxWidth > WidthForMaxHeight ? [WidthForMaxHeight, maxHeight] : [maxWidth, heightForMaxWidth];
  };

  return (
    <React.Fragment>
      <Carousel ref={carouselRef} dots={false} style={{ paddingTop: 10 }}>
        {/* Step 0 - Overview Video */}
        <div>
          <Title level={4} style={{ textAlign: "center" }}>
            Connect Care
          </Title>
          <ReactPlayer
            style={{ margin: "auto" }}
            url="https://www.youtube.com/watch?v=UD0uBj2-zuE"
            controls
            width={getVideoDimensions()[0]}
            height={getVideoDimensions()[1]}
          />
        </div>
        {/* Step 1 - Overview Details */}
        <div>
          <Row>
            <Col xs={picSizes.xs} sm={picSizes.sm} md={picSizes.md} lg={picSizes.lg} xl={picSizes.xl}>
              <img
                src={process.env.PUBLIC_URL + "/moreInfo/connectCareOverview.png"}
                alt="ConnectCare Overview"
                height={props.height * 0.8}
                width="90%"
              />
            </Col>
            <Col
              xs={bulletSizes.xs}
              sm={bulletSizes.sm}
              md={bulletSizes.md}
              lg={bulletSizes.lg}
              xl={bulletSizes.xl}
              style={{ paddingBottom: 40 }}
            >
              <Title level={4} style={{ marginBottom: -8 }}>
                Connect Care
              </Title>
              {generateBulletPoints()}
              <p style={{ fontSize: 16, paddingTop: 8 }}>
                More information can be found{" "}
                <a href="https://www.albertahealthservices.ca/info/cis.aspx" target="_blank" rel="noopener noreferrer">
                  here.
                </a>
              </p>
              <br />
              <Title level={4} style={{ marginBottom: -8 }}>
                Implementation Timeline
              </Title>
              <p style={{ fontSize: 16, paddingTop: 10, marginBottom: -10 }}>
                Connect Care is being implemented in stages across Alberta. The implementation timeline provided by
                Alberta Health Services can be found{" "}
                <a
                  href="https://www.albertahealthservices.ca/assets/info/cis/if-cis-cc-infographic-site-implementation-timeline.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </p>
            </Col>
          </Row>
        </div>
        {/* Step 2 - Database info */}
        <div>
          <p style={{ fontSize: 18, fontWeight: "bold" }}>Creating a dataset can require linking many tables</p>
          <img src={process.env.PUBLIC_URL + "/moreInfo/tableLinking.png"} alt="table linking" width="90%" />
        </div>
      </Carousel>

      <div>
        <button style={{ visibility: step === 0 ? "hidden" : "visible" }} onClick={handlePreviousClick}>
          {getButtonText()}
        </button>
        <button
          style={{ visibility: step === maxStep ? "hidden" : "visible", float: "right" }}
          onClick={handleNextClick}
        >
          {getButtonText(1)}
        </button>
      </div>
    </React.Fragment>
  );
};

export default ConnectCare;
