import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import store from "./Store/configureStore";

import DataAssetsPage from "./Pages/DataAssets/DataAssetsPage";
import HypertensionPage from "./Pages/HypertensionPage/HypertensionPage";
import HeartFailurePage from "./Pages/HeartFailurePage/HeartFailurePage";
import PressureUlcerPage from "./Pages/PressureUlcerPage/PressureUlcerPage";
import MetricsPage from "./Pages/MetricsPage/MetricsPage";

const router = (
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/ab-data-assets" component={DataAssetsPage} />
        <Route exact path="/" render={() => <Redirect to="/ab-data-assets" />} />
        <Route exact path="/heart-failure" component={HeartFailurePage} />
        <Route exact path="/hypertension" component={HypertensionPage} />
        {process.env.REACT_APP_VERSION === "dev" && (
          <Route exact path="/pressure-injury" component={PressureUlcerPage} />
        )}
        <Route exact path="/performance-metrics" component={MetricsPage} />
      </Switch>
    </BrowserRouter>
  </Provider>
);

export default router;
