import React, { useState, useEffect, useRef } from "react";
import MenuBar from "../../Components/MenuBar/MenuBar.js";
import HeartFailureIntro from "../../Components/HeartFailure/HeartFailureIntro";
import HeartFailureMethods from "../../Components/HeartFailure/HeartFailureMethods";
import HeartFailureResults from "../../Components/HeartFailure/HeartFailureResults";
import InfoHover from "../../Util/InfoHover";
import { Carousel, Layout, Radio } from "antd";
import { throttle } from "lodash";
import { getWindowBracket } from "../../Util/layout";
import { heartFailureInfo } from "../../Util/writing";

const { Content } = Layout;

const HeartFailurePage = () => {
  const [contentWidth, setContentWidth] = useState(window.innerWidth);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 60);
  const sizeBracket = getWindowBracket(contentWidth);
  const carouselRef = useRef();

  const resizeListener = () => {
    setContentWidth(document.getElementById("content").getBoundingClientRect().width);
    setContentHeight(window.innerHeight - 60);
  };
  const throttledResizeListener = throttle(resizeListener, 200);

  // componentDidMount and willUnmount
  useEffect(() => {
    window.addEventListener("resize", throttledResizeListener);
    return () => window.removeEventListener("resize", throttledResizeListener);
  }, []);

  const onRadioChange = (e) => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(e.target.value);
    }
  };

  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <MenuBar bracket={sizeBracket} width={contentWidth} />
        <Content id="content">
          <div style={{ margin: "auto", textAlign: "center" }}>
            <Radio.Group onChange={onRadioChange} defaultValue={0}>
              <Radio.Button value={0}>{"Introduction & Methods"}</Radio.Button>
              <Radio.Button value={1}>Results</Radio.Button>
            </Radio.Group>
          </div>
          <Carousel ref={carouselRef} dots={false} style={{ padding: 10 }}>
            <div>
              <div
                style={{
                  height: contentHeight - 60,
                  overflowY: "auto",
                  margin: "auto",
                  width: contentWidth * 0.95,
                  backgroundColor: "white",
                }}
              >
                <HeartFailureIntro width={contentWidth} />
                <InfoHover content={heartFailureInfo} width={contentWidth} />
                <HeartFailureMethods width={contentWidth * 0.9} height={contentHeight - 60} />
              </div>
            </div>
            <div>
              <HeartFailureResults width={contentWidth} height={contentHeight - 60} />
            </div>
          </Carousel>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default HeartFailurePage;
