import React from "react";
import { roundNumber } from "../../Util/util";
import { getWindowBracket } from "../../Util/layout";

export const getResultsConstants = (width, height) => {
  const bracket = getWindowBracket(width);

  const viewWidth = 0.95;
  const outerRadius =
    bracket === "xs" || bracket === "sm" ? Math.min((width * viewWidth) / 8, height / 8) : (width * viewWidth) / 16;
  const cy = outerRadius * 1.1 + 30;

  return {
    overallColor: "#48c93c",
    viewWidth: viewWidth,
    outerRadius: outerRadius,
    cy: cy,
    cx: bracket === "xs" || bracket === "sm" ? "43%" : "50%",
    pieChartWidth: (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : 3),
    pieChartHeight: cy * 2.2,
    radarChartWidth:
      (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : bracket === "md" || bracket === "lg" ? 2 : 4),
    radarChartHeight: cy * 2.5,
    radarChartOuterRadius: 1199 < width && width < 1500 ? "60%" : "70%",
    radarLabelFontSize: 1199 < width && width < 1500 ? width * 0.009 : null, // default is 14
    bracket: bracket,
  };
};

export const renderLabel = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, payload, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <circle cx={mx} cy={my} r={2} fill={fill} />
      <text
        x={mx + (cos >= 0 ? 1 : -1) * 7}
        y={my - outerRadius / 16 + 2}
        textAnchor={textAnchor}
        fill="grey"
        fontSize={outerRadius / 6}
      >
        {payload.payload.name}
      </text>
      <text
        x={mx + (cos >= 0 ? 1 : -1) * 7}
        y={my - outerRadius / 16 + 4 + outerRadius / 7}
        textAnchor={textAnchor}
        fill="grey"
        fontSize={outerRadius / 6}
      >
        {"(" + payload.payload.value + ", " + roundNumber(percent * 100, 2) + "%)"}
      </text>
    </g>
  );
};

// Pie chart data
export const agePie = [
  { name: "Less than 45", value: 123, color: "#ffb061" },
  { name: "45-64", value: 206, color: "#ff9324" },
  { name: "Greater than 64", value: 279, color: "#f55b00" },
];
export const servicePie = [
  { name: "Non-Surgical", value: 395, color: "#433e3a" },
  { name: "Surgical", value: 213, color: "#716761" },
];
export const sexPie = [
  { name: "Female", value: 302, color: "#efbd00" },
  { name: "Male", value: 306, color: "#ffed50" },
];

export const categoryMapping = {
  "Less than 45": "Age",
  "45-64": "Age",
  "Greater than 64": "Age",
  Surgical: "Service",
  "Non-Surgical": "Service",
  Female: "Sex",
  Male: "Sex",
};

// Radar chart data
export const radarData = [
  {
    name: "Sensitivity",
    OverallDC: 95,
    OverallC: 91,
    OverallCS: 95,
    OverallICD: 47,
    "Less than 45DC": 100,
    "Less than 45C": 100,
    "Less than 45CS": 100,
    "Less than 45ICD": 29,
    "45-64DC": 87,
    "45-64C": 90,
    "45-64CS": 90,
    "45-64ICD": 42,
    "Greater than 64DC": 91,
    "Greater than 64C": 96,
    "Greater than 64CS": 97,
    "Greater than 64ICD": 50,
    SurgicalDC: 90,
    SurgicalC: 91,
    SurgicalCS: 91,
    SurgicalICD: 44,
    "Non-SurgicalDC": 91,
    "Non-SurgicalC": 96,
    "Non-SurgicalCS": 97,
    "Non-SurgicalICD": 48,
    FemaleDC: 90,
    FemaleC: 94,
    FemaleCS: 94,
    FemaleICD: 45,
    MaleDC: 91,
    MaleC: 95,
    MaleCS: 96,
    MaleICD: 48,
    max: 100,
  },
  {
    name: "PPV",
    OverallDC: 91,
    OverallC: 93,
    OverallCS: 92,
    OverallICD: 97,
    "Less than 45DC": 88,
    "Less than 45C": 82,
    "Less than 45CS": 82,
    "Less than 45ICD": 80,
    "45-64DC": 87,
    "45-64C": 84,
    "45-64CS": 85,
    "45-64ICD": 94,
    "Greater than 64DC": 95,
    "Greater than 64C": 95,
    "Greater than 64CS": 95,
    "Greater than 64ICD": 98,
    SurgicalDC: 92,
    SurgicalC: 90,
    SurgicalCS: 90,
    SurgicalICD: 98,
    "Non-SurgicalDC": 93,
    "Non-SurgicalC": 92,
    "Non-SurgicalCS": 92,
    "Non-SurgicalICD": 96,
    FemaleDC: 92,
    FemaleC: 91,
    FemaleCS: 91,
    FemaleICD: 95,
    MaleDC: 93,
    MaleC: 92,
    MaleCS: 93,
    MaleICD: 97,
    max: 100,
  },
  {
    name: "Specificity",
    OverallDC: 92,
    OverallC: 93,
    OverallCS: 92,
    OverallICD: 98,
    "Less than 45DC": 98,
    "Less than 45C": 97,
    "Less than 45CS": 97,
    "Less than 45ICD": 99,
    "45-64DC": 92,
    "45-64C": 90,
    "45-64CS": 91,
    "45-64ICD": 98,
    "Greater than 64DC": 88,
    "Greater than 64C": 87,
    "Greater than 64CS": 87,
    "Greater than 64ICD": 97,
    SurgicalDC: 94,
    SurgicalC: 93,
    SurgicalCS: 93,
    SurgicalICD: 99,
    "Non-SurgicalDC": 93,
    "Non-SurgicalC": 91,
    "Non-SurgicalCS": 92,
    "Non-SurgicalICD": 98,
    FemaleDC: 94,
    FemaleC: 92,
    FemaleCS: 92,
    FemaleICD: 98,
    MaleDC: 93,
    MaleC: 91,
    MaleCS: 92,
    MaleICD: 99,
    max: 100,
  },
  {
    name: "NPV",
    OverallDC: 95,
    OverallC: 91,
    OverallCS: 95,
    OverallICD: 66,
    "Less than 45DC": 100,
    "Less than 45C": 100,
    "Less than 45CS": 100,
    "Less than 45ICD": 92,
    "45-64DC": 92,
    "45-64C": 93,
    "45-64CS": 94,
    "45-64ICD": 74,
    "Greater than 64DC": 79,
    "Greater than 64C": 89,
    "Greater than 64CS": 90,
    "Greater than 64ICD": 42,
    SurgicalDC: 92,
    SurgicalC: 93,
    SurgicalCS: 93,
    SurgicalICD: 70,
    "Non-SurgicalDC": 91,
    "Non-SurgicalC": 96,
    "Non-SurgicalCS": 96,
    "Non-SurgicalICD": 64,
    FemaleDC: 92,
    FemaleC: 95,
    FemaleCS: 95,
    FemaleICD: 68,
    MaleDC: 91,
    MaleC: 94,
    MaleCS: 95,
    MaleICD: 64,
    max: 100,
  },
];
