import React from "react";
import { connect } from "react-redux";
import { Layout, Button } from "antd";
const { Footer } = Layout;

const small = ["xs", "sm"];
const infoAvailable = ["ConnectCare", "Sunrise Clinical Manager"];

const DataAssetsFooter = (props) => {
  return (
    <React.Fragment>
      <Footer
        style={{
          height: 60,
          backgroundColor: "white",
          color: "black",
          fontSize: props.bracket === "xs" ? 14 : 18,
          textAlign: "center",
          paddingLeft: "1%",
          paddingRight: "1%",
          paddingTop: 15,
        }}
      >
        <Button
          type="primary"
          shape="round"
          size={small.includes(props.bracket) ? "small" : "middle"}
          style={{
            float: "left",
            paddingLeft: 5,
            paddingRight: 5,
          }}
          onClick={() => props.setModalContent("compare")}
        >
          {small.includes(props.bracket) ? "Compare" : "Compare Datasets"}
        </Button>
        <>
          Additional information can be found{" "}
          <a
            href="https://cumming.ucalgary.ca/centres/centre-health-informatics/data-and-analytic-services/data-resources/available-datasets"
            target="_blank"
            rel="noopener noreferrer"
          >
            here.
          </a>
        </>

        <Button
          type="primary"
          shape="round"
          size={small.includes(props.bracket) ? "small" : "middle"}
          style={{
            float: "right",
            paddingLeft: 5,
            paddingRight: 5,
            visibility:
              props.selectedDataset && infoAvailable.includes(props.selectedDataset.title) ? "visible" : "hidden",
          }}
          onClick={() => props.setModalContent("info")}
        >
          {small.includes(props.bracket) ? "Info" : "More Info"}
        </Button>
      </Footer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    datasets: state.data.datasets,
    selectedDataset: state.data.selectedDataset,
  };
};

export default connect(mapStateToProps, null)(DataAssetsFooter);
