import { getWindowBracket } from "../../Util/layout";

export const treeData = [
  {
    name: "Data",
    color: "#ff9324",
    children: [{ name: "Randomized Clinical Trial" }, { name: "Data Linkage" }, { name: "Document Extraction" }],
  },
  {
    name: "Preprocessing",
    color: "#82b2b8",
    children: [{ name: "Bag of Words and TF-IDF Vectors" }, { name: "Bias Removal" }, { name: "Other Conditions" }],
  },
  {
    name: "Models",
    color: "#b6bd36",
    children: [{ name: "Six Models" }, { name: "Model Tuning" }, { name: "Nursing Note Components" }],
  },
];

export const getMethodsConstants = (width, height) => {
  const minSize = Math.min(width, height);
  const childrenFontSize = 12;

  return {
    linkColor: "#6e6666",
    textColor: "white",
    linkWidth: minSize / 150,
    vPadding: height * 0.2,
    hPadding: width * 0.2,
    mainRadius: minSize / 10,
    childrenRadius: minSize / 12,
    mainFontSize: minSize / 35,
    childrenFontSize: childrenFontSize,
    childrenLineHeight: childrenFontSize * 1.1,
  };
};

export const detailsWriting = [
  [
    "A previously completed randomized clinical trial (RCT) is used to create a reference standard. Originally, there were 678 eligible consenting inpatients in the trial. This number was narrowed down due to factors such as uncompleted trials, patients admitted with pressure injuries, patients admitted to the ICU, and more. There were 276 eligible patients remaining for this study. The RCT provides record ID, medical unit, patient sex, first and second skin assessment dates, and pressure injury disease, measured and classified into one of six stages.",
    "The pressure injury randomized clinical trial data was linked to the Discharge Abstract Database (DAD) and Allscripts Sunrise Clinical Manager (SCM). The DAD is an administrative database which provides an abstract of the primary clinical data for a patient visit. Each abstract contains demographic information of the patient, as well as ICD-10 codes that detail the diagnoses relevant to the patient's visit. SCM is an EMR system used in hospitals in Calgary. The RCT data and DAD were linked by using the patient personal health number and admission date. Then, SCM data was linked using data elements common to both the DAD and SCM.",
    "After linking the data, free-text nursing notes were extracted from SCM. Multidisciplinary Progress Records (MPRs), Patient Assessment (PA), and Patient Assessment Tools (PAT) are the document types that were extracted. MPRs provide a description of PUs if charted during care. Patient Assessment documents contain condition and beahviour assessments. Patient Assessment Tools include two clinical indexes, along with notes on the suitable prevention or treatment protocol.",
  ],
  [
    "All of the documents were converted into a bag of words and term frequency - inverse document frequency (TF-IDF) vectors. This was done by using the Python scikit-learn library.",
    'The semi-structured nursing notes contain fixed fields with data elements ("The Braden Scale for Prediciting Pressure Sore Risk" and "Pressure Injury Risk: Moderate Risk" for example) that could create bias when training models. Regular expressions were used to remove these sections.',
    "Text pertaining to other conditions or symptoms were removed. This was done because of the bag of words approach that was taken. The context of text is lost during the bag of words process, so it was necessary to only use text related to pressure injuries.",
  ],
  [
    "Naive Bayes, logistic regression, decision tree, Adaboost, random forest, and gradient boost classification models were all trained and tuned in order to classify the nursing notes. PI cases above stage 1 were classified as positive.",
    "Common hyperparameters for each model were tuned in order to obtain the best performance. Examples of tuned hyperparameters include: strength of regularization and number of estimators.",
    "Different combinations of nursing note document types were used in building the models, in order to determine the contribution made by each document. The combinations were: MPRs, Patient Assessment + MPRs, Patient Assessment Tools + MPRs, Patient Assessment + Patient Assessment Tools + MPRs, and filtered Patient Assessment + MPRs. A filtered Patient Assessment is defined as a document with a skin assessment after the removal of irrelevant text.",
  ],
];

export const getResultsConstants = (width, height) => {
  const bracket = getWindowBracket(width);

  const viewWidth = 0.95;
  const outerRadius =
    bracket === "xs" || bracket === "sm"
      ? Math.min((width * viewWidth) / 6, height / 8)
      : bracket === "md"
      ? Math.min((width * viewWidth) / 12, height / 3)
      : (width * viewWidth) / 18;
  const cy = outerRadius * 1.1 + 30;

  return {
    bracket: bracket,
    viewWidth: viewWidth,
    outerRadius: outerRadius,
    cx: bracket === "xs" || bracket === "sm" ? "45%" : bracket === "md" ? "45%" : bracket === "lg" ? "46.5%" : "48%",
    cy: cy,
    pieChartWidth: width * viewWidth,
    pieChartHeight: cy * 2.2,
    radarChartWidthICD:
      (width *
        viewWidth *
        (bracket === "xl" ? 10 : bracket === "lg" ? 12 : bracket === "md" ? 16 : bracket === "sm" ? 24 : 24)) /
      24, // values from column sizing in PressureUlcerResults
    radarChartWidthMPR: (width * viewWidth) / (bracket === "xl" ? 2 : 1),
    radarChartWidthMPRPA: (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : 2),
    radarChartWidthThree: (width * viewWidth) / (bracket === "xs" || bracket === "sm" ? 1 : bracket === "xl" ? 3 : 2),
    radarChartHeight: cy * 2.5,
    radarChartOuterRadius: 1199 < width && width < 1500 ? "60%" : "70%",
    radarLabelFontSize: 1199 < width && width < 1500 ? width * 0.01 : null, // default is 14
    radarColor: "#617eac",
  };
};

export const puPie = [
  { name: "Absent", value: 210, color: "#2d4b7a" },
  { name: "Present", value: 66, color: "#617eac" },
];

export const barChartDataPatientCount = [
  {
    name: "PA",
    "Injury Present": 57.6,
    "Injury Absent": 46.6,
  },
  {
    name: "PAT",
    "Injury Present": 51.5,
    "Injury Absent": 63.8,
  },
  {
    name: "MPR",
    "Injury Present": 100,
    "Injury Absent": 100,
  },
];

export const barChartDataMeanNumDoc = [
  {
    name: "PA",
    "Injury Present": 14.7,
    "Injury Absent": 13.7,
  },
  {
    name: "PAT",
    "Injury Present": 3,
    "Injury Absent": 2.6,
  },
  {
    name: "MPR",
    "Injury Present": 24.4,
    "Injury Absent": 21.9,
  },
];

export const barChartDataMeanWords = [
  {
    name: "PA",
    "Injury Present": 394.6,
    "Injury Absent": 378.6,
  },
  {
    name: "PAT",
    "Injury Present": 218.5,
    "Injury Absent": 207.3,
  },
  {
    name: "MPR",
    "Injury Present": 144.2,
    "Injury Absent": 149.2,
  },
];

// Radar chart data
export const radarData = [
  {
    name: "Sensitivity",
    MPR99: 19.6,
    MPRPA99: 33.6,
    MPRPAT99: 19.7,
    MPRPAPAT99: 31.4,
    MPRFPA99: 35.5,
    max: 100,
  },
  {
    name: "Specificity",
    MPR99: 98.8,
    MPRPA99: 98.7,
    MPRPAT99: 98.8,
    MPRPAPAT99: 98.8,
    MPRFPA99: 98.7,
    max: 100,
  },
  {
    name: "PPV",
    MPR99: 82.7,
    MPRPA99: 88.3,
    MPRPAT99: 82.6,
    MPRPAPAT99: 89.1,
    MPRFPA99: 89.5,
    max: 100,
  },
  {
    name: "NPV",
    MPR99: 79,
    MPRPA99: 82,
    MPRPAT99: 79,
    MPRPAPAT99: 81.5,
    MPRFPA99: 82.5,
    max: 100,
  },
  {
    name: "F1",
    MPR99: 30.9,
    MPRPA99: 47.8,
    MPRPAT99: 31,
    MPRPAPAT99: 45.4,
    MPRFPA99: 49.8,
    max: 100,
  },
  {
    name: "AUC",
    MPR99: 68.6,
    MPRPA99: 79.1,
    MPRPAT99: 68.9,
    MPRPAPAT99: 81.6,
    MPRFPA99: 82.4,
    max: 100,
  },
];

// Radar chart data
export const radarDataComparison = [
  {
    name: "Sensitivity",
    ICD10: 27.7,
    MPR99: 19.6,
    MPRPA99: 33.6,
    MPRPAT99: 19.7,
    MPRPAPAT99: 31.4,
    MPRFPA99: 35.5,
    max: 100,
  },
  {
    name: "Specificity",
    ICD10: 98.8,
    MPR99: 98.8,
    MPRPA99: 98.7,
    MPRPAT99: 98.8,
    MPRPAPAT99: 98.8,
    MPRFPA99: 98.7,
    max: 100,
  },
  {
    name: "PPV",
    ICD10: 91.7,
    MPR99: 82.7,
    MPRPA99: 88.3,
    MPRPAT99: 82.6,
    MPRPAPAT99: 89.1,
    MPRFPA99: 89.5,
    max: 100,
  },
  {
    name: "NPV",
    ICD10: 73.9,
    MPR99: 79,
    MPRPA99: 82,
    MPRPAT99: 79,
    MPRPAPAT99: 81.5,
    MPRFPA99: 82.5,
    max: 100,
  },
];

export const algorithmColors = {
  ICD10: "#464652",
  MPR99: "#617eac",
  MPRPA99: "#df5656",
  MPRPAT99: "#ffb061",
  MPRPAPAT99: "#e5ee44",
  MPRFPA99: "pink",
};
